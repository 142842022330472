import {
  IconAddressBook,
  IconLayoutDashboard,
  IconListCheck,
  IconMessageCircleQuestion,
  IconNews,
  IconSettings,
  IconSpeakerphone,
  IconUserSquare,
} from "@tabler/icons-react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/realtorr logo.png";
import NewsSection from "./Dashboard/NewsSection";
import { useSelector } from "react-redux";

const routes = [
  {
    label: "Dashboard",
    icon: IconLayoutDashboard,
    href: "/dashboard",
  },
  {
    label: "Leads",
    icon: IconAddressBook,
    href: "/dashboard/leads",
  },
  {
    label: "Marketing",
    icon: IconSpeakerphone,
    href: "/dashboard/marketing",
  },
  {
    label: "Listings",
    icon: IconListCheck,
    href: "/dashboard/listings",
  },
  // {
  //   label: "Billing",
  //   icon: IconReceipt,
  //   href: "/dashboard/billing",
  // },
];

const Sidebar = ({ isSidebarOpen }) => {
  const location = useLocation().pathname;
  const profile = useSelector((state) => state.auth.profile);

  console.log("profile", profile);

  return (
    <aside
      className={`bg-white d-flex flex-column vh-100 border-end ${
        isSidebarOpen ? "mobile-side-bar" : "d-block"
      } d-md-flex`}
    >
      <div className="d-flex justify-content-center my-4">
        <Link to="/dashboard" className="d-flex justify-content-center">
          <img
            src={logo}
            alt="logo"
            className="img-fluid" // Use Bootstrap class for responsive image
            style={{ maxHeight: "25px" }} // Ensure max height is set for the image
          />
        </Link>
      </div>
      <div className="d-flex flex-column flex-grow-1 overflow-hidden">
        <div className="container-fluid flex-grow-1 overflow-auto">
          <ul className="navbar-nav flex-column">
            {profile.role !== "superAdmin" &&
              routes.map((route) => (
                <li className="nav-item my-1" key={route.href}>
                  <Link
                    to={route.href}
                    className={`nav-link hover-tabs h3 group d-flex justify-content-start align-items-center font-medium cursor-pointer p-2 rounded-3 ${
                      location === route.href
                        ? "active bg-primary text-white"
                        : ""
                    }`}
                  >
                    <route.icon className="icon me-2" />
                    {route.label}
                  </Link>
                </li>
              ))}
            {profile.role !== "superAdmin" && <hr />}
            {profile.role === "superAdmin" && (
              <>
                <li className="nav-item my-1">
                  <Link
                    to="/dashboard/super-admin"
                    className={`nav-link hover-tabs h3 group d-flex align-items-center justify-content-start font-medium cursor-pointer hover:bg-blue p-2 rounded-3 ${
                      location === "/dashboard/super-admin"
                        ? "active bg-primary text-white"
                        : ""
                    }`}
                  >
                    <IconLayoutDashboard className="icon me-2" />
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item my-1">
                  <Link
                    to="/dashboard/subscribers"
                    className={`nav-link hover-tabs h3 group d-flex align-items-center justify-content-start font-medium cursor-pointer hover:bg-blue p-2 rounded-3 ${
                      location === "/dashboard/subscribers"
                        ? "active bg-primary text-white"
                        : ""
                    }`}
                  >
                    <IconUserSquare className="icon me-2" />
                    Subscribers
                  </Link>
                </li>
                <li className="nav-item my-1">
                  <Link
                    to="/dashboard/news"
                    className={`nav-link hover-tabs h3 group d-flex align-items-center justify-content-start font-medium cursor-pointer hover:bg-blue p-2 rounded-3 ${
                      location === "/dashboard/news"
                        ? "active bg-primary text-white"
                        : ""
                    }`}
                  >
                    <IconNews className="icon me-2" />
                    News Content
                  </Link>
                </li>
              </>
            )}
            {profile.role !== "superAdmin" && <NewsSection />}
          </ul>
        </div>
      </div>
      {/* <div>
        <ul className="navbar-nav flex-column mx-3 mb-3">
          <hr />
          <li className="nav-item my-2">
            <Link
              to="/dashboard/support"
              className={`nav-link hover-tabs h3 group d-flex align-items-center justify-content-start font-medium cursor-pointer p-2 rounded-3 ${
                location === "/dashboard/support"
                  ? "active active bg-primary text-white"
                  : ""
              }`}
            >
              <IconMessageCircleQuestion className="icon me-2" />
              Support
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/dashboard/setting"
              className={`nav-link hover-tabs h3 group d-flex align-items-center justify-content-start font-medium cursor-pointer p-2 rounded-3 ${
                location === "/dashboard/setting"
                  ? "active active bg-primary text-white"
                  : ""
              }`}
            >
              <IconSettings className="icon me-2" />
              Settings
            </Link>
          </li>
        </ul>
      </div> */}
    </aside>
  );
};

export default Sidebar;
