import { IconBath, IconBed, IconMapPin, IconRuler } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import listingImage from "../../../assets/images/login-cover.png";
import { useDispatch } from "react-redux";
import { fetchMlsMedia } from "../../../modules/dashboard/actions";

const MLSSingleCard = ({ card, onClick }) => {
  const [imageUrl, setImageUrl] = useState(null); // To store the image URL
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState("$");
  const [time, setTime] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const now = new Date().toISOString();
    setTime(now.split('.')[0] + "Z"); // Remove milliseconds
  }, []); // Run only once when the component mounts

  const getCurrencyFromAddress = (address) => {
    if (address.includes("ON") || address.includes("Canada")) {
      return "$"; // Canadian Dollar
    } else if (address.includes("St. Catharines")) {
      return "$"; // Specific city in Canada
    } else if (address.includes("US") || address.includes("United States")) {
      return "$"; // US Dollar
    }
    // Add more rules as needed
    return "$"; // Default to USD
  };

  console.log("image", imageUrl);

  const formatListedDate = (dateString) => {
    const listedDate = new Date(dateString);
    const currentDate = new Date();

    // Set both dates to midnight to ignore time differences
    listedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    // Calculate the days difference, ensuring no negative values
    const timeDifference = currentDate - listedDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert ms to days

    // Ensure no negative daysAgo by returning 0 if timeDifference is negative
    const daysAgoFinal = timeDifference < 0 ? 0 : daysAgo;

    return daysAgoFinal === 0
      ? "Listed today"
      : daysAgoFinal === 1
      ? "Listed 1 day ago"
      : `Listed ${daysAgoFinal} days ago`;
  };

  useEffect(() => {
    const fetchImage = async () => {
      if (card) {
        setLoading(true);
        try {
          const response = await dispatch(
            fetchMlsMedia(time,card.ListingKey)
          );
          console.log("Fetched images:", response);

          if (response && Array.isArray(response) && response.length > 0) {
            // Filter for valid media items
            const activeMedia = response.find(
              (item) =>
                item?.MediaURL &&
                item?.MediaStatus === "Active" &&
                item?.MediaType === "image/jpeg"
            );
            setImageUrl(activeMedia?.MediaURL);
          } else {
            setImageUrl();
          }
        } catch (error) {
          console.error("Failed to fetch image:", error);
          setImageUrl();
        } finally {
          setLoading(false);
        }
      } else {
        setImageUrl();
      }
    };

    fetchImage();

    if (card?.UnparsedAddress) {
      const detectedCurrency = getCurrencyFromAddress(card.UnparsedAddress);
      setCurrency(detectedCurrency);
    }
  }, [card, dispatch, time]);

  const cleanAddress = (address) => {
    return address
      .replace(/#/g, "") // Remove #
      .replace(/-\s*/g, "") // Remove - and any trailing spaces
      .replace(/,\s*ON.*/g, "") // Remove ", ON" and everything after it
      .trim(); // Trim extra spaces
  };

  return (
    // <div className="cards  " >
      <div key={card.ListingKey} className="card-listing cursor-pointer" onClick={() => onClick(card.ListingKey)}>
        <div className="position-relative">
          <img
            src={imageUrl || listingImage}
            alt={card.ListingKey || "Default"}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = listingImage;
            }}
          />
          <div className="position-absolute top-0 start-0 m-2 P-4">
            <span
              className={`status ${card.StandardStatus?.toLowerCase()} text-capitalize`}
            >
              {card.StandardStatus}
            </span>
          </div>
        </div>
        <div className="card-listing-details">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <h2>{currency}</h2>
              <h2>
                {card.ListPrice && Number(card.ListPrice).toLocaleString()}
              </h2>
            </div>
            {card.TransactionType && (
              <div className="h5 bg-primary-lt rounded-4 p-2 text-capitalize">
                {card.TransactionType}
              </div>
            )}
          </div>
          <p
            className="h2"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {cleanAddress(card.UnparsedAddress)}
          </p>
          <div className="d-flex gap-2 mb-3">
            <strong>{card.ListingKey}</strong>
          </div>
          <div className="d-flex gap-2">
            <IconMapPin />
            <p>{card.UnparsedAddress}</p>
          </div>
         
          <div className="d-flex gap-2">
            <div className="d-flex bg-gray-900 rounded-4 p-2 align-items-center gap-1">
              <IconBed />
              <div>
                <strong>{card.BedroomsTotal}</strong>
              </div>
            </div>
            <div className="d-flex bg-gray-900 rounded-4 p-2 align-items-center gap-1">
              <IconBath />
              <div>
                <strong>{card.BathroomsTotalInteger}</strong>
              </div>
            </div>
            {card.BuildingAreaTotal && <div className="d-flex bg-gray-900 rounded-4 p-2 align-items-center gap-1">
              <IconRuler />
              <div>
                <strong>
                  {card.BuildingAreaTotal}{" "}
                  {card.BuildingAreaUnits === "Square Feet" && (
                    <>
                      ft<sup>2</sup>
                    </>
                  )}
                </strong>
              </div>
            </div>}
          </div>
          {/* <div className="text-black mt-2">
            {card.OriginalEntryTimestamp &&
              formatListedDate(card.OriginalEntryTimestamp)}
          </div> */}
        </div>
      </div>
    // </div>
  );
};

export default MLSSingleCard;
