import React, { useState } from "react";
import AuthLayout from "../../../components/AuthLayout";
import { IconChevronLeft } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../actions";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      return setError("Email is required");
    }

    if (!validateEmail(email)) {
      return setError("Please enter a valid email address.");
    }

    try {
      setLoading(true);
      await dispatch(forgotPassword(email));
      navigate("../email-verification", { state: email });
      setLoading(false);
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred");
      setLoading(false);
    }
  };

  console.log(email);

  return (
    <AuthLayout>
      <Link to="/" className="back-button m-3 h5 mb-4">
        <div className="d-flex align-items-center">
          <IconChevronLeft />
          Back To Login
        </div>
      </Link>
      <div className="container container-tight my-5 px-5">
        <div className="text-center mb-4 mt-4">
          <h2 className="h1 text-center mb-3">
            <strong>Forgot password?</strong>
          </h2>
        </div>
        <p className="text-center mb-3">
          Please provide the email address associated with your account, and we
          will send a 4-digit code to reset your account password.
        </p>
        <form
          action="./"
          method="get"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          {error && <div className="text-danger mt-3 text-center">{error}</div>}
          <div className="mb-3">
            <label className="form-label">Email address</label>
            <input
              type="email"
              className="form-control form-control-rounded"
              placeholder="Email address"
              autoComplete="off"
              name="email"
              value={email}
              onChange={handleChange}
            />
          </div>

          <div className="form-footer mt-8">
            <button
              type="submit"
              className="btn btn-primary btn-pill w-100"
              disabled={loading}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
