export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAILURE = "EMAIL_VERIFICATION_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const CLEAR_PROFILE = "CLEAR_PROFILE";

//dashboard
export const IS_SKIPPED_SUCCESS = "IS_SKIPPED_SUCCESS";
export const IS_SKIPPED_FAILURE = "IS_SKIPPED_FAILURE";
export const ONBOARDING_SUCCESS = "ONBOARDING_SUCCESS";
export const ONBOARDING_FAILURE = "ONBOARDING_FAILURE";

// listing
export const FETCH_LISTINGS_REQUEST = 'FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_FAILURE = 'FETCH_LISTINGS_FAILURE';

export const FETCH_ALL_LISTINGS_REQUEST = 'FETCH_ALL_LISTINGS_REQUEST';
export const FETCH_ALL_LISTINGS_SUCCESS = 'FETCH_ALL_LISTINGS_SUCCESS';
export const FETCH_ALL_LISTINGS_FAILURE = 'FETCH_ALL_LISTINGS_FAILURE';

export const FETCH_LISTING_BY_ID_REQUEST = "FETCH_LISTING_BY_ID_REQUEST";
export const FETCH_LISTING_BY_ID_SUCCESS = "FETCH_LISTING_BY_ID_SUCCESS";
export const FETCH_LISTING_BY_ID_FAILURE = "FETCH_LISTING_BY_ID_FAILURE";
export const FETCH_LISTING_BY_STATUS_REQUEST = "FETCH_LISTING_BY_STATUS_REQUEST";
export const FETCH_LISTING_BY_STATUS_SUCCESS = "FETCH_LISTING_BY_STATUS_SUCCESS";
export const FETCH_LISTING_BY_STATUS_FAILURE = "FETCH_LISTING_BY_STATUS_FAILURE";

export const CREATE_LISTING_REQUEST = 'CREATE_LISTING_REQUEST';
export const CREATE_LISTING_SUCCESS = 'CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_FAILURE = 'CREATE_LISTING_FAILURE';

export const UPDATE_LISTING_REQUEST = 'UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_FAILURE = 'UPDATE_LISTING_FAILURE';

export const DELETE_LISTING_REQUEST = "DELETE_LISTING_REQUEST";
export const DELETE_LISTING_SUCCESS = "DELETE_LISTING_SUCCESS";
export const DELETE_LISTING_FAILURE = "DELETE_LISTING_FAILURE";

export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const ADD_LEADS_REQUEST = "ADD_LEADS_REQUEST";
export const ADD_LEADS_SUCCESS = "ADD_LEADS_SUCCESS";
export const ADD_LEADS_FAILURE = "ADD_LEADS_FAILURE";

export const FETCH_LEADS_REQUEST = "FETCH_LEADS_REQUEST";
export const FETCH_LEADS_SUCCESS = "FETCH_LEADS_SUCCESS";
export const FETCH_LEADS_FAILURE = "FETCH_LEADS_FAILURE";

export const FETCH_LEAD_BY_ID_REQUEST = "FETCH_LEAD_BY_ID_REQUEST";
export const FETCH_LEAD_BY_ID_SUCCESS = "FETCH_LEAD_BY_ID_SUCCESS";
export const FETCH_LEAD_BY_ID_FAILURE = "FETCH_LEAD_BY_ID_FAILURE";

export const UPDATE_LEAD_REQUEST = "UPDATE_LEAD_REQUEST";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const UPDATE_LEAD_FAILURE = "UPDATE_LEAD_FAILURE";

export const DELETE_LEAD_REQUEST = "DELETE_LEAD_REQUEST";
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";
export const DELETE_LEAD_FAILURE = "DELETE_LEAD_FAILURE";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const CREATE_NEWS_REQUEST = "CREATE_NEWS_REQUEST";
export const CREATE_NEWS_SUCCESS= "CREATE_NEWS_SUCCESS";
export const CREATE_NEWS_FAILURE = "CREATE_NEWS_FAILURE";

export const FETCH_NEWS_REQUEST = "FETCH_NEWS_REQUEST";
export const FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_FAILURE = "FETCH_NEWS_FAILURE";

export const FETCH__ALL_NEWS_REQUEST = "FETCH_ALL_NEWS_REQUEST";
export const FETCH__ALL_NEWS_SUCCESS = "FETCH__ALL_NEWS_SUCCESS";
export const FETCH__ALL_NEWS_FAILURE = "FETCH__ALL_NEWS_FAILURE";

export const UPDATE_NEWS_REQUEST = "UPDATE_NEWS_REQUEST";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_FAILURE = "UPDATE_NEWS_FAILURE";

export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAILURE = "DELETE_NEWS_FAILURE";

export const FETCH_AGENT_REQUEST = "FETCH_AGENT_REQUEST";
export const FETCH_AGENT_SUCCESS = "FETCH_AGENT_SUCCESS";
export const FETCH_AGENT_FAILURE = "FETCH_AGENT_FAILURE";

export const FETCH_ALL_AGENT_REQUEST = "FETCH_ALL_AGENT_REQUEST";
export const FETCH_ALL_AGENT_SUCCESS = "FETCH_ALL_AGENT_SUCCESS";
export const FETCH_ALL_AGENT_FAILURE = "FETCH_ALL_AGENT_FAILURE";



export const CREATE_APPOINTMENT_REQUEST = "CREATE_APPOINTMENT_REQUEST";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const CREATE_APPOINTMENT_FAILURE = "CREATE_APPOINTMENT_FAILURE";

export const FETCH_NEWS_BY_ID_REQUEST = "FETCH_NEWS_BY_ID_REQUEST";
export const FETCH_NEWS_BY_ID_SUCCESS = "FETCH_NEWS_BY_ID_SUCCESS";
export const FETCH_NEWS_BY_ID_FAILURE = "FETCH_NEWS_BY_ID_FAILURE";

export const DOWNLOAD_IMAGE_REQUEST = "DOWNLOAD_IMAGE_REQUEST";
export const DOWNLOAD_IMAGE_SUCCESS = "DOWNLOAD_IMAGE_SUCCESS";
export const DOWNLOAD_IMAGE_FAILURE = "DOWNLOAD_IMAGE_FAILURE";

export const FETCH_ALL_LEADS_REQUEST = "FETCH_ALL_LEADS_REQUEST";
export const FETCH_ALL_LEADS_SUCCESS = "FETCH_ALL_LEADS_SUCCESS";
export const FETCH_ALL_LEADS_FAILURE = "FETCH_ALL_LEADS_FAILURE";

export const STRIPE_PAYMENT_REQUEST = "STRIPE_PAYMENT_REQUEST";
export const STRIPE_PAYMENT_SUCCESS = "STRIPE_PAYMENT_SUCCESS";
export const STRIPE_PAYMENT_FAILURE = "STRIPE_PAYMENT_FAILURE";

export const STRIPE_PAYMENT_CHECKOUT_REQUEST = "STRIPE_PAYMENT_CHECKOUT_REQUEST";
export const STRIPE_PAYMENT_CHECKOUT_SUCCESS = "STRIPE_PAYMENT_CHECKOUT_SUCCESS";
export const STRIPE_PAYMENT_CHECKOUT_FAILURE = "STRIPE_PAYMENT_CHECKOUT_FAILURE";

export const CREATE_SHOWCASE_REQUEST = "CREATE_SHOWCASE_REQUEST";
export const CREATE_SHOWCASE_SUCCESS = "CREATE_SHOWCASE_SUCCESS";
export const CREATE_SHOWCASE_FAILURE = "CREATE_SHOWCASE_FAILURE";

export const FETCH_SHOWCASE_REQUEST = "FETCH_SHOWCASE_REQUEST";
export const FETCH_SHOWCASE_SUCCESS = "FETCH_SHOWCASE_SUCCESS";
export const FETCH_SHOWCASE_FAILURE = "FETCH_SHOWCASE_FAILURE";
export const FETCH_ALL_SHOWCASE_REQUEST = "FETCH_ALL_SHOWCASE_REQUEST";
export const FETCH_ALL_SHOWCASE_SUCCESS = "FETCH_ALL_SHOWCASE_SUCCESS";
export const FETCH_ALL_SHOWCASE_FAILURE = "FETCH_ALL_SHOWCASE_FAILURE";

export const DELETE_SHOWCASE_REQUEST = "DELETE_SHOWCASE_REQUEST";
export const DELETE_SHOWCASE_SUCCESS = "DELETE_SHOWCASE_SUCCESS";
export const DELETE_SHOWCASE_FAILURE = "DELETE_SHOWCASE_FAILURE";

export const UPDATE_SHOWCASE_REQUEST = "UPDATE_SHOWCASE_REQUEST";
export const UPDATE_SHOWCASE_SUCCESS = "UPDATE_SHOWCASE_SUCCESS";
export const UPDATE_SHOWCASE_FAILURE = "UPDATE_SHOWCASE_FAILURE";

export const FETCH_IDX_PROPERTIES_REQUEST = "FETCH_IDX_PROPERTIES_REQUEST";
export const FETCH_IDX_PROPERTIES_SUCCESS = "FETCH_IDX_PROPERTIES_SUCCESS";
export const FETCH_IDX_PROPERTIES_FAILURE = "FETCH_IDX_PROPERTIES_FAILURE";
export const FETCH_VOW_PROPERTIES_REQUEST = "FETCH_VOW_PROPERTIES_REQUEST";
export const FETCH_VOW_PROPERTIES_SUCCESS = "FETCH_VOW_PROPERTIES_SUCCESS";
export const FETCH_VOW_PROPERTIES_FAILURE = "FETCH_VOW_PROPERTIES_FAILURE";

export const MLS_MEDIA_REQUEST = "MLS_MEDIA_REQUEST"
export const MLS_MEDIA_SUCCESS = "MLS_MEDIA_SUCCESS"
export const MLS_MEDIA_FAILURE = "MLS_MEDIA_FAILURE"

export const FETCH_USER_BY_ID_REQUEST = "FETCH_USER_BY_ID_REQUEST";
export const FETCH_USER_BY_ID_SUCCESS = "FETCH_USER_BY_ID_SUCCESS";
export const FETCH_USER_BY_ID_FAILURE = "FETCH_USER_BY_ID_FAILURE";

export const MAIL_WELCOME_REQUEST = "MAIL_WELCOME_REQUEST";
export const MAIL_WELCOME_SUCCESS = "MAIL_WELCOME_SUCCESS";
export const MAIL_WELCOME_FAILURE = "MAIL_WELCOME_FAILURE";	
