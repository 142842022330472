import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllListings,
  fetchIdxProperties,
  fetchVowProperties,
} from "../../../modules/dashboard/actions";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import SelectedListingCard from "../SelectedListingCard";
import { getName as getCountryName } from "country-list";
import "../../../assets/styles/mlsmap.scss";
import logo from "../../../assets/images/myRealPage.png";
import L from "leaflet";
import { IconMapPinFilled, IconSearch } from "@tabler/icons-react";
import ReactDOMServer from "react-dom/server";
import SingleCard from "./SingleCard";
import { getCountryDataList } from "countries-list";
import MLSCard from "../MLSCard";
import MLSSingleCard from "./MLSSingleCard";
import { LOCATION_URL } from "../../../config/config";
import MLSListingPreview from "../MlsListingPreview";

const customIcon = new L.DivIcon({
  className: "custom-tabler-icon",
  html: ReactDOMServer.renderToString(
    <IconMapPinFilled size={24} color="#1655FB" />
  ),
  iconSize: [24, 24],
  iconAnchor: [12, 24],
});

const MLSMapPreview = () => {
  const dispatch = useDispatch();
  const { allListings } = useSelector((state) => state.dashboard);
  const [userId, setUserId] = useState("");
  const [listingIds, setListingIds] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [listingsWithCoords, setListingsWithCoords] = useState([]);
  const [mapCenter, setMapCenter] = useState([37.0902, -95.7129]); // Centered on the United States
  const [searchTerm, setSearchTerm] = useState("");
  const [mls, setMls] = useState("");
  const [userType, setUserType] = useState("");
  const [MLSCards, setMLSCards] = useState([]);
  const [mlsLoading, setMLSLoading] = useState(false);
  const [isMLSPreviewModalOpen, setMLSIsPreviewModalOpen] = useState(false);
  const [selectedMLS, setSelectedMLS] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => setShowFilters(!showFilters);

  const [formData, setFormData] = useState({
    // Initialize form data
    searchTerm: "",
    location: "",
    minPrice: "",
    maxPrice: "",
    currency: "",
    propertyType: "",
    propertyCategory: "",
    status: "",
    year: "",
    approxArea: "",
    areaType: "SQ FT",
    buildingInfo: {
      bedroom: 0,
      bathroom: 0,
    },
  });

  const SetViewOnChange = ({ center }) => {
    const map = useMap();
    map.setView(center); // Update the map view to the new center
    return null;
  };

  const countries = getCountryDataList();
  const [bedValue, setBedValue] = useState(
    formData?.buildingInfo?.bedroom || 0
  );
  const [bathValue, setBathValue] = useState(
    formData?.buildingInfo?.bathroom || 0
  );
  const currentYear = new Date().getFullYear();
  const [yearError, setYearError] = useState("");

  const [selectedCurrency, setSelectedCurrency] = useState(
    formData?.currency || ""
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Extract userId and listingIds from URL parameters
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get("userId");
    const listingIdsParam = urlParams.get("listingIds");
    const mlsParam = urlParams.get("mls");
    const userTypeParam = urlParams.get("type");

    setUserId(userIdParam);
    setMls(mlsParam);
    setUserType(userTypeParam);
    if (listingIdsParam) {
      const idsArray = listingIdsParam.split(",");
      setListingIds(idsArray);
    }
  }, []);

  // Fetch all listings for the user
  useEffect(() => {
    if (userId) {
      dispatch(fetchAllListings(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    const fetchProperties = async () => {
      // Set loading to true before the API call
      setMLSLoading(true);
      try {
        let response;
        if (userType === "brokerage") {
          response = await dispatch(fetchIdxProperties("ListOfficeKey", mls));
        } else if (userType === "solo") {
          response = await dispatch(fetchVowProperties("ListAgentKey", mls));
        } else if (mls) {
          response = await dispatch(fetchVowProperties("ListAgentKey", mls));
          console.log("Response: ", response);
          if (response.length === 0) {
            response = await dispatch(fetchIdxProperties("ListOfficeKey", mls));
          }
        }
        console.log("mls", response);
        setMLSCards(response.value || response); // Use response.value if it exists, else use response directly
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      } finally {
        // Set loading to false after the API call completes
        setMLSLoading(false);
      }
    };

    fetchProperties();
  }, [dispatch, userType, mls]);

  // Filter listings based on listingIds from URL
  useEffect(() => {
    if (MLSCards && listingIds.length > 0) {
      const filtered = MLSCards.filter((listing) =>
        listingIds.includes(listing.ListingKey)
      );
      setFilteredListings(filtered);
    }
  }, [MLSCards, listingIds]);

  console.log(filteredListings);

  const validateYear = (value) => {
    const year = parseInt(value, 10);
    if (year <= currentYear) {
      setYearError("");
      handleChange({ target: { name: "year", value } });
    } else {
      setYearError(`Year must not be greater than ${currentYear}.`);
      handleChange({ target: { name: "year", value } });
    }
  };

  const getSingleCurrency = (country) => {
    return Array.isArray(country.currency)
      ? country.currency[0]
      : country.currency;
  };

  // Handle country change to update both currency and selected country code
  const handleCountryChange = (countryCode) => {
    const selectedCountry = countries[countryCode];
    if (!countryCode) {
      setSelectedCurrency("");
      setSelectedCountryCode("");
      setFormData((prevData) => ({
        ...prevData,
        currency: "", // Clear currency to show all listings
      }));
    } else if (selectedCountry) {
      const singleCurrency = getSingleCurrency(selectedCountry);
      setSelectedCurrency(singleCurrency);
      setSelectedCountryCode(countryCode);
      setFormData((prevData) => ({
        ...prevData,
        currency: singleCurrency,
      }));
    }
  };

  useEffect(() => {
    const countryCode = Object.keys(countries).find(
      (code) => getSingleCurrency(countries[code]) === formData.currency
    );
    if (countryCode) {
      setSelectedCountryCode(countryCode);
    }
  }, [formData.currency]);

  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  const incrementBed = () => {
    setBedValue((prevValue) => prevValue + 1);
  };

  const decrementBed = () => {
    setBedValue((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  const incrementBath = () => {
    setBathValue((prevValue) => prevValue + 1);
  };

  const decrementBath = () => {
    setBathValue((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  // Function to geocode address
  const geocodeAddress = async (listing) => {
    // Construct full address based on the new structure
    const fullAddress = `${
      listing.UnitNumber ? `#${listing.UnitNumber} - ` : ""
    }${listing.StreetNumber || ""} ${listing.StreetName || ""} ${
      listing.StreetSuffix || ""
    }, ${listing.City || ""}, ${listing.StateOrProvince || ""}, ${
      listing.Country || ""
    }, ${listing.PostalCode || ""}`;

    console.log("Geocoding address:", fullAddress);

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          fullAddress
        )}&key=${LOCATION_URL}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.results && data.results.length > 0) {
        return {
          latitude: data.results[0].geometry.location.lat,
          longitude: data.results[0].geometry.location.lng,
        };
      } else {
        console.warn("No geocoding results for:", fullAddress);
        return null;
      }
    } catch (error) {
      console.error("Geocoding error:", error.message);
      return null;
    }
  };

  // Update listings with coordinates
  useEffect(() => {
    const updateListingsWithCoordinates = async () => {
      const updatedListings = await Promise.all(
        filteredListings.map(async (listing) => {
          let { latitude, longitude } = listing;

          if (!latitude || !longitude) {
            const coords = await geocodeAddress(listing); // Pass the whole listing object
            if (coords) {
              latitude = coords.latitude;
              longitude = coords.longitude;
            } else {
              console.warn(
                `Skipping listing due to geocoding failure: ${listing.id}`
              );
              return null; // Skip this listing if geocoding fails
            }
          }

          return {
            ...listing,
            address: {
              ...listing.address,
              latitude,
              longitude,
            },
          };
        })
      );

      const validListings = updatedListings.filter(Boolean);
      setListingsWithCoords(validListings);
      //   setFilteredListingIds(updatedListings.map((listing) => listing.ListingKey))
    };
    if (filteredListings.length > 0) {
      updateListingsWithCoordinates();
    } else {
      setListingsWithCoords([]);
    }
  }, [filteredListings]);

  // Update map center based on listings with valid coordinates
  useEffect(() => {
    const calculateDenseAreaCenter = (listings) => {
      const clusters = [];
      const radius = 0.05; // Define a proximity radius for clustering (degrees)

      listings.forEach((listing) => {
        const { latitude, longitude } = listing.address;
        if (!latitude || !longitude) return;

        // Find a cluster this listing belongs to
        let addedToCluster = false;
        for (let cluster of clusters) {
          const distance = Math.sqrt(
            Math.pow(latitude - cluster.latitude, 2) +
              Math.pow(longitude - cluster.longitude, 2)
          );
          if (distance < radius) {
            cluster.listings.push(listing);
            cluster.latitude =
              cluster.listings.reduce((sum, l) => sum + l.address.latitude, 0) /
              cluster.listings.length;
            cluster.longitude =
              cluster.listings.reduce(
                (sum, l) => sum + l.address.longitude,
                0
              ) / cluster.listings.length;
            addedToCluster = true;
            break;
          }
        }

        // If no cluster is found, create a new one
        if (!addedToCluster) {
          clusters.push({
            latitude,
            longitude,
            listings: [listing],
          });
        }
      });

      // Find the cluster with the most listings
      const densestCluster = clusters.reduce((maxCluster, cluster) =>
        cluster.listings.length > maxCluster.listings.length
          ? cluster
          : maxCluster
      );

      return {
        latitude: densestCluster.latitude,
        longitude: densestCluster.longitude,
      };
    };

    console.log("Listings with coords:", listingsWithCoords);

    if (listingsWithCoords.length > 0) {
      const center = calculateDenseAreaCenter(listingsWithCoords);
      console.log("Map center:", center);
      setMapCenter([center.latitude, center.longitude]);
    } else {
      setMapCenter([51.505, -0.09]); // Default fallback location
    }
  }, [listingsWithCoords]);

  console.log("Map center:", mapCenter);

  const filterListings = useCallback(() => {
    return MLSCards.filter((listing) => {
      const matchesSearchTerm =
        !formData.searchTerm ||
        (listing.UnparsedAddress &&
          listing.UnparsedAddress.toLowerCase().includes(
            formData.searchTerm.toLowerCase()
          ));

      const matchesLocation =
        !formData.location ||
        (listing.UnparsedAddress &&
          listing.UnparsedAddress.toLowerCase().includes(
            formData.location.toLowerCase()
          ));

      const matchesMinPrice =
        !formData.minPrice ||
        (listing.ListPrice && listing.ListPrice >= Number(formData.minPrice));

      const matchesMaxPrice =
        !formData.maxPrice ||
        (listing.ListPrice && listing.ListPrice <= Number(formData.maxPrice));

      // const matchesCurrency =  !formData.currency || listing.currency === formData.currency;

      const matchesPropertyType =
        !formData.propertyType ||
        listing.PropertyType === formData.propertyType;

      const matchesPropertyCategory =
        !formData.propertyCategory ||
        listing.TransactionType === formData.propertyCategory;

      const matchesStatus =
        !formData.status || listing.MlsStatus === formData.status;

      const matchesYear =
        !formData.year ||
        (listing.TaxYear && listing.TaxYear === formData.year);

      const matchesBedroom =
        !bedValue ||
        (listing.BedroomsTotal && listing.BedroomsTotal === bedValue);

      const matchesBathroom =
        !bathValue ||
        (listing.BathroomsTotalInteger &&
          listing.BathroomsTotalInteger === bathValue);

      return (
        matchesSearchTerm &&
        matchesLocation &&
        matchesMinPrice &&
        matchesMaxPrice &&
        // matchesCurrency &&
        matchesPropertyType &&
        matchesPropertyCategory &&
        matchesStatus &&
        matchesYear &&
        matchesBedroom &&
        matchesBathroom
      );
    });
  }, [MLSCards, formData, bedValue, bathValue]); // Only depend on allListings and formData

  const handleFilter = () => {
    const result = filterListings();
    setFilteredListings(result); // Update filtered listings when button is clicked
  };

  const openListingPreviewModal = (listing) => {
    // const baseUrl = window.location.origin;
    // const preview = `${baseUrl}/listingMLS/${listing}?userId=${userId}&mls=${mls}&type=${userType}`;
    // window.location.href = preview;
    setSelectedMLS(listing);
    setMLSIsPreviewModalOpen(true);
  };
  console.log(filteredListings);

  return (
    <div>
      <MLSListingPreview
        isOpen={isMLSPreviewModalOpen}
        closeModal={() => setMLSIsPreviewModalOpen(false)}
        listings={MLSCards}
        selectedListing={selectedMLS}
        userId={userId}
      />
      <div
        className={`fixed-search-section ${
          showFilters ? "show" : "hide"
        } d-none d-md-block`}
      >
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-2 mb-3">
          {/* <img
            src={logo}
            alt="logo"
            className="img-fluid me-4 ms-3" // Use Bootstrap class for responsive image
            style={{ maxHeight: "25px" }} // Ensure max height is set for the image
          /> */}
          <div className="mb-1 w-100">
            <label>Search</label>
            <div className="input-icon">
              <span className="input-icon-addon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="10" cy="10" r="7" />
                  <line x1="21" y1="21" x2="15" y2="15" />
                </svg>
              </span>
              <input
                type="text"
                className="form-control form-control-rounded  w-100"
                placeholder="Search for listings"
                name="searchTerm"
                value={formData.searchTerm}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-1 w-100">
            <label>Price</label>
            <div className="d-flex align-items-center">
              <input
                type="number"
                name="minPrice"
                placeholder="Min Price"
                className="form-control round-left"
                value={formData.minPrice}
                onChange={handleChange}
              />
              <input
                type="number"
                name="maxPrice"
                placeholder="Max Price"
                className="form-control round-right"
                value={formData.maxPrice}
                onChange={handleChange}
              />
              {/* {selectedCurrency && (
                <div className="form-control border-end mt-2 w-25 bg-gray-500">
                  {selectedCurrency}
                </div>
              )}
              <select
                className="form-select text-secondary"
                name="country"
                value={selectedCountryCode}
                onChange={(e) => handleCountryChange(e.target.value)}
                style={styles.select}
              >
                <option value="">Currency</option>{" "}
                {Object.keys(countries).map((code) => (
                  <option key={code} value={code}>
                    {countries[code].name}
                  </option>
                ))}
              </select> */}
            </div>
          </div>
          <div className="mb-1 w-100">
            <label>Status</label>
            <select
              className="form-select rounded-5"
              name="status"
              onChange={handleChange}
            >
              <option value="" className="form-select">
                All
              </option>
              <option value="active" className="form-select">
                Active
              </option>
              <option value="New" className="form-select">
                New
              </option>
              <option value="Price Change" className="form-select">
                Price Change
              </option>
              <option value="cancelled" className="form-select">
                Cancelled
              </option>
              <option value="deleted" className="form-select">
                Deleted
              </option>
              <option value="expired" className="form-select">
                Expired
              </option>
              <option value="on hold" className="form-select">
                On Hold
              </option>
              <option value="pending" className="form-select">
                Pending
              </option>
              <option value="sold" className="form-select">
                Sold
              </option>
              <option value="terminated" className="form-select">
                Terminated
              </option>
              <option value="unknown" className="form-select">
                Unknown
              </option>
              <option value="wait approval" className="form-select">
                Wait Approval
              </option>
            </select>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-2">
          <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-1 w-100">
            <div className="mb-3 w-100">
              <label>Type</label>
              <select
                className="form-select rounded-5"
                name="propertyType"
                onChange={handleChange}
              >
                <option value="" className="form-select">
                  All
                </option>
                <option value="Commercial" className="form-select">
                  Commercial
                </option>
                <option
                  value="Residential Condo & Other"
                  className="form-select"
                >
                  Condo
                </option>
                <option value="Residential Freehold" className="form-select">
                  Freehold
                </option>
              </select>
            </div>
            <div className="mb-3 w-100">
              <label>Category</label>
              <select
                className="form-select rounded-5"
                name="propertyCategory"
                onChange={handleChange}
              >
                <option value="" className="form-select">
                  All
                </option>
                <option value="For Sale" className="form-select">
                  Sale
                </option>
                <option value="For Lease" className="form-select">
                  Lease
                </option>
              </select>
            </div>
            <div className="mb-3 w-100">
            {/* <div style={styles.container}> */}
              <label>Year Built</label>
              <input
                type="number"
                name="year"
                placeholder="Year Built"
                className="form-control form-control-rounded  w-100"
                onChange={(e) => validateYear(e.target.value)}
              />
              {/* {yearError && <small className="text-danger">{yearError}</small>} */}
              {/* </div> */}
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row  justify-content-between gap-2 mb-1 w-100">
            

            <div className="mb-3 w-100">
              <div style={styles.container}>
                <label className="mb-2"> Bedrooms</label>
                <div style={styles.inputContainer}>
                  <button
                    style={styles.button}
                    onClick={decrementBed}
                    disabled={bedValue === 0}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={bedValue}
                    name="bedroom"
                    onChange={(e) =>
                      setBedValue(
                        Math.max(0, parseInt(e.target.value, 10) || 0)
                      )
                    }
                    style={styles.input}
                  />

                  <button style={styles.button} onClick={incrementBed}>
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="mb-3 w-100">
              <div style={styles.container}>
                <label className="mb-2"> Bathrooms</label>
                <div style={styles.inputContainer}>
                  <button
                    style={styles.button}
                    onClick={decrementBath}
                    disabled={bathValue === 0}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={bathValue}
                    name="bathroom"
                    onChange={(e) => {
                      const newValue = Math.max(
                        0,
                        parseInt(e.target.value, 10)
                      );
                      bathValue(isNaN(newValue) ? 0 : newValue);
                    }}
                    style={styles.input}
                  />
                  <button style={styles.button} onClick={incrementBath}>
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              onClick={handleFilter}
              className="btn btn-primary btn-pill "
            >
              Search
            </button>
          </div>
        </div>
      </div>

      {!showFilters && (
        <button
          className="floating-button d-md-none"
          onClick={toggleFilters}
          aria-label="Open Filters"
        >
          <IconSearch />
        </button>
      )}

      {showFilters && (
        <div className="filter-modal">
          <div className="filter-header">
            <h5>Filters</h5>
            <button
              className="btn-close"
              onClick={toggleFilters}
              aria-label="Close Filters"
            ></button>
          </div>
          <div className="filter-body">
            {/* Add filter inputs here */}
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-2 mb-3">
              {/* <img
            src={logo}
            alt="logo"
            className="img-fluid me-4 ms-3" // Use Bootstrap class for responsive image
            style={{ maxHeight: "25px" }} // Ensure max height is set for the image
          /> */}
              <div className="mb-1 w-100">
                <label>Search</label>
                <div className="input-icon">
                  <span className="input-icon-addon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="10" cy="10" r="7" />
                      <line x1="21" y1="21" x2="15" y2="15" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control form-control-rounded  w-100"
                    placeholder="Search for listings"
                    name="searchTerm"
                    value={formData.searchTerm}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mb-1 w-100">
                <label>Price</label>
                <div className="d-flex align-items-center">
                  <input
                    type="number"
                    name="minPrice"
                    placeholder="Min Price"
                    className="form-control round-left"
                    value={formData.minPrice}
                    onChange={handleChange}
                  />
                  <input
                    type="number"
                    name="maxPrice"
                    placeholder="Max Price"
                    className="form-control round-right"
                    value={formData.maxPrice}
                    onChange={handleChange}
                  />
                  {/* {selectedCurrency && (
                <div className="form-control border-end mt-2 w-25 bg-gray-500">
                  {selectedCurrency}
                </div>
              )}
              <select
                className="form-select text-secondary"
                name="country"
                value={selectedCountryCode}
                onChange={(e) => handleCountryChange(e.target.value)}
                style={styles.select}
              >
                <option value="">Currency</option>{" "}
                {Object.keys(countries).map((code) => (
                  <option key={code} value={code}>
                    {countries[code].name}
                  </option>
                ))}
              </select> */}
                </div>
              </div>
              <div className="mb-1 w-100">
                <label>Status</label>
                <select
                  className="form-select rounded-5"
                  name="status"
                  onChange={handleChange}
                >
                  <option value="" className="form-select">
                    All
                  </option>
                  <option value="active" className="form-select">
                    Active
                  </option>
                  <option value="New" className="form-select">
                    New
                  </option>
                  <option value="Price Change" className="form-select">
                    Price Change
                  </option>
                  <option value="cancelled" className="form-select">
                    Cancelled
                  </option>
                  <option value="deleted" className="form-select">
                    Deleted
                  </option>
                  <option value="expired" className="form-select">
                    Expired
                  </option>
                  <option value="on hold" className="form-select">
                    On Hold
                  </option>
                  <option value="pending" className="form-select">
                    Pending
                  </option>
                  <option value="sold" className="form-select">
                    Sold
                  </option>
                  <option value="terminated" className="form-select">
                    Terminated
                  </option>
                  <option value="unknown" className="form-select">
                    Unknown
                  </option>
                  <option value="wait approval" className="form-select">
                    Wait Approval
                  </option>
                </select>
              </div>
            </div>

            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-2">
              <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-1 w-100">
                <div className="mb-3 w-100">
                  <label>Property Type</label>
                  <select
                    className="form-select rounded-5"
                    name="propertyType"
                    onChange={handleChange}
                  >
                    <option value="" className="form-select">
                      All
                    </option>
                    <option value="Commercial" className="form-select">
                      Commercial
                    </option>
                    <option
                      value="Residential Condo & Other"
                      className="form-select"
                    >
                      Condo
                    </option>
                    <option
                      value="Residential Freehold"
                      className="form-select"
                    >
                      Freehold
                    </option>
                  </select>
                </div>
                <div className="mb-3 w-100">
                  <label>Category</label>
                  <select
                    className="form-select rounded-5"
                    name="propertyCategory"
                    onChange={handleChange}
                  >
                    <option value="" className="form-select">
                      All
                    </option>
                    <option value="For Sale" className="form-select">
                      Sale
                    </option>
                    <option value="For Lease" className="form-select">
                      Lease
                    </option>
                  </select>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row  justify-content-between gap-2 mb-1 w-100">
                <div className="mb-3 w-100">
                  <label>Year Built</label>
                  <input
                    type="number"
                    name="year"
                    placeholder="Year Built"
                    className="form-control form-control-rounded"
                    onChange={(e) => validateYear(e.target.value)}
                  />
                  {yearError && (
                    <small className="text-danger">{yearError}</small>
                  )}
                </div>

                <div className="mb-3 w-100">
                  <div style={styles.container}>
                    <label className="mb-2"> Bedrooms</label>
                    <div style={styles.inputContainer}>
                      <button
                        style={styles.button}
                        onClick={decrementBed}
                        disabled={bedValue === 0}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        value={bedValue}
                        name="bedroom"
                        onChange={(e) =>
                          setBedValue(
                            Math.max(0, parseInt(e.target.value, 10) || 0)
                          )
                        }
                        style={styles.input}
                      />

                      <button style={styles.button} onClick={incrementBed}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mb-3 w-100">
                  <div style={styles.container}>
                    <label className="mb-2"> Bathrooms</label>
                    <div style={styles.inputContainer}>
                      <button
                        style={styles.button}
                        onClick={decrementBath}
                        disabled={bathValue === 0}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        value={bathValue}
                        name="bathroom"
                        onChange={(e) => {
                          const newValue = Math.max(
                            0,
                            parseInt(e.target.value, 10)
                          );
                          bathValue(isNaN(newValue) ? 0 : newValue);
                        }}
                        style={styles.input}
                      />
                      <button style={styles.button} onClick={incrementBath}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  onClick={handleFilter}
                  className="btn btn-primary btn-pill "
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="map-preview-container" style={{ height: "100vh" }}>
        <div className="map-preview-content">
          {/* Map Container */}
          <div className="map-container-mls ">
            <MapContainer
              center={mapCenter}
              zoom={8}
              minZoom={2}
              style={{ height: "100%", width: "100%" }}
              className="map"
            >
              {/* Ensure the center updates dynamically */}
              <SetViewOnChange center={mapCenter} />
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {listingsWithCoords.map((listing) => {
                const { latitude, longitude } = listing.address;
                if (latitude && longitude) {
                  return (
                    <Marker
                      key={listing.id}
                      position={[parseFloat(latitude), parseFloat(longitude)]}
                      icon={customIcon}
                    >
                      <Popup>
                        <MLSSingleCard
                          card={listing}
                          onClick={openListingPreviewModal}
                        />
                      </Popup>
                    </Marker>
                  );
                }
                return null;
              })}
            </MapContainer>
          </div>

          {/* Listings Cards */}
          <div className="listing-cards " style={{ height: "100%" }}>
            {mlsLoading ? (
              <div className="loading-container">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100vh" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
                {/* Use any loading spinner */}
              </div>
            ) : (
              filteredListings.length > 0 && (
                <div className="cards-container">
                  {filteredListings.map((listing) => (
                    <MLSSingleCard
                      key={listing.id} // Ensure unique key
                      card={listing}
                      onClick={openListingPreviewModal}
                    />
                  ))}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "20px",
    overflow: "hidden",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#f0f0f0",
    border: "none",
    borderRadius: "30px",
    padding: "6px 8px 6px 8px",
    cursor: "pointer",
    fontSize: "16px",
    width: "40px",
    textAlign: "center",
    margin: "3px",
  },
  input: {
    width: "40px",
    textAlign: "center",
    border: "none",
    outline: "none",
    fontSize: "13px",
    margin: "0 3px",
    backgroundColor: "white",
  },
  select: {
    width: "90px",
    border: "none",
    outline: "none",
    padding: "10px",
    borderRadius: "0 20px 20px 0",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
};
export default MLSMapPreview;
