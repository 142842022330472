import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllListings } from "../../../modules/dashboard/actions";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import SelectedListingCard from "../SelectedListingCard";
import { getName as getCountryName } from "country-list";
import "../../../assets/styles/map.scss";
import logo from "../../../assets/images/myRealPage.png";
import L from "leaflet";
import { IconMapPinFilled } from "@tabler/icons-react";
import ReactDOMServer from "react-dom/server";
import SingleCard from "./SingleCard";
import { getCountryDataList } from "countries-list";
import { LOCATION_URL } from "../../../config/config";

const customIcon = new L.DivIcon({
  className: "custom-tabler-icon",
  html: ReactDOMServer.renderToString(
    <IconMapPinFilled size={24} color="#1655FB" />
  ),
  iconSize: [24, 24],
  iconAnchor: [12, 24],
});


const MapPreview = () => {
  const dispatch = useDispatch();
  const { allListings } = useSelector((state) => state.dashboard);
  const [userId, setUserId] = useState("");
  const [listingIds, setListingIds] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [listingsWithCoords, setListingsWithCoords] = useState([]);
  const [mapCenter, setMapCenter] = useState([37.0902, -95.7129]); // Centered on the United States
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({
    // Initialize form data
    searchTerm: "",
    location: "",
    minPrice: "",
    maxPrice: "",
    currency: "",
    propertyType: "",
    propertyCategory: "",
    status: "",
    year: "",
    approxArea: "",
    areaType: "SQ FT",
    buildingInfo: {
      bedroom: 0,
      bathroom: 0,
    },
  });
  const countries = getCountryDataList();
  const [bedValue, setBedValue] = useState(
    formData?.buildingInfo?.bedroom || 0
  );
  const [bathValue, setBathValue] = useState(
    formData?.buildingInfo?.bathroom || 0
  );
  const currentYear = new Date().getFullYear();
  const [yearError, setYearError] = useState("");

  const [selectedCurrency, setSelectedCurrency] = useState(
    formData?.currency || ""
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Extract userId and listingIds from URL parameters
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get("userId");
    const listingIdsParam = urlParams.get("listingIds");

    setUserId(userIdParam);

    if (listingIdsParam) {
      const idsArray = listingIdsParam.split(",");
      setListingIds(idsArray);
    }
  }, []);

  // Fetch all listings for the user
  useEffect(() => {
    if (userId) {
      dispatch(fetchAllListings(userId));
    }
  }, [dispatch, userId]);

  // Filter listings based on listingIds from URL
  useEffect(() => {
    if (allListings && listingIds.length > 0) {
      const filtered = allListings.filter((listing) =>
        listingIds.includes(listing.id)
      );
      setFilteredListings(filtered);
    }
  }, [allListings, listingIds]);

  const validateYear = (value) => {
    const year = parseInt(value, 10);
    if (year <= currentYear) {
      setYearError("");
      handleChange({ target: { name: "year", value } });
    } else {
      setYearError(`Year must not be greater than ${currentYear}.`);
      handleChange({ target: { name: "year", value } });
    }
  };

  const getSingleCurrency = (country) => {
    return Array.isArray(country.currency)
      ? country.currency[0]
      : country.currency;
  };

  // Handle country change to update both currency and selected country code
  const handleCountryChange = (countryCode) => {
    const selectedCountry = countries[countryCode];
    if (!countryCode) {
      setSelectedCurrency("");
      setSelectedCountryCode("");
      setFormData((prevData) => ({
        ...prevData,
        currency: "", // Clear currency to show all listings
      }));
    } else if (selectedCountry) {
      const singleCurrency = getSingleCurrency(selectedCountry);
      setSelectedCurrency(singleCurrency);
      setSelectedCountryCode(countryCode);
      setFormData((prevData) => ({
        ...prevData,
        currency: singleCurrency,
      }));
    }
  };

  useEffect(() => {
    const countryCode = Object.keys(countries).find(
      (code) => getSingleCurrency(countries[code]) === formData.currency
    );
    if (countryCode) {
      setSelectedCountryCode(countryCode);
    }
  }, [formData.currency]);

  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  const incrementBed = () => {
    setBedValue((prevValue) => prevValue + 1);
  };

  const decrementBed = () => {
    setBedValue((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  const incrementBath = () => {
    setBathValue((prevValue) => prevValue + 1);
  };

  const decrementBath = () => {
    setBathValue((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  // Function to geocode address
  const geocodeAddress = async (address) => {
    const { streetNumber, streetName, city, state, postalCode, country } =
      address;

    const countryName = getCountryName(country);
    const addressString = [
      streetNumber,
      streetName,
      city,
      state,
      postalCode,
      countryName,
    ]
      .filter(Boolean)
      .join(", ");

    console.log("Geocoding address with Google Maps:", addressString);

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          addressString
        )}&key=${LOCATION_URL}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.results && data.results.length > 0) {
        return {
          latitude: data.results[0].geometry.location.lat,
          longitude: data.results[0].geometry.location.lng,
        };
      } else {
        console.warn("No geocoding results for:", addressString);
        return null;
      }
    } catch (error) {
      console.error("Geocoding error with Google Maps:", error.message);
      return null;
    }
  };

  // Update listings with coordinates
  useEffect(() => {
    const updateListingsWithCoordinates = async () => {
      const updatedListings = await Promise.all(
        filteredListings.map(async (listing) => {
          let { latitude, longitude } = listing.address;

          if (!latitude || !longitude) {
            const coords = await geocodeAddress(listing.address);
            if (coords) {
              latitude = coords.latitude;
              longitude = coords.longitude;
            } else {
              console.warn(
                `Skipping listing due to geocoding failure: ${listing.id}`
              );
              return null; // Skip this listing if geocoding fails
            }
          }

          return {
            ...listing,
            address: {
              ...listing.address,
              latitude,
              longitude,
            },
          };
        })
      );

      const validListings = updatedListings.filter(Boolean);
      setListingsWithCoords(validListings);
    };

    if (filteredListings.length > 0) {
      updateListingsWithCoordinates();
    } else {
      setListingsWithCoords([]);
    }
  }, [filteredListings]);

  // Update map center based on listings with valid coordinates
  useEffect(() => {
    const validListings = listingsWithCoords.filter(
      (listing) => listing.address.latitude && listing.address.longitude
    );

    if (validListings.length > 0) {
      const avgLat =
        validListings.reduce(
          (sum, listing) => sum + listing.address.latitude,
          0
        ) / validListings.length;
      const avgLng =
        validListings.reduce(
          (sum, listing) => sum + listing.address.longitude,
          0
        ) / validListings.length;
      setMapCenter([avgLat, avgLng]);
    } else {
      setMapCenter([51.505, -0.09]); // Fallback to a default location
    }
  }, [listingsWithCoords]);

  const filterListings = useCallback(() => {
    return allListings.filter((listing) => {
      const matchesSearchTerm =
        !formData.searchTerm ||
        (listing.title &&
          listing.title
            .toLowerCase()
            .includes(formData.searchTerm.toLowerCase()));

      const matchesLocation =
        !formData.location ||
        (listing.address.city &&
          listing.address.city
            .toLowerCase()
            .includes(formData.location.toLowerCase()));

      const matchesMinPrice =
        !formData.minPrice ||
        (listing.price && listing.price >= Number(formData.minPrice));

      const matchesMaxPrice =
        !formData.maxPrice ||
        (listing.price && listing.price <= Number(formData.maxPrice));

      const matchesCurrency =
        !formData.currency || listing.currency === formData.currency;

      const matchesPropertyType =
        !formData.propertyType ||
        listing.propertyType === formData.propertyType;

      const matchesPropertyCategory =
        !formData.propertyCategory ||
        listing.propertyCategory === formData.propertyCategory;

      const matchesStatus =
        !formData.status || listing.status === formData.status;

      const matchesYear =
        !formData.year ||
        (listing.buildingInfo.year &&
          listing.buildingInfo.year === formData.year);

      const matchesBedroom =
        !bedValue ||
        (listing.buildingInfo.bedroom &&
          listing.buildingInfo.bedroom === bedValue);

      const matchesBathroom =
        !bathValue ||
        (listing.buildingInfo.bathroom &&
          listing.buildingInfo.bathroom === bathValue);

      return (
        matchesSearchTerm &&
        matchesLocation &&
        matchesMinPrice &&
        matchesMaxPrice &&
        matchesCurrency &&
        matchesPropertyType &&
        matchesPropertyCategory &&
        matchesStatus &&
        matchesYear &&
        matchesBedroom &&
        matchesBathroom
      );
    });
  }, [allListings, formData, bedValue, bathValue]); // Only depend on allListings and formData

  const handleFilter = () => {
    const result = filterListings();
    setFilteredListings(result); // Update filtered listings when button is clicked
  };

  const openListingPreviewModal = (listingId) => {
    const baseUrl = window.location.origin;
    const preview = `${baseUrl}/listing/${listingId}?userId=${userId}`;
    window.location.href = preview; 
  };

  console.log(filteredListings);

  return (
    <>
      <div className="fixed-search-section">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-2 mb-3">
        {/* <img
            src={logo}
            alt="logo"
            className="img-fluid me-4 ms-3" // Use Bootstrap class for responsive image
            style={{ maxHeight: "25px" }} // Ensure max height is set for the image
          /> */}
          <div className="mb-1 w-100">
            <label>Search</label>
            <div className="input-icon">
              <span className="input-icon-addon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="10" cy="10" r="7" />
                  <line x1="21" y1="21" x2="15" y2="15" />
                </svg>
              </span>
              <input
                type="text"
                className="form-control form-control-rounded  w-100"
                placeholder="Search for listings"
                name="searchTerm"
                value={formData.searchTerm}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-1 w-100">
            <label>Price</label>
            <div className="d-flex align-items-center">
              <input
                type="number"
                name="minPrice"
                placeholder="Min Price"
                className="form-control round-left"
                value={formData.minPrice}
                onChange={handleChange}
              />
              <input
                type="number"
                name="maxPrice"
                placeholder="Max Price"
                className="form-control round-right"
                value={formData.maxPrice}
                onChange={handleChange}
              />
              {/* {selectedCurrency && (
                <div className="form-control border-end mt-2 w-25 bg-gray-500">
                  {selectedCurrency}
                </div>
              )}
              <select
                className="form-select text-secondary"
                name="country"
                value={selectedCountryCode}
                onChange={(e) => handleCountryChange(e.target.value)}
                style={styles.select}
              >
                <option value="">Currency</option>{" "}
               
                {Object.keys(countries).map((code) => (
                  <option key={code} value={code}>
                    {countries[code].name}
                  </option>
                ))}
              </select> */}
            </div>
          </div>
          <div className="mb-1 w-100">
            <label>Status</label>
            <select
              className="form-select rounded-5"
              name="status"
              // defaultValue={formData.status}
              onChange={handleChange}
            >
              <option value="" className="form-select">
                All
              </option>
              <option value="active" className="form-select">
                Active
              </option>
              <option value="cancelled" className="form-select">
                Cancelled
              </option>
              <option value="deleted" className="form-select">
                Deleted
              </option>
              <option value="expired" className="form-select">
                Expired
              </option>
              <option value="on hold" className="form-select">
                On Hold
              </option>
              <option value="pending" className="form-select">
                Pending
              </option>
              <option value="sold" className="form-select">
                Sold
              </option>
              <option value="terminated" className="form-select">
                Terminated
              </option>
              <option value="unknown" className="form-select">
                Unknown
              </option>
              <option value="wait approval" className="form-select">
                Wait Approval
              </option>
            </select>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-2">
          <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-1 w-100">
            <div className="mb-3 w-100">
              <label>Property Type</label>
              <select
                className="form-select rounded-5"
                name="propertyType"
                // defaultValue={formData.propertyType}
                onChange={handleChange}
              >
                <option value="" className="form-select">
                  All
                </option>
                <option value="commercial" className="form-select">
                  Commercial
                </option>
                <option value="condo" className="form-select">
                  Condo
                </option>
                <option value="freehold" className="form-select">
                  Freehold
                </option>
              </select>
            </div>
            <div className="mb-3 w-100">
              <label>Property Category</label>
              <select
                className="form-select rounded-5"
                name="propertyCategory"
                // defaultValue={formData.propertyCategory}
                onChange={handleChange}
              >
                <option value="" className="form-select">
                  All
                </option>
                <option value="sale" className="form-select">
                  Sale
                </option>
                <option value="lease" className="form-select">
                  Lease
                </option>
                <option value="rent" className="form-select">
                  Rent
                </option>
              </select>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row  justify-content-between gap-2 mb-1 w-100">
            <div className="mb-3 w-100">
              <label>Year Built</label>
              <input
                type="number"
                name="year"
                placeholder="Year Built"
                className="form-control form-control-rounded"
                // defaultValue={formData.buildingInfo.year}
                onChange={(e) => validateYear(e.target.value)}
              />
              {yearError && <small className="text-danger">{yearError}</small>}
            </div>

            <div className="mb-3 w-100">
              <div style={styles.container}>
                <label className="mb-2"> Bedrooms</label>
                <div style={styles.inputContainer}>
                  <button
                    style={styles.button}
                    onClick={decrementBed}
                    disabled={bedValue === 0}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={bedValue}
                    name="bedroom"
                    onChange={(e) =>
                      setBedValue(
                        Math.max(0, parseInt(e.target.value, 10) || 0)
                      )
                    }
                    style={styles.input}
                  />

                  <button style={styles.button} onClick={incrementBed}>
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="mb-3 w-100">
              <div style={styles.container}>
                <label className="mb-2"> Bathrooms</label>
                <div style={styles.inputContainer}>
                  <button
                    style={styles.button}
                    onClick={decrementBath}
                    disabled={bathValue === 0}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={bathValue}
                    name="bathroom"
                    onChange={(e) => {
                      const newValue = Math.max(
                        0,
                        parseInt(e.target.value, 10)
                      );
                      bathValue(isNaN(newValue) ? 0 : newValue);
                    }}
                    style={styles.input}
                  />
                  <button style={styles.button} onClick={incrementBath}>
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button onClick={handleFilter} className="btn btn-primary btn-pill ">
              Search
            </button>
          </div>
        </div>
      </div>
      <div className="map-preview-container">
        <div className="map-preview-content">
          {/* Map Container */}
          <div className="map-container">
            <MapContainer
              center={mapCenter}
              zoom={3}
              minZoom={2}
              style={{ height: "100%", width: "100%" }}
              className="map"
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {listingsWithCoords.map((listing) => {
                const { latitude, longitude } = listing.address;
                if (latitude && longitude) {
                  return (
                    <Marker
                      key={listing.id}
                      position={[parseFloat(latitude), parseFloat(longitude)]}
                      icon={customIcon}
                    >
                      <Popup>
                        <SingleCard
                          card={listing}
                          onClick={openListingPreviewModal}
                        />
                      </Popup>
                    </Marker>
                  );
                }
                return null;
              })}
            </MapContainer>
          </div>

          {/* Listings Cards */}
          <div className="listing-cards">
            {filteredListings.length > 0 && (
              <SelectedListingCard
                cards={filteredListings}
                onClick={openListingPreviewModal}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "20px",
    overflow: "hidden",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#f0f0f0",
    border: "none",
    borderRadius: "30px",
    padding: "6px 8px 6px 8px",
    cursor: "pointer",
    fontSize: "16px",
    width: "40px",
    textAlign: "center",
    margin: "3px",
  },
  input: {
    width: "40px",
    textAlign: "center",
    border: "none",
    outline: "none",
    fontSize: "13px",
    margin: "0 3px",
    backgroundColor: "white",
  },
  select: {
    width: "90px",
    border: "none",
    outline: "none",
    padding: "10px",
    borderRadius: "0 20px 20px 0",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
};
export default MapPreview;
