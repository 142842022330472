export const API_URL = process.env.REACT_APP_BASE_URL;

export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

export const LOCATION_URL = process.env.REACT_APP_LOCATION_URL;

console.log("API_URL", API_URL);
console.log("IMAGE_URL", IMAGE_URL);
console.log("LOCATION_URL", LOCATION_URL);
// Utility function to construct the download URL
export const getDownloadUrl = (fileName) => {
    const baseUrl = `${IMAGE_URL}`; // Define your base download URL
    console.log("baseUrl", baseUrl);
    return `${baseUrl}${fileName}`; // Return the full URL by appending the file name
  };
  