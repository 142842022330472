import React, { useEffect, useState } from "react";
import listingImage from "../../../assets/images/login-cover.png"; // Placeholder for default image
import "../../../assets/styles/smallListingCard.scss"; // Adding custom CSS file for styling
import { IconPlus } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { fetchMlsMedia } from "../../../modules/dashboard/actions";
import classNames from "classnames";

const MlsSmallListingCard = ({
  card,
  onClick,
  addCardIdToArray,
  selectedCards,
}) => {
  const dispatch = useDispatch();
  const [mlsLoading, setMLSLoading] = useState(false);
  const [MLSCardsImages, setMLSCardsImages] = useState({});
  const [imageUrl, setImageUrl] = useState(null);
  const [currency, setCurrency] = useState("$");
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("");

  const getCurrencyFromAddress = (address) => {
    if (address.includes("ON") || address.includes("Canada")) {
      return "$"; // Canadian Dollar
    } else if (address.includes("St. Catharines")) {
      return "$"; // Specific city in Canada
    } else if (address.includes("US") || address.includes("United States")) {
      return "$"; // US Dollar
    }
    // Add more rules as needed
    return "$"; // Default to USD
  };

  useEffect(() => {
    const now = new Date().toISOString();
    setTime(now.split('.')[0] + "Z"); // Remove milliseconds
  }, []); 

  const cleanAddress = (address) => {
    return address
      .replace(/#/g, "") // Remove #
      .replace(/-\s*/g, "") // Remove - and any trailing spaces
      .replace(/,\s*ON.*/g, "") // Remove ", ON" and everything after it
      .trim(); // Trim extra spaces
  };

  useEffect(() => {
    const fetchImage = async () => {
      if (card) {
        setLoading(true);
        try {
          const response = await dispatch(
            fetchMlsMedia(time,card.ListingKey)
          );
          console.log("Fetched images:", response);

          if (response && Array.isArray(response) && response.length > 0) {
            // Filter for valid media items
            const activeMedia = response.find(
              (item) =>
                item?.MediaURL &&
                item?.MediaStatus === "Active" &&
                item?.MediaType === "image/jpeg"
            );
            setImageUrl(activeMedia?.MediaURL);
          } else {
            setImageUrl();
          }
        } catch (error) {
          console.error("Failed to fetch image:", error);
          setImageUrl();
        } finally {
          setLoading(false);
        }
      } else {
        setImageUrl();
      }
    };

    fetchImage();

    if (card?.UnparsedAddress) {
      const detectedCurrency = getCurrencyFromAddress(card.UnparsedAddress);
      setCurrency(detectedCurrency);
    }
  }, [card, dispatch, time]);

  const isSelected = selectedCards.includes(card.ListingKey);

  return (
    // <div className="row gap-1">
    //   {cards.map((card, index) => (
    <div
      key={card.ListingKey}
      className={classNames(
        "custom-card shadow-sm col-12 col-sm-6 col-md-4 col-lg-3 mx-auto m-1",
        { "border border-primary border-2 shadow-lg": isSelected } // Add highlighted border if selected
      )}
    >
      {/* Image Section */}
      <div className="image-section">
        <img
          src={imageUrl || listingImage}
          alt={card.title}
          className="property-image"
        />
        {/* Overlay with button */}
        {!isSelected && (
          <div className="overlay">
            <button
              className="add-button btn btn-primary btn-pill"
              onClick={(e) => {
                e.stopPropagation(); // Prevent click from bubbling to the card
                addCardIdToArray(card.ListingKey);
              }}
            >
              Add
            </button>
          </div>
        )}
      </div>

      {/* Details Section */}
      <div className="details-section">
        <div className="price-section">
          <div className="price-text">
            {currency}{" "}
            {card.ListPrice && Number(card.ListPrice).toLocaleString()}
          </div>
        </div>
        {/* {card.currency} */}

        <div className="title-section card-title">
          {cleanAddress(card.UnparsedAddress)}
          {/* <div onClick={() => addCardIdToArray(card.ListingKey)}>
                <IconPlus size={20} className="mb-1 cursor-pointer" />
              </div> */}
        </div>
      </div>
    </div>
    //   ))}
    // </div>
  );
};

export default MlsSmallListingCard;
