import React, { useEffect, useState } from "react";
import { IconChevronDown, IconCirclePlus } from "@tabler/icons-react";
import {
  fetchListingById,
  fetchListings,
  fetchListingsByStatus,
  fetchAllListings,
  fetchIdxProperties,
  fetchVowProperties,
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import ListingForm from "../../../components/Listinngs/ListingForm";
import ListingCard from "../../../components/Listinngs/ListingCard";
// import ListingPreview from "../../../components/Listinngs/ListingPreview";
import UpdateListingForm from "../../../components/Listinngs/UpdateListingForm";
import ListingAnalytics from "../../../components/Listinngs/ListingAnalytics";
import { useLocation } from "react-router-dom";
import MLSListingPreview from "../../../components/Listinngs/MlsListingPreview";
import MLSSingleCard from "../../../components/Listinngs/ListingCodeGenerator/MLSSingleCard";
import Pagination from "../../../components/Pagination";

const Listings = () => {
  const dispatch = useDispatch();
  const { allListings, loading, MLS, currentPage, totalPages } = useSelector(
    (state) => state.dashboard
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false); // State for Export Modal
  const [exportedListings, setExportedListings] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [selectedListing, setSelectedListing] = useState(null);
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [selectedCards, setSelectedCards] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const profile = useSelector((state) => state.auth.profile);
  const [MLSCards, setMLSCards] = useState([]);
  const [mlsLoading, setMLSLoading] = useState(false);
  const [selectedMLS, setSelectedMLS] = useState(null);
  const [isMLSPreviewModalOpen, setMLSIsPreviewModalOpen] = useState(false);
  const [currentPageMLS, setCurrentPage] = useState(1);
  const listingsPerPage = 10;
  const [currentPageManual, setCurrentPageManual] = useState(1);
  const listingsPerPageManual = 10;
  // const [isListingDropdownOpen, setListingDropdownOpen] = useState(false);

  // const togglePropertyDropdown = () => {
  //   setListingDropdownOpen(!isListingDropdownOpen);
  // };

  const [activeListingType, setActiveListingType] = useState("MLS");

  const handleListingTypeFilter = (e) => {
    setActiveListingType(e.target.value);
  };

  // const handleListingTypeChange = (type) => {
  //   setActiveListingType(type); // Set the selected listing type filter
  //   setListingDropdownOpen(false); // Close the dropdown after selection
  // };

  // Get current page data

  const handleSelectCard = (id) => {
    setSelectedCards((prevSelectedCards) => {
      console.log("Selected Cards Before: ", prevSelectedCards);
      console.log("Clicked Card ID: ", id);

      if (prevSelectedCards.includes(id)) {
        const newSelectedCards = prevSelectedCards.filter(
          (cardId) => cardId !== id
        );
        console.log("Selected Cards After Deselect: ", newSelectedCards);
        return newSelectedCards;
      } else {
        const newSelectedCards = [...prevSelectedCards, id];
        console.log("Selected Cards After Select: ", newSelectedCards);
        return newSelectedCards;
      }
    });
  };

  const openExportModal = () => {
    const selectedListings = allListings.filter((listing) =>
      selectedCards.includes(listing.id)
    );
    setExportedListings(selectedListings);
    setIsExportModalOpen(true);
  };

  // const closeExportModal = () => {
  //   setIsExportModalOpen(false);
  // };

  const isSelected = (id) => selectedCards.includes(id);

  useEffect(() => {
    const fetchProperties = async () => {
      setMLSLoading(true); // Set loading to true before the API call
      try {
        let response;
        if (profile.userType === "brokerage") {
          response = await dispatch(
            fetchIdxProperties("ListOfficeKey", profile.mlsNumber)
          );
        } else if (profile.userType === "solo") {
          response = await dispatch(
            fetchVowProperties("ListAgentKey", profile.mlsNumber)
          );
        } else if (profile.mlsNumber) {
          response = await dispatch(
            fetchVowProperties("ListAgentKey", profile.mlsNumber)
          );
          console.log("Response: ", response);
          if (response.length === 0) {
            response = await dispatch(
              fetchIdxProperties("ListOfficeKey", profile.mlsNumber)
            );
          }
        }
        setMLSCards(response.value || response); // Use response.value if it exists, else use response directly
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      } finally {
        setMLSLoading(false); // Set loading to false after the API call completes
      }
    };

    fetchProperties();
  }, [dispatch, profile.userType, profile.mlsNumber]);

  useEffect(() => {
    dispatch(fetchAllListings(profile.id));
    if (activeTab === "All") {
      dispatch(fetchListings(currentPage, profile.id));
    }
    if (location.state?.activeTab === "Analytics") {
      setActiveTab("Analytics"); // Set the active tab to Analytics
    }

    // if(profile.userType === "brokerage") {
    //   dispatch(fetchIdxProperties("ListOfficeKey",profile.mlsNumber));
    // } else {
    //   dispatch(fetchIdxProperties("ListAgentKey",profile.mlsNumber));
    // }
  }, [
    dispatch,
    currentPage,
    activeTab,
    isModalOpen,
    location.state,
    profile.id,
    profile.mlsNumber,
    profile.userType,
    isPreviewModalOpen,
  ]);

  const sortedListings = allListings.slice().sort((a, b) => {
    return new Date(b.createdDate) - new Date(a.createdDate);
  });

  const filteredListings = sortedListings.filter((listing) => {
    const searchValue = searchTerm.toLowerCase();
    const price = listing.price.toString().toLowerCase();
    const address = listing.address.fullAddress
      ? listing.address.fullAddress.toLowerCase()
      : "";
    const title = listing.title ? listing.title.toLowerCase() : "";

    const matchesSearch =
      title.includes(searchValue) ||
      price.includes(searchValue) ||
      address.includes(searchValue);

    if (activeTab === "active") {
      return matchesSearch && listing.status === "active";
    } else if (activeTab === "sold") {
      return matchesSearch && listing.status === "sold";
    } else if (activeTab === "terminated") {
      return matchesSearch && listing.status === "terminated";
    } else if (activeTab === "on hold") {
      return matchesSearch && listing.status === "on hold";
    } else if (activeTab === "pending") {
      return matchesSearch && listing.status === "pending";
    } else if (activeTab === "deleted") {
      return matchesSearch && listing.status === "deleted";
    } else if (activeTab === "expired") {
      return matchesSearch && listing.status === "expired";
    } else if (activeTab === "cancelled") {
      return matchesSearch && listing.status === "cancelled";
    } else if (activeTab === "unknown") {
      return matchesSearch && listing.status === "unknown";
    } else if (activeTab === "wait approval") {
      return matchesSearch && listing.status === "wait approval";
    } else {
      return matchesSearch; // Show all leads
    }
  });

  const paginatedManualListings = filteredListings?.slice(
    (currentPageManual - 1) * listingsPerPageManual,
    currentPageManual * listingsPerPageManual
  );

  const handlePageChangeManual = (page) => {
    setCurrentPageManual(page);
  };

  const tabs = [
    "All",
    "active",
    "sold",
    "on hold",
    "pending",
    "deleted",
    "terminated",
    "expired",
    "cancelled",
    "unknown",
    "wait approval",
  ];

  const getListingByStatus = (status) => {
    setActiveTab(status);
    setCurrentPageManual(1);
    setCurrentPage(1);
    // if (status === "All") {
    //   dispatch(fetchListings(1, profile.id));
    //   return;
    // } else {
    //   dispatch(fetchListingsByStatus(1, status, profile.id));
    // }
  };

  const openListingModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openListingPreviewModal = async (listing) => {
    const user = profile;
    const data = {
      id: listing,
      userId: user.id,
    };
    const response = await dispatch(fetchListingById(data));
    setSelectedListing(response);
    setSelectedListingId(listing);
    setIsPreviewModalOpen(true);
    closeModal();
  };

  const closeListingPreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const handleMlsListingPreview = (listing) => {
    setSelectedMLS(listing);
    setMLSIsPreviewModalOpen(true);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      // setCurrentPage(newPage);  // Update the current page state
      // if (activeTab === "All") {
      //   dispatch(fetchListings(newPage)); // Fetch listings for the new page
      // }
      dispatch(fetchListingsByStatus(newPage, activeTab, profile.id)); // Fetch listings for the new page based on status
    }
  };

  const filteredMlsListings = MLSCards.filter((listing) => {
    const searchValue = searchTerm.toLowerCase();
    const price = listing.ListPrice.toString().toLowerCase();
    const address = listing.UnparsedAddress
      ? listing.UnparsedAddress.toLowerCase()
      : "";
    const title = listing.PublicRemarks
      ? listing.PublicRemarks.toLowerCase()
      : "";

    const matchesSearch =
      title.includes(searchValue) ||
      price.includes(searchValue) ||
      address.includes(searchValue);
    if (activeTab === "active") {
      return matchesSearch && listing.StandardStatus === "Active";
    } else if (activeTab === "sold") {
      return matchesSearch && listing.StandardStatus === "Sold";
    } else if (activeTab === "terminated") {
      return matchesSearch && listing.StandardStatus === "Terminated";
    } else if (activeTab === "on hold") {
      return matchesSearch && listing.StandardStatus === "On hold";
    } else if (activeTab === "pending") {
      return matchesSearch && listing.StandardStatus === "Pending";
    } else if (activeTab === "deleted") {
      return matchesSearch && listing.StandardStatus === "Deleted";
    } else if (activeTab === "expired") {
      return matchesSearch && listing.StandardStatus === "Expired";
    } else if (activeTab === "cancelled") {
      return matchesSearch && listing.StandardStatus === "Cancelled";
    } else if (activeTab === "unknown") {
      return matchesSearch && listing.StandardStatus === "Unknown";
    } else if (activeTab === "wait approval") {
      return matchesSearch && listing.StandardStatus === "Wait approval";
    } else {
      return matchesSearch; // Show all leads
    }
  });

  const paginatedListings = filteredMlsListings?.slice(
    (currentPageMLS - 1) * listingsPerPage,
    currentPageMLS * listingsPerPage
  );

  const handlePageChangeMLS = (page) => {
    setCurrentPage(page);
  };

  const totalListings = filteredMlsListings?.length || 0;
  const totalPagesMLS = Math.ceil(totalListings / listingsPerPage);
  const totalListingsManual = filteredListings?.length || 0;
  const totalPagesManual = Math.ceil(
    totalListingsManual / listingsPerPageManual
  );

  return (
    <div className="">
      <ListingForm isOpen={isModalOpen} closeModal={closeModal} />
      <UpdateListingForm
        isOpen={isPreviewModalOpen}
        closeModal={closeListingPreviewModal}
        selectedListing={selectedListing}
        page={currentPage}
        setSelectedListingId={selectedListingId}
      />
      <MLSListingPreview
        isOpen={isMLSPreviewModalOpen}
        closeModal={() => setMLSIsPreviewModalOpen(false)}
        listings={MLS}
        selectedListing={selectedMLS}
      />
      <div className="page-wrapper">
        <div className="page-header m-3">
          <div className="row align-items-center gap-2">
            <div className="col-auto bg-white rounded-4 border">
              <div className="border-0">
                {activeListingType !== "MLS" &&
                  tabs.map((tab) => (
                    <button
                      key={tab}
                      className={`my-1 text-capitalize ${
                        activeTab === tab
                          ? "btn btn-outline-primary btn-pill bg-tabs"
                          : "bg-white border-0 mx-1"
                      }`}
                      onClick={() => getListingByStatus(tab)}
                    >
                      {tab}
                    </button>
                  ))}
                {activeListingType === "MLS" &&
                  tabs.map((tab) => (
                    <button
                      key={tab}
                      className={`my-1 text-capitalize ${
                        activeTab === tab
                          ? "btn btn-outline-primary btn-pill bg-tabs"
                          : "bg-white border-0 mx-1"
                      }`}
                      onClick={() => getListingByStatus(tab)}
                    >
                      {tab}
                    </button>
                  ))}

                <button
                  className={`my-1 text-capitalize ${
                    activeTab === "Analytics"
                      ? "btn btn-outline-primary btn-pill bg-blue-lt"
                      : "bg-white border-0 mx-1 border-start "
                  }`}
                  onClick={() => setActiveTab("Analytics")}
                >
                  Analytics
                </button>
              </div>
            </div>
            <div className="col-auto ms-auto">
              <div className="btn-list">
                <button
                  className="btn btn-pill btn-primary"
                  onClick={openListingModal}
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="ms-2">Create a New Listing</div>
                    <IconCirclePlus size={18} />
                  </div>
                </button>
                {selectedCards.length > 0 && (
                  <button
                    className="btn btn-outline-primary btn-pill"
                    onClick={openExportModal}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <div className="ms-2">Share short code</div>
                      <IconChevronDown size={18} />
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="page-header m-3">
          <div className="row align-items-center gap-2">
            <div className="col-auto rounded-4">
              <div className="btn-list">
                <select
                  className="form-select rounded-5 me-2"
                  value={activeListingType}
                  onChange={handleListingTypeFilter}
                  // disabled={!isDropdownEnabled}
                >
                  <option value="MLS">MLS</option>
                  <option value="Manual">Manual</option>
                </select>
              </div>
            </div>
            <div className="col-auto ms-auto">
              <div className="input-icon mb-3">
                <span className="input-icon-addon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control form-control-rounded border-0 bg-gray-600"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mx-3">
          {activeTab === "Analytics" ? (
            <ListingAnalytics />
          ) : (
            <>
              {activeListingType === "MLS" && mlsLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "50vh" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              ) : activeListingType === "MLS" &&
                paginatedListings &&
                paginatedListings.length > 0 ? (
                <>
                  <h1>MLS Listings</h1>
                  <div className="cards-container">
                    {paginatedListings.map((listing) => (
                      <MLSSingleCard
                        key={listing.id} // Ensure unique key
                        card={listing}
                        onClick={handleMlsListingPreview}
                      />
                    ))}
                  </div>

                  <Pagination
                    currentPage={currentPageMLS}
                    onPageChange={handlePageChangeMLS}
                    totalPages={totalPagesMLS}
                  />
                </>
              ) : (
                activeListingType === "MLS" && (
                  <div className="text-center p-4">
                    <p className="">No Listing available</p>
                  </div>
                )
              )}

              {activeListingType === "Manual" && loading ? ( // Add Manual listing loading state
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "50vh" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              ) : activeListingType === "Manual" &&
                paginatedManualListings &&
                paginatedManualListings.length > 0 ? (
                <>
                  <h1>Manual Listings</h1>
                  <ListingCard
                    cards={paginatedManualListings}
                    activeTab={activeTab}
                    onClick={openListingPreviewModal} // Assuming this opens a modal with listing details
                    handleSelectCard={handleSelectCard} // Assuming this selects listings for bulk actions
                    isSelected={isSelected}
                  />
                  <Pagination
                    currentPage={currentPageManual}
                    onPageChange={handlePageChangeManual}
                    totalPages={totalPagesManual}
                  />
                </>
              ) : (
                activeListingType === "Manual" && (
                  <div className="text-center p-4">
                    <p className="">No Listing available</p>
                  </div> // Display message if no Manual listings
                )
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Listings;
