import React, { useState, useRef, useEffect } from "react";

import {
  IconLayoutDashboard,
  IconBell,
  IconUser,
  IconChevronDown,
  IconListCheck,
  IconMenu2,
  IconAddressBook,
  IconNews,
  IconUserSquare,
  IconUserCircle,
  IconSpeakerphone,
} from "@tabler/icons-react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../modules/auth/actions";

const DashboardHeader = ({ isSidebarOpen, toggleSidebar }) => {
  const location = useLocation().pathname;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="page-header ms-3 mt-3 me-3 ms-md-3">
      <div className="row align-items-center">
        <div className="col">
          <div className="" onClick={toggleSidebar}>
            {isSidebarOpen ? (
              <IconMenu2 className="d-md-none" />
            ) : (
              <IconMenu2 className="d-md-none" />
            )}
          </div>
          <div className="my-1">
            Welcome,{" "}
            <strong>
              {profile.name
                ? profile.name
                : profile.displayName
                ? profile.displayName
                : profile.email}
            </strong>
          </div>
          {location === "/dashboard" && (
            <div className="d-flex gap-2">
              <IconLayoutDashboard />
              <h1 className="page-title">Dashboard</h1>
            </div>
          )}
          {location === "/dashboard/listings" && (
            <div className="d-flex gap-2">
              <IconListCheck />
              <h1 className="page-title">Listings</h1>
            </div>
          )}
          {location === "/dashboard/leads" && (
            <div className="d-flex gap-2">
              <IconAddressBook />
              <h1 className="page-title">Leads</h1>
            </div>
          )}
          {location === "/dashboard/news" && (
            <div className="d-flex gap-2">
              <IconNews />
              <h1 className="page-title">News</h1>
            </div>
          )}
          {location === "/dashboard/profile" && (
            <div className="d-flex gap-2">
              <IconUser />
              <h1 className="page-title">Billing Account</h1>
            </div>
          )}
          {location === "/dashboard/agent-setting" && (
            <div className="d-flex gap-2">
              <IconUserCircle />
              <h1 className="page-title">Brokerage/ Agent Setting</h1>
            </div>
          )}
          {location === "/dashboard/subscribers" && (
            <div className="d-flex gap-2">
              <IconUserSquare />
              <h1 className="page-title">Subscribers</h1>
            </div>
          )}
          {location === "/dashboard/marketing" && (
            <div className="d-flex gap-2">
              <IconSpeakerphone />
              <h1 className="page-title">Marketing</h1>
            </div>
          )}
        </div>
        <div className="col-auto ms-auto">
          <div className="d-flex gap-2 align-items-center">
            <button
              className={`avatar border-0 bg-white rounded-3 ${
                isSidebarOpen ? "d-none d-md-block" : ""
              }`}
            >
              {profile.isSkipped ? (
                <>
                  <IconBell size={19} />{" "}
                  <span className="badge bg-danger"></span>{" "}
                </>
              ) : (
                <IconBell size={19} />
              )}
            </button>

            <div
              className={`d-flex bg-white border rounded-pill align-items-center px-2 py-1 ${
                isSidebarOpen ? "d-none d-md-flex" : ""
              }`}
              ref={dropdownRef}
            >
              <div
                className="d-flex align-items-center"
                onClick={toggleDropdown}
                style={{ cursor: "pointer" }}
              >
                <div className="bg-primary rounded-circle p-2 d-flex align-items-center justify-content-center">
                  <IconUser size={18} className="text-white" />
                </div>
                <div className="d-flex flex-column mx-2">
                  <p className="mb-0">
                    {profile.displayName
                      ? profile.displayName
                      : profile.displayName
                      ? profile.displayName
                      : profile.email}
                  </p>
                  <p className="d-none d-sm-block text-muted mb-0">
                    {profile.role === "superAdmin" ? "Super Admin":profile.role === "agent"? "Agent":profile.role}
                  </p>
                </div>
                <IconChevronDown size={20} className="ml-2" />
              </div>
              {dropdownVisible && (
                <div
                  className="dropdown-menu show position-absolute end-0 bg-white shadow rounded-3"
                  style={{ marginTop: "11rem" }}
                >
                  <Link
                    to="/dashboard/profile"
                    className="dropdown-item border-bottom"
                    onClick={toggleDropdown}
                  >
                   Billing Account
                  </Link>
                  {profile.role !== "superAdmin" &&( profile.isSkipped !== undefined && profile.isSkipped !== true) && (
                  <Link
                    to="/dashboard/agent-setting"
                    className="dropdown-item border-bottom"
                    onClick={toggleDropdown}
                  >
                    Brokerage/ Agent Setting
                  </Link>)}
                  {/* <Link
                    to="/dashboard/setting"
                    className="dropdown-item border-bottom"
                    onClick={toggleDropdown}
                  >
                    Setting
                  </Link> */}
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
