import React from 'react';
import classNames from 'classnames';
import listingImage from "../../../assets/images/login-cover.png"; // Placeholder for default image
import '../../../assets/styles/smallListingCard.scss'; // Adding custom CSS file for styling
import { IconPlus } from '@tabler/icons-react';

const SmallListingCard = ({ cards, onClick,addCardIdToArray,selectedCards }) => {
  return (
    <div className="row gap-1">
      {cards.map((card, index) => {
        // Determine if the card is selected
        const isSelected = selectedCards.includes(card.id);

        return (
          <div
            key={index}
            className={classNames(
              "custom-card shadow-sm col-12 col-sm-6 col-md-4 col-lg-3 mx-auto m-1",
              { "border border-primary border-2 shadow-lg": isSelected } // Add highlighted border if selected
            )}
            onClick={() => onClick && onClick(card)} // Event handling for clicking the card
          >
            {/* Image Section */}
            <div className="image-section">
              <img
                src={card.images && card.images[0]?.imageUrl ? card.images[0].imageUrl : listingImage}
                alt={card.title}
                className="property-image"
              />
              {/* Overlay with button */}
              {!isSelected && (
                <div className="overlay">
                  <button
                    className="add-button btn btn-primary btn-pill"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent click from bubbling to the card
                      addCardIdToArray(card.id);
                    }}
                  >
                    Add
                  </button>
                </div>
              )}
            </div>


            {/* Details Section */}
            <div className="details-section">
              <div className="price-section">
                <div className="price-text">
                  {card.currency} {card.price.toLocaleString()}
                </div>
              </div>

              <div className="title-section">
                <span className='text-black'>{card.title}</span>
                {/* <IconPlus
                  size={20}
                  className="mb-1 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click from bubbling to the card
                    addCardIdToArray(card.id);
                  }}
                /> */}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SmallListingCard;
