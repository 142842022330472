import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { userLogout, getProfile } from "../modules/auth/actions";
import { IconX } from "@tabler/icons-react";
import DashboardHeader from "./DashboardHeader";

const DashboardRootLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const profile = useSelector((state) => state.auth.profile);

  useEffect(() => {
    if (!token) {
      dispatch(userLogout());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(getProfile());
    }
  }, [dispatch, token]);

  if (!token) {
    return <Navigate to="/" />;
  }

  if (!profile) {
    // Implement loading component here
    return null;
  }

  return (
    <>
      <div className="content-blur">
        <div className="position-relative d-flex h-100">
          <div
            className={`d-md-block sidebar-fixed bg-white ${
              isSidebarOpen ? "mobile-side-bar" : "d-none"
            }`}
          >
            {/* <Link to="/dashboard" className="d-flex justify-content-center">
          
            <img src={logo} height="20" alt="logo" className="my-4" />
          </Link> */}
            <Sidebar isSidebarOpen={isSidebarOpen} />
          </div>
          <div
            className="position-fixed top-0 start-0 ms-2 mt-4 mb-lg-1"
            onClick={toggleSidebar}
          >
            {isSidebarOpen ? <IconX className="d-block" /> : ""}
          </div>

          <main
            className={`flex-grow-1 main-content ${
              isSidebarOpen ? "mobile-main-content" : "main-content"
            }`}
            style={{ overflowY: "auto", maxHeight: "100vh" }}
          >
            <DashboardHeader
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />
            <div className="">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default DashboardRootLayout;
