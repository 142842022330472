import React from "react";
import logo from "../../../assets/images/realtorr logo.png";
import { IconCheck } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../../../components/AuthLayout";

const SelectPackage = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("../signup-details");
  };

  return (
    <AuthLayout>
      <div className="container container-tight my-5 px-5">
      <div className="text-center mb-4">
          <img src={logo} style={{ maxHeight: "25px" }} alt="" />
        </div>
        <div className="text-center text-secondary mt-3">
          Do you have an account? <Link to="/">Sign in</Link>
        </div>
        <div className="steps steps-counter steps-primary mb-5 mt-5">
          <span className="step-item active">
            Select Package
          </span>
          <span className="step-item">
            Signup with details
          </span>
          <span href="#" className="step-item">
            Payment
          </span>
        </div>
        <form
          action="./"
          method="get"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="card rounded-3">
            <div className="card-body">
              <p className="text-secondary">Only</p>
              <h1 className="text-primary">
                <strong><sup>$</sup>59/month</strong>
              </h1>
              <h5 className="">+ taxes and board fees for certain boards*</h5>

              <p>
                Whether you need a single agent website, listings for WordPress
                or a broker website, myRealpage has a solution to help your
                business
              </p>

              <div className="text-primary">
                <p>
                  <IconCheck stroke={2} /> No Contracts
                </p>
                <p>
                  <IconCheck stroke={2} /> Cancel Anytime
                </p>
                <p>
                  <IconCheck stroke={2} /> 16 hours / 7 days Support
                </p>
              </div>

              <div className="btn-list">
                <button
                  type="submit"
                  className="btn btn-primary btn-md btn-pill w-100 mb-2"
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </form>
        {/* {error && <div className="text-danger mt-3 text-center">{error}</div>} */}
        {/* <div className="text-center text-secondary mt-3">
            Don't you have an account? <a href="./signup-details" tabIndex="-1">Create an account</a>
          </div> */}
      </div>
    </AuthLayout>
  );
};

export default SelectPackage;
