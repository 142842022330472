import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IconPhone,
  IconMail,
  IconPencil,
  IconUserCircle,
  IconLocationPin,
  IconMapPin,
} from "@tabler/icons-react";
import EditAgentProfile from "../../../components/Profile/EditAgentProfile";

const AgentSetting = () => {
  const dispatch = useDispatch();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const openUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };
  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const profile = useSelector((state) => state.auth.profile);
  return (
    <div className="container">
      <EditAgentProfile
        isOpen={isUpdateModalOpen}
        closeModel={closeUpdateModal}
        profile={profile}
      />
      {/* Card to represent the agent/broker information */}
      <div className="card shadow-sm p-4 agent-card rounded-4">
        <h2 className="text-primary">Brokerage/ Agent Information</h2>
        <div className="d-flex align-items-center mb-4 justify-content-between gap-3">
          {/* Agent Avatar */}
          <div className="d-flex align-items-center gap-3">
            <span
              className="avatar avatar-xl bg-gray rounded-circle"
              style={{
                backgroundImage: `url(${
                  profile.profileUrl || "https://via.placeholder.com/150"
                })`,
                position: "relative",
                width: "120px",
                height: "120px",
                backgroundSize: "cover",
                borderRadius: "50%",
              }}
            />
            <div className="ml-3">
              {/* Agent Name */}
              <h3 className="mb-0">
                {profile.displayName ? profile.displayName : profile.name}
              </h3>
            </div>
          </div>
          {/* Edit Button */}
          <button
            className="btn btn-primary btn-pill ml-auto d-flex align-items-center"
            onClick={() => openUpdateModal()}
          >
            <IconPencil className="mr-2" size={18} /> Edit
          </button>
        </div>

        {/* Basic Information */}
        <div className="mb-4">
          <h4 className="text-primary">Basic Information</h4>
          <div className="d-flex align-items-center mt-2 mb-3 justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <div>
                <IconUserCircle size={20} />
              </div>
              {profile.userType === "brokerage"
                ? profile.displayName
                : profile.displayName}
            </div>
          </div>
          <div className="d-flex align-items-center mt-2 mb-3 justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <div>
                <IconPhone size={20} />
              </div>
              {profile.userType === "brokerage"
                ? profile.businessPhone
                : profile.businessPhone}
            </div>
          </div>
          <div className="d-flex align-items-center mb-3 justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <div>
                <IconMail size={20} />
              </div>
              {profile.userType === "brokerage"
                ? profile.businessEmail
                : profile.businessEmail}
            </div>
          </div>
          <div className="d-flex align-items-center mt-2 mb-3 justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <div className="d-flex align-items-center">MLS</div>
              {profile.mlsNumber ? profile.mlsNumber : "No MLS number"}
            </div>
          </div>
          <div className="d-flex align-items-center mb-3 justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <div>
                <IconMapPin size={20} />
              </div>
              {profile.userType === "brokerage"
                ? profile.businessAddress
                : profile.businessAddress}
            </div>
          </div>
        </div>

        {/* Social Links */}
        <div className="">
          <h4 className="text-primary">Social Links</h4>
          {profile.links && profile.links.length > 0 ? (
            profile.links.map((link, index) => (
              <div key={index} className="mt-2">
                <input
                  type="text"
                  placeholder={`${link}`}
                  className="form-control form-control-rounded mb-2"
                  readOnly
                />
              </div>
            ))
          ) : (
            <div className="mt-2">
              <p className="text-center">No social links added yet</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentSetting;
