import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconCake,
  IconCalendarMonth,
  IconEdit,
  IconLink,
  IconMail,
  IconMapPin,
  IconPhone,
  IconUser,
  IconWorld,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import UpdateAgent from "./UpdateAgent";

const ViewAgent = ({ isOpen, closeModel, agent }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen]);

  const getSocialMediaIcon = (link) => {
    const lowerLink = link.toLowerCase();
    if (lowerLink.includes("facebook.com"))
      return <IconBrandFacebook size={20} />;
    if (lowerLink.includes("twitter.com"))
      return <IconBrandTwitter size={20} />;
    if (lowerLink.includes("linkedin.com"))
      return <IconBrandLinkedin size={20} />;
    if (lowerLink.includes("instagram.com"))
      return <IconBrandInstagram size={20} />;
    return <IconLink size={20} />; // Generic icon for other links
  };

  const ensureAbsoluteURL = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };
  return (
    <div>
      {isModalOpen && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="">
                <button
                  type="button"
                  className="btn-close mt-2 me-4"
                  onClick={closeModel}
                  aria-label="Close"
                ></button>
              </div>
              {/* {error && <div className="text-danger mt-3 text-center">{error}</div>} */}
              <div className="modal-body text-black">
                <div className="profile-card">
                  {!isEditing && (
                    <>
                      <div className="card-header mb-4 mt-4">
                        <div className="d-flex align-items-center">
                          <div className="avatar avatar-lg bg-gray-500 rounded-4 me-3">
                            <span className="fs-3 fw-bold text-dark">
                              {agent.displayName && agent.displayName.charAt(0)}
                            </span>
                          </div>
                          <div className="d-flex flex-column gap-1">
                            <strong>
                              {agent.displayName && agent.displayName}{" "}
                            </strong>
                          </div>
                        </div>
                        <div className="card-actions ">
                          {/* <button
                            className="btn btn-primary btn-pill"
                            onClick={() => setIsEditing(true)}
                          >
                            <div className="d-flex align-items-center gap-1">
                              <div className="ms-1">Edit</div>
                              <IconEdit size={16} />
                            </div>
                          </button> */}
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-3">
                        <p>Basic Information</p>
                        <div className="d-flex gap-2">
                          <IconUser size={16} />
                          <strong>
                            <div>
                            {agent.displayName ? agent.displayName : "N/A"}
                            </div>
                          </strong>
                        </div>
                        <div className="d-flex gap-2">
                          <IconPhone size={16} />
                          <strong>
                            <div>{agent.businessPhone ? agent.businessPhone : "N/A"}</div>
                          </strong>
                        </div>
                        <div className="d-flex gap-2">
                          <IconMail size={16} />
                          <strong>
                            <div>{agent.email && agent.email}</div>
                          </strong>
                        </div>
                        <div className="d-flex gap-2">
                          <IconMapPin size={16} />
                          <strong>
                            <div>{agent.businessAddress ? agent.businessAddress : "N/A"}</div>
                          </strong>
                        </div>
                        <div className="d-flex gap-2">
                          
                          
                          <div>MLS</div>
                          <strong>
                            <div>{agent.mlsNumber ? agent.mlsNumber : "N/A"}</div>
                          </strong>
                        </div>
                        <div className="d-flex gap-2">
                          <IconCalendarMonth size={16} />
                          <strong>
                            <div>Joined: {new Date(agent.createdOn).toLocaleDateString()}</div>
                          </strong>
                        </div>
                        {/* <div className="d-flex gap-2">
                          <IconCake size={16} />
                          <strong>
                            <p>09.06.1997</p>
                          </strong>
                        </div> */}
                        {/* <div className="d-flex gap-2">
                          <IconWorld size={16} />
                          <strong>
                            <p>www.chirag.com</p>
                          </strong>
                        </div> */}
                        {agent.links && agent.links.length > 0 && (
          <div>
            <h4 className="mb-1">Socials</h4>
            <div className="d-flex flex-wrap gap-2">
              {agent.links.map((link, index) => (
                <div className="border rounded-circle p-2">
                    <a
                  key={index}
                  href={ensureAbsoluteURL(link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-muted"
                  data-bs-toggle="tooltip"
                  title={link}
                >
                  {getSocialMediaIcon(link)}
                </a>
                    </div>
              ))}
            </div>
          </div>
        )}
                        
                      </div>
                    </>
                  )}

                  <div className="mt-3">
                    {isEditing && (
                      <UpdateAgent
                        agent={agent}
                        closeModel={closeModel}
                        setIsEditing={setIsEditing}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAgent;
