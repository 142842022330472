import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  fetchAllListings,
  fetchIdxProperties,
  fetchVowProperties,
} from "../../../modules/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import SelectedListingCard from "../SelectedListingCard";
import ListingPreview from "../ListingPreview";
import FilterSection from "./filters";
import Loader from "../../Loader";
import TopFilters from "./topFilters";
import MLSCard from "../MLSCard";
import MLSFilterSection from "./MLSFilters";
import MLSSingleCard from "./MLSSingleCard";
import MLSTopFilters from "./MLSTopFilters";
import MLSListingPreview from "../MlsListingPreview";
import { IconAdjustmentsHorizontal } from "@tabler/icons-react";

// Deep equality check to avoid unnecessary updates
const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

const MLSGeneratedListing = () => {
  const { ids } = useParams(); // Get the `:ids` parameter from the URL
  const idArray = ids ? ids.split(",") : []; // Split the IDs into an array
  const dispatch = useDispatch();
  const { allListings, currentPage } = useSelector((state) => state.dashboard);
  const [exportedListings, setExportedListings] = useState([]); // This is the filtered listings state
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [loading, setLoading] = useState(true); // Loading
  const [mls, setMls] = useState("");
  const [userType, setUserType] = useState("");

  const [activeTab, setActiveTab] = useState("all"); // Tabs filter state
  const [userId, setUserId] = useState("");
  const [filterOption, setFilterOptions] = useState("");
  const [title, setTitle] = useState("");
  const [mlsLoading, setMLSLoading] = useState(false);
  const [mlsCards, setMLSCards] = useState([]);
  const [isMLSPreviewModalOpen, setMLSIsPreviewModalOpen] = useState(false);
  const [selectedMLS, setSelectedMLS] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  useEffect(() => {
    // Get the current URL from the browser
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get("userId");
    const filterOptions = urlParams.get("filter");
    const titleParam = urlParams.get("title");
    const mlsParam = urlParams.get("mls");
    const userTypeParam = urlParams.get("type");
    setUserId(userIdParam);
    setFilterOptions(filterOptions);
    setTitle(titleParam);
    setMls(mlsParam);
    setUserType(userTypeParam);
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      // Set loading to true before the API call
      setMLSLoading(true);
      try {
        let response;
        if (userType === "brokerage") {
          response = await dispatch(fetchIdxProperties("ListOfficeKey", mls));
        } else if (userType === "solo") {
          response = await dispatch(fetchVowProperties("ListAgentKey", mls));
        } else if (mls) {
          response = await dispatch(fetchVowProperties("ListAgentKey", mls));
          console.log("Response: ", response);
          if (response.length === 0) {
            response = await dispatch(fetchIdxProperties("ListOfficeKey", mls));
          }
        }
        console.log("mls", response);
        setMLSCards(response.value || response); // Use response.value if it exists, else use response directly
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      } finally {
        // Set loading to false after the API call completes
        setMLSLoading(false);
      }
    };

    fetchProperties();
  }, [dispatch, userType, mls]);

  // Filters State
  const [propertyType, setPropertyType] = useState([]);
  const [propertyCategory, setPropertyCategory] = useState([]);
  const [status, setStatus] = useState([]);
  const [priceRange, setPriceRange] = useState([1000, 2000000]);
  const currentYear = new Date().getFullYear();
  const [yearBuiltRange, setYearBuiltRange] = useState([1900, currentYear]);
  const [areaRange, setAreaRange] = useState([500, 20000]);
  const [bedrooms, setBedrooms] = useState(""); // New state for bedrooms
  const [bathrooms, setBathrooms] = useState("");

  // Fetch all listings on component mount
  useEffect(() => {
    dispatch(fetchAllListings(userId)).then(() => setLoading(false)); // Set loading to false after fetching
  }, [dispatch, userId]);

  // Memoize the filtered listings to avoid unnecessary recalculations
  const filteredListings = useMemo(() => {
    // if (allListings.length === 0) return [];

    // Step 1: Filter listings by IDs from URL params
    // let filteredByIds = allListings.filter((listing) =>
    //   idArray.includes(listing.id.toString())
    // );
    let filteredByMLSIds = mlsCards.filter((listing) =>
      idArray.includes(listing.ListingKey.toString())
    );

    // Step 2: Apply tab filtering logic (activeTab)
    if (activeTab !== "all") {
      filteredByMLSIds = filteredByMLSIds.filter((listing) => {
        if (activeTab === "commercial") {
          return listing.PropertyType === "Commercial";
        } else if (activeTab === "condo") {
          return listing.PropertyType === "Residential Condo & Other";
        } else if (activeTab === "freehold") {
          return listing.PropertyType === "Residential Freehold";
        }
        return true; // Return all for "all" tab
      });
    }

    // Step 3: Apply further filtering logic based on selected filters
    return filteredByMLSIds.filter((listing) => {
      const matchesPropertyType =
        propertyType.length === 0 ||
        propertyType.includes(listing.PropertyType);
      const matchesPropertyCategory =
        propertyCategory.length === 0 ||
        propertyCategory.includes(listing.TransactionType);
      const matchesStatus =
        status.length === 0 || status.includes(listing.MlsStatus);
      const matchesBedrooms =
        !bedrooms || listing.BedroomsTotal === parseInt(bedrooms);
      const matchesBathrooms =
        !bathrooms || listing.BathroomsTotalInteger === parseInt(bathrooms);
      const matchesPriceRange =
        (priceRange[0] === 1000 && priceRange[1] === 2000000) ||
        (parseFloat(listing.ListPrice) >= priceRange[0] &&
          parseFloat(listing.ListPrice) <= priceRange[1]);
      const matchesYearBuilt =
        (yearBuiltRange[0] === 1900 && yearBuiltRange[1] === currentYear) ||
        (listing.TaxYear >= yearBuiltRange[0] &&
          listing.TaxYear <= yearBuiltRange[1]);
      const matchesArea =
        (areaRange[0] === 500 && areaRange[1] === 20000) ||
        (parseFloat(listing.BuildingAreaTotal) >= areaRange[0] &&
          parseFloat(listing.BuildingAreaTotal) <= areaRange[1]);

      return (
        matchesPropertyType &&
        matchesPropertyCategory &&
        matchesStatus &&
        matchesBedrooms &&
        matchesBathrooms &&
        matchesPriceRange &&
        matchesYearBuilt &&
        matchesArea
      );
    });
    // return filteredByMLSIds;
  }, [
    mlsCards,
    idArray,
    activeTab,
    propertyType,
    propertyCategory,
    status,
    bedrooms,
    bathrooms,
    priceRange,
    yearBuiltRange,
    areaRange,
    currentYear,
  ]);

  // Only update exportedListings when filteredListings changes
  useEffect(() => {
    if (!deepEqual(exportedListings, filteredListings)) {
      setExportedListings(filteredListings);
    }
  }, [filteredListings]);

  const openListingPreviewModal = (listing) => {
    // const baseUrl = window.location.origin;
    // const preview = `${baseUrl}/listingMLS/${listing}?userId=${userId}&mls=${mls}&type=${userType}`;
    // window.location.href = preview;
    setSelectedMLS(listing);
    setMLSIsPreviewModalOpen(true);
  };

  const closeListingPreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  // const handlePreview = (listingId) => {
  //   const baseUrl = window.location.origin;
  //   const preview = `${baseUrl}/listing/${listingId}?userId=${userId}`;
  //   window.open(preview, "_blank"); // Opens the link in a new tab
  // };

  console.log(exportedListings);
  console.log(mlsCards);
  console.log(selectedMLS);
  useEffect(() => {
    if (isMLSPreviewModalOpen) {
      document.body.classList.add("modal-open");
      // document.getElementById("blur-background").classList.add("blurred");
    } else {
      document.body.classList.remove("modal-open");
      // document.getElementById("blur-background").classList.remove("blurred");
    }
  }, [isMLSPreviewModalOpen]);

  return (
    <div>
      <MLSListingPreview
        isOpen={isMLSPreviewModalOpen}
        closeModal={() => setMLSIsPreviewModalOpen(false)}
        listings={mlsCards}
        selectedListing={selectedMLS}
        userId={userId}
      />
      <div className="container-xl bg-white">
        <h1 className="text-center text-primary">{title && title}</h1>
        <div className="d-flex align-items-center justify-content-center gap-1 mt-3">
          <div className="d-flex flex-wrap gap-2 border shadow-md pe-2 rounded-4 mb-4">
            <div
              className={`tab-item ${activeTab === "all" ? "active" : ""}`}
              onClick={() => setActiveTab("all")}
            >
              All Properties
            </div>
            <div
              className={`tab-item ${
                activeTab === "commercial" ? "active" : ""
              }`}
              onClick={() => setActiveTab("commercial")}
            >
              Commercial
            </div>
            <div
              className={`tab-item ${activeTab === "condo" ? "active" : ""}`}
              onClick={() => setActiveTab("condo")}
            >
              Condo
            </div>
            <div
              className={`tab-item ${activeTab === "freehold" ? "active" : ""}`}
              onClick={() => setActiveTab("freehold")}
            >
              Freehold
            </div>
          </div>
        </div>

        {filterOption === "Top" && (
          <div className="filter-section-container-top mb-4">
            <MLSTopFilters
              propertyType={propertyType}
              setPropertyType={setPropertyType}
              propertyCategory={propertyCategory}
              setPropertyCategory={setPropertyCategory}
              status={status}
              setStatus={setStatus}
              priceRange={priceRange}
              setPriceRange={setPriceRange}
              yearBuiltRange={yearBuiltRange}
              setYearBuiltRange={setYearBuiltRange}
              areaRange={areaRange}
              setAreaRange={setAreaRange}
              bedrooms={bedrooms}
              setBedrooms={setBedrooms}
              bathrooms={bathrooms}
              setBathrooms={setBathrooms}
            />
          </div>
        )}

        <div className="d-flex gap-2">
          {filterOption === "Default" && (
            <div
              className={`filter-section-container ${
                isFilterVisible ? "visible" : "hidden d-none d-md-block"
              }`}
            >
            <button
              className="btn-close d-md-none"
              onClick={toggleFilterVisibility}
              aria-label="Close Filters"
            ></button>
              <MLSFilterSection
                propertyType={propertyType}
                setPropertyType={setPropertyType}
                propertyCategory={propertyCategory}
                setPropertyCategory={setPropertyCategory}
                status={status}
                setStatus={setStatus}
                priceRange={priceRange}
                setPriceRange={setPriceRange}
                yearBuiltRange={yearBuiltRange}
                setYearBuiltRange={setYearBuiltRange}
                areaRange={areaRange}
                setAreaRange={setAreaRange}
                bedrooms={bedrooms}
                setBedrooms={setBedrooms}
                bathrooms={bathrooms}
                setBathrooms={setBathrooms}
              />
            </div>
          )}
          <div className="d-flex flex-column align-items-center">
            <div className="my-3 w-100">
              {mlsLoading ? (
                <div
                  className="spinner-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100%",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 9999, // Ensure it stays above other content
                    backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional, to create an overlay
                  }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              ) : exportedListings.length > 0 ? (
                <div className="cards-container">
                  {exportedListings.map((listing) => (
                    <MLSSingleCard
                      key={listing.id} // Ensure unique key
                      card={listing}
                      onClick={openListingPreviewModal}
                    />
                  ))}
                </div>
              ) : (
                <div className="no-listing-message text-center">
                  <p>No listings available for the selected criteria.</p>
                </div>
              )}
            </div>
          </div>

          {/* Floating Filter Button (Visible on Small Screens Only) */}
          <button
            className="floating-filter-button d-md-none"
            onClick={toggleFilterVisibility}
          >
            <div className="">
              <IconAdjustmentsHorizontal />{" "}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MLSGeneratedListing;
