import React from "react";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="d-flex align-items-center justify-content-center mt-4">
      <nav>
        <ul className="pagination gap-1">
          <button
            className="pagination-arrow text-black me-1"
            disabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            <IconChevronLeft />
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <li
              key={page}
              className={`page-item rounded-5 me-1 ${
                currentPage === page ? "active" : ""
              }`}
            >
              <button
                className="page-link rounded-5"
                onClick={() => onPageChange(page)}
              >
                <strong>{page}</strong>
              </button>
            </li>
          ))}
          <button
            className="pagination-arrow text-black"
            disabled={currentPage === totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          >
            <IconChevronRight />
          </button>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
