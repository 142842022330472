import React, { useEffect, useState } from "react";
import BasicSection from "../Preview/BasicSection";
import VideosSection from "../Preview/VideosSection";
import ImagesSection from "../Preview/ImageSection";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllListings,
  fetchIdxProperties,
  fetchListings,
  fetchVowProperties,
} from "../../../modules/dashboard/actions";
import { useParams } from "react-router-dom";
import MlsBasic from "./MlsBasic";
import MlsImages from "./MlsImages";
import MlsVideo from "./MlsVideo";
import Loader from "../../Loader";

const MlsPreview = () => {
  // const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(isOpen);
  const param = useParams();
  const dispatch = useDispatch();
  // const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState("basic");
  const [mlsLoading, setMLSLoading] = useState(false);
  const { allListings, error } = useSelector((state) => state.dashboard);
  const [userId, setUserId] = useState("");
  const [mls, setMls] = useState("");
  const [userType, setUserType] = useState("");
  const [MLSCards, setMLSCards] = useState([]);

  useEffect(() => {
    // Get the current URL from the browser
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get("userId");
    const mlsParam = urlParams.get("mls");
    const userTypeParam = urlParams.get("type");
    setUserId(userIdParam);
    setMls(mlsParam);
    setUserType(userTypeParam);
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      // Set loading to true before the API call
      setMLSLoading(true);
      try {
        let response;
        if (userType === "brokerage") {
          response = await dispatch(fetchIdxProperties("ListOfficeKey", mls));
        } else if (userType === "solo") {
          response = await dispatch(fetchVowProperties("ListAgentKey", mls));
        }
        console.log("mls", response);
        setMLSCards(response.value || response); // Use response.value if it exists, else use response directly
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      } finally {
        // Set loading to false after the API call completes
        setMLSLoading(false);
      }
    };

    fetchProperties();
  }, [dispatch, userType, mls]);

  console.log("mls", MLSCards);
  console.log("mls", mls);

  const renderContent = () => {
    switch (activeTab) {
      case "basic":
        return <MlsBasic selectedListing={param.id} listings={MLSCards} setActiveTab={setActiveTab}/>;
      case "images":
        return <MlsImages selectedListing={param.id} listings={MLSCards} />;
      case "videos":
        return <MlsVideo selectedListing={param.id} listings={MLSCards} />;
      default:
        return <MlsBasic selectedListing={param.id} listings={MLSCards} />;
    }
  };
  return (
    <div className="bg-white">
      {/* {isPreviewModalOpen && ( */}
      <div className="container ">
        <div className="d-flex align-items-center justify-content-center mx-auto gap-2 border-0">
          <div className="d-flex flex-wrap gap-2 border shadow-md pe-2 rounded-4 mb-4">
            <div
              className={`tab-item ${activeTab === "basic" ? "active" : ""}`}
              onClick={() => setActiveTab("basic")}
            >
              Basic
            </div>
            <div
              className={`tab-item ${activeTab === "images" ? "active" : ""}`}
              onClick={() => setActiveTab("images")}
            >
              Images
            </div>
            {/* <div
                        className={`tab-item ${
                          activeTab === "videos" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("videos")}
                      >
                        Videos
                      </div> */}
          </div>
        </div>

        {/* {error && (
                  <div className="text-danger mt-3 text-center">{error}</div>
                )} */}
        <div className=" text-black">
          <div>
            {/* Tab Navigation */}

            {/* Content Section */}
            {mlsLoading ? (
              <Loader />
            ) : (
              <div className="">{renderContent()}</div>
            )}
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default MlsPreview;
