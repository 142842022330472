import { Route, Routes } from "react-router-dom"

import Home from "../modules/public/views/Home";
import AboutUs from "../modules/public/views/AboutUs"
import NotFound from "../modules/public/views/404";

import { PublicPaths } from "./paths"
import Preview from "../components/Listinngs/AddListing/Preview";
import GeneratedListing from "../components/Listinngs/ListingCodeGenerator/GeneratedListing";
import LatestNews from "../components/Dashboard/LatestNews";
import MapPreview from "../components/Listinngs/ListingCodeGenerator/MapPreview";
import CompactSearchPreview from "../components/Listinngs/ListingCodeGenerator/CompactSearchPreview";
import MlsPreview from "../components/Listinngs/Mls/MlsPreview";
import MLSGeneratedListing from "../components/Listinngs/ListingCodeGenerator/MLSGeneratedListing";
import MLSMapPreview from "../components/Listinngs/ListingCodeGenerator/MLSMapPreview";
import MLSCompactSearchPreview from "../components/Listinngs/ListingCodeGenerator/MLSCompactSearchPreview";
import Login from "../modules/auth/views/Login";

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path={PublicPaths.HOME} element={<Login/>}/>
            <Route path={PublicPaths.ABOUT_US} element={<AboutUs/>}/>
            <Route path={PublicPaths.NOT_FOUND} element={<NotFound/>}/>
            <Route path={PublicPaths.LISTING} element={<Preview/>}/>
            <Route path={PublicPaths.MLS_LISTING} element={<MlsPreview/>}/>
            <Route path={PublicPaths.LISTING_GENERATED} element={<GeneratedListing/>}/>
            <Route path={PublicPaths.MLS_LISTING_GENERATED} element={<MLSGeneratedListing/>}/>
            <Route path={PublicPaths.LATEST_NEWS} element={<LatestNews/>}/>
            <Route path={PublicPaths.MAP_PREVIEW} element={<MapPreview/>}/>
            <Route path={PublicPaths.MLS_MAP_PREVIEW} element={<MLSMapPreview/>}/>
            <Route path={PublicPaths.SEARCH_PREVIEW} element={<CompactSearchPreview/>}/>
            <Route path={PublicPaths.MAP_SEARCH_PREVIEW} element={<MLSCompactSearchPreview/>}/>
        </Routes>
    );
}

export default PublicRoutes;