import { IconBath, IconBed, IconMapPin, IconRuler } from "@tabler/icons-react";
import React from "react";
import listingImage from "../../assets/images/login-cover.png";
// import { fetchListingById } from "../../modules/dashboard/actions";
// import { useDispatch } from "react-redux";
import { useState } from "react";

const ListingCard = ({
  cards,
  activeTab,
  onClick,
  handleSelectCard,
  isSelected,
}) => {
  const handleClick = async (id) => {
    // Dispatch the action to fetch listing by ID
    onClick(id);
  };

  const formatListedDate = (dateString) => {
    const listedDate = new Date(dateString);
    const currentDate = new Date();

    // Set both dates to midnight to ignore time differences
    listedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    // Calculate the days difference, ensuring no negative values
    const timeDifference = currentDate - listedDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert ms to days

    // Ensure no negative daysAgo by returning 0 if timeDifference is negative
    const daysAgoFinal = timeDifference < 0 ? 0 : daysAgo;

    return daysAgoFinal === 0
      ? "Listed today"
      : daysAgoFinal === 1
      ? "Listed 1 day ago"
      : `Listed ${daysAgoFinal} days ago`;
  };

  return (
    <>
    
      <div className="cards-container">
        {cards.map((card) => (
          <div
            key={card.id}
            className="card-listing"
            // style={{ height: "340px", overflow: "hidden" }}
          >
            <div className="position-relative">
              <img
                src={
                  card.images && card.images[0] && card.images[0].imageUrl
                    ? card.images[0].imageUrl
                    : listingImage
                }
                alt={card.title}
              />
              <div className="position-absolute top-0 start-0 m-2 P-4">
                {card.status === "active" && (
                  <span className="status text-capitalize">{card.status}</span>
                )}
                {card.status === "sold" && (
                  <span className="status bg-teal text-capitalize">
                    {card.status}
                  </span>
                )}
                {card.status === "on hold" && (
                  <span className="status bg-yellow text-capitalize">
                    {card.status}
                  </span>
                )}
                {card.status === "cancelled" && (
                  <span className="status cancelled text-capitalize">
                    {card.status}
                  </span>
                )}
                {card.status === "pending" && (
                  <span className="status pending text-capitalize">
                    {card.status}
                  </span>
                )}
                {card.status === "deleted" && (
                  <span className="status deleted text-capitalize">
                    {card.status}
                  </span>
                )}
                {card.status === "terminated" && (
                  <span className="status terminated text-capitalize">
                    {card.status}
                  </span>
                )}
                {card.status === "expired" && (
                  <span className="status expired text-capitalize">
                    {card.status}
                  </span>
                )}
                {card.status === "unknown" && (
                  <span className="status unknown text-capitalize">
                    {card.status}
                  </span>
                )}
                {card.status === "wait approval" && (
                  <span className="status wait-approval text-capitalize">
                    {card.status}
                  </span>
                )}
              </div>
              {/* <input
              type="checkbox"
              className="position-absolute top-0 end-0 m-2"
              checked={isSelected(card.id)}
              onChange={() => handleSelectCard(card.id)}
              style={{ width: "20px", height: "20px", cursor: "pointer"}}
            /> */}
            </div>
            <div
              className="card-listing-details cursor-pointer"
              onClick={() => handleClick(card.id)}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-2">
                  <h2>{card.currency}</h2>
                  <h2>{card.price && Number(card.price).toLocaleString()}</h2>
                </div>
                {card.propertyCategory === "sale" && (
                  <div className="h5 bg-primary-lt rounded-4 p-2 text-capitalize">
                    {card.propertyCategory}
                  </div>
                )}
                {card.propertyCategory === "rent" && (
                  <div className="h5 bg-primary-lt rounded-4 p-2 text-capitalize">
                    {card.propertyCategory}
                  </div>
                )}
                {card.propertyCategory === "lease" && (
                  <div className="h5 bg-primary-lt rounded-4 p-2 text-capitalize">
                    {card.propertyCategory}
                  </div>
                )}
              </div>
              <p
                className="h2"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {card.title}
              </p>
              <div className="d-flex gap-2 mb-2">
                <IconMapPin />
                <p
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {" "}
                  {card.address.fullAddress}
                </p>
              </div>
              <div className="d-flex gap-2 mt-auto">
                <div className="d-flex bg-gray-900 rounded-4 p-2 align-items-center gap-1">
                  <IconBed />
                  <div>
                    <strong>{card.buildingInfo.bedroom}</strong>
                  </div>
                </div>
                <div className="d-flex bg-gray-900 rounded-4 p-2 align-items-center gap-1">
                  <IconBath />
                  <div>
                    <strong>{card.buildingInfo.bathroom}</strong>
                  </div>
                </div>
                <div className="d-flex bg-gray-900 rounded-4 p-2 align-items-center gap-1">
                  <IconRuler />
                  <div>
                    <strong>
                      {card.buildingInfo.lotArea}{" "}
                      {card.buildingInfo.areaType === "SQ FT" && (
                        <>
                          ft<sup>2</sup>
                        </>
                      )}
                    </strong>
                  </div>
                </div>
              </div>
              <div className="text-black mt-2 d-flex justify-content-between align-items-center">
                {card.listedDate && formatListedDate(card.listedDate)}
                <div className="bg-gray-900 rounded-circle py-1 px-2"><div>M</div></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ListingCard;
