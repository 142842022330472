import React, { useEffect, useState } from "react";
import MarketingSection from "../../../components/Dashboard/MarketingSection";
import WarningMessage from "../../../components/WarningMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAgent,
  fetchAllLeads,
  fetchLeads,
  fetchListings,
  fetchIdxProperties,
  fetchVowProperties,
} from "../actions";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  // const [activeIndex, setActiveIndex] = useState(0);
  // const profile = useSelector((state) => state.auth.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showOnboardingWarning, setShowOnboardingWarning] = useState(false);
  const [potentialLeadsCount, setPotentialLeadsCount] = useState(0);
  const [mlsCount, setMLSCount] = useState(0);
  const [totalAllListings, setTotalAllListings] = useState(0);
  const profile = useSelector((state) => state.auth.profile);

  const { total, leadsTotal, leadsTotalPages, leadsCurrentPage, leads } =
    useSelector((state) => state.dashboard);
  const [MLSCards, setMLSCards] = useState([]);
  const [mlsLoading, setMLSLoading] = useState(false);

  const countPotentialLeads = async () => {
    const allLeads = await dispatch(fetchAllLeads());
    const potentialLeads = allLeads.filter((lead) => lead.isPotential);
    const potentialCount = potentialLeads.length;
    setPotentialLeadsCount(potentialCount);
    console.log("Potential Leads: ", potentialLeadsCount);
  };

  const handleCompleteProfileClick = () => {
    setShowOnboarding(true);
    setShowOnboardingWarning(false);
  };

  useEffect(() => {
    // Fetch listings and agents on initial load
    dispatch(fetchListings(1, profile.id));
    dispatch(fetchAgent());

    // Function to fetch all leads and count potential leads
    const fetchAllLeadsAndCountPotential = async () => {
      const allLeads = await dispatch(fetchAllLeads()); // Fetch all leads
      const potentialLeads = allLeads.filter((lead) => lead.isPotential); // Filter potential leads
      setPotentialLeadsCount(potentialLeads.length);
    };

    // Call function only if we have valid pages to fetch
    if (leadsTotalPages > 1) {
      fetchAllLeadsAndCountPotential();
    }
  }, [dispatch, profile.id, leadsTotalPages, leads.length, leadsTotal]);

  useEffect(() => {
    dispatch(fetchLeads());
    if (leadsCurrentPage) {
      countPotentialLeads();
    }
  }, [leadsCurrentPage, leadsTotalPages, dispatch]);

  useEffect(() => {
    const fetchProperties = async () => {
      setMLSLoading(true); // Set loading to true before the API call
      try {
        let response;
        if (profile.userType === "brokerage") {
          response = await dispatch(
            fetchIdxProperties("ListOfficeKey", profile.mlsNumber)
          );
        } else if (profile.userType === "solo") {
          response = await dispatch(
            fetchVowProperties("ListAgentKey", profile.mlsNumber)
          );
        } else if (profile.mlsNumber) {
          response = await dispatch(
            fetchVowProperties("ListAgentKey", profile.mlsNumber)
          );
          console.log("Response: ", response);
          if (response.length === 0) {
            response = await dispatch(
              fetchIdxProperties("ListOfficeKey", profile.mlsNumber)
            );
          }
        }
        console.log("mls", response);
        setMLSCards(response.value || response); // Use response.value if it exists, else use response directly
        if (MLSCards.length > 0) {
          setMLSCount(MLSCards.length);
          setTotalAllListings(MLSCards.length + total);
        }
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      } finally {
        setMLSLoading(false); // Set loading to false after the API call completes
      }
    };

    fetchProperties();
  }, [dispatch, profile.userType, profile.mlsNumber, MLSCards.length, total]);

  // const handleCloseOnboarding = () => {
  //   setShowOnboarding(false);
  // };

  const handleViewAllClick = () => {
    // Navigate to the Leads page and pass the state to set activeTab to "Analytics"
    navigate("./leads", { state: { activeTab: "Analytics" } });
  };
  const handleListingViewAllClick = () => {
    // Navigate to the Leads page and pass the state to set activeTab to "Analytics"
    navigate("./listings", { state: { activeTab: "Analytics" } });
  };

  return (
    <div className="">
      <WarningMessage onClick={handleCompleteProfileClick} />
      {/* {!showOnboarding && <Onboarding/>} */}

      <div className="page-wrapper">
        <div className="page-body">
          <div className="container-fluid">
            <div className="row row-deck row-cards">
              <div className="col-sm-6 col-lg-6 rounded-4">
                <div className="card rounded-4">
                  <div className="card-body rounded-4">
                    <div className="d-flex align-items-center justify-content-between rounded-4">
                      <h2>Leads</h2>
                      <span
                        className="h5 text-primary cursor-pointer"
                        onClick={handleViewAllClick}
                      >
                        View All
                      </span>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-end mb-3">
                      <input
                        type="date"
                        className="w-50 justify-content-end form-control form-control-rounded"
                      />
                    </div> */}
                    <div className="d-flex flex-wrap gap-4">
                      <div className="card rounded-4">
                        <div className="card-body">
                          <h4 className="card-subtitle text-body-secondary">
                            Total Leads
                          </h4>
                          <h1 className="card-title-analytics h1">
                            <strong>{leadsTotal}</strong>
                          </h1>
                          <div className="card-text text-cyan d-flex gap-2">
                            {/* <span className="text-cyan">
                              <IconTrendingUp className="me-1" />
                              8.5%
                            </span>{" "} */}
                            Total Leads up to date
                          </div>
                        </div>
                      </div>
                      {/* <div className="card rounded-4">
                        <div className="card-body">
                          <h5 className="card-subtitle text-body-secondary">
                            VOW Profiles
                          </h5>
                          <h1 className="card-title-analytics h1">
                            <strong>0</strong>
                          </h1>
                          <div className="card-text text-cyan d-flex gap-2">
                        
                            Profiles up to date
                          </div>
                        </div>
                      </div> */}
                      <div className="card rounded-4">
                        <div className="card-body">
                          <h4 className="card-subtitle text-body-secondary">
                            Potential Leads
                          </h4>
                          <h1 className="card-title-analytics h1">
                            <strong>{potentialLeadsCount}</strong>
                          </h1>
                          <div className="card-text text-cyan  d-flex gap-2">
                            {/* <span className="text-cyan">
                              <IconThumbUp className="me-1" />
                              8.5%
                            </span>{" "} */}
                            Potential Leads up to date
                          </div>
                        </div>
                      </div>
                      <div className="card rounded-4">
                        <div className="card-body rounded-4">
                          <h4 className="card-subtitle text-body-secondary">
                            Unseen Leads
                          </h4>
                          <h1 className="card-title-analytics h1">
                            <strong>{potentialLeadsCount}</strong>
                          </h1>
                          <p className="card-text text-red">
                            {/* <IconCircleCheck className="me-1" /> */}
                            Unseen Leads up to date
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-6 rounded-4">
                <div className="card rounded-4">
                  <div className="card-body rounded-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h2>Listings</h2>
                      <span
                        className="h5 text-primary cursor-pointer"
                        onClick={handleListingViewAllClick}
                      >
                        View All
                      </span>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-end mb-3">
                      <input
                        type="date"
                        className="w-50 justify-content-end form-control form-control-rounded"
                      />
                    </div> */}
                    <div className="d-flex flex-wrap gap-4">
                      <div className="card rounded-4">
                        <div className="card-body">
                          <h4 className="card-subtitle text-body-secondary">
                            Total Listings
                          </h4>
                          <h1 className="card-title-analytics h1">
                            <strong>{totalAllListings}</strong>
                          </h1>
                          <div className="card-text text-cyan d-flex gap-2">
                            {/* <span className="text-cyan">
                              <IconTrendingUp className="me-1" />
                              8.5%
                            </span>{" "} */}
                            Total Listings up to date
                          </div>
                        </div>
                      </div>
                      <div className="card rounded-4">
                        <div className="card-body">
                          <h4 className="card-subtitle text-body-secondary">
                            MLS Listings
                          </h4>
                          <h1 className="card-title-analytics h1">
                            <strong>{mlsCount}</strong>
                          </h1>
                          <div className="card-text text-cyan d-flex gap-2">
                            {/* <span className="text-cyan">
                              <IconTrendingUp className="me-1" />
                              8.5%
                            </span>{" "} */}
                            MLS Listings up to date
                          </div>
                        </div>
                      </div>
                      <div className="card w-100 rounded-4">
                        <div className="card-body">
                          <h4 className="card-subtitle text-body-secondary">
                            Manual Listings
                          </h4>
                          <h1 className="card-title-analytics h1">
                            <strong>{total}</strong>
                          </h1>
                          <div className="card-text text-cyan d-flex gap-2">
                            Manual Listings up to date
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* marketing section */}
              {/* <MarketingSection /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
