import {
  IconMail,
  IconPhone,
  IconUser,
  IconCalendar,
  IconClock,
  IconMessage,
  IconBlockquote,
} from "@tabler/icons-react";
import React from "react";

const Basic = ({ lead }) => {
  return (
    <>
      <div className="d-flex gap-2">
        <IconUser size={16} />
        <strong>
          <p>
            {lead.firstName && lead.firstName} {lead.lastName && lead.lastName}
          </p>
        </strong>
      </div>
      <div className="d-flex gap-2">
        <IconPhone size={16} />
        <strong>
          <p>{lead.phone && lead.phone}</p>
        </strong>
      </div>
      <div className="d-flex gap-2">
        <IconMail size={16} />
        <strong>
          <p>{lead.email && lead.email}</p>
        </strong>
      </div>
      {/* <div className="d-flex gap-2">
        <IconHome size={16} />
        <strong>
          <p>No 19, 2/5, Wellawatte, Colombo 6, Sri Lanka</p>
        </strong>
      </div> */}
      {lead.date && (
        <div className="d-flex gap-2">
          <IconCalendar size={16} />
          <strong>
            <p>{lead.date && new Date(lead.date).toLocaleDateString()}</p>
          </strong>
        </div>
      )}
      {lead.startTime && (
        <div className="d-flex gap-2">
          <IconClock size={16} />
          <strong>
            <p>
              {lead.startTime && lead.startTime} -{" "}
              {lead.endTime && lead.endTime}
            </p>
          </strong>
        </div>
      )}
      {lead.subject && (
        <div className="d-flex gap-2">
          <IconBlockquote size={16} />
          <strong>
            <p>
              {lead.subject && lead.subject}
            </p>
          </strong>
        </div>
      )}
      {lead.message && (
        <div className="d-flex gap-2">
          <IconMessage size={16} />
          <strong>
          
            <p>
              {lead.message && lead.message}
            </p>
          </strong>
        </div>
      )}
    </>
  );
};

export default Basic;
