import React, { useCallback, useEffect, useState } from "react";
import listingImage from "../../../assets/images/login-cover.png";
import "../../../assets/styles/listingSearch.scss";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { getCountryDataList } from "countries-list";
import { getName as getCountryName } from "country-list";
import SingleCard from "./SingleCard";
import L from "leaflet";
import { IconMapPinFilled } from "@tabler/icons-react";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";
import { LOCATION_URL } from "../../../config/config";
const customIcon = new L.DivIcon({
  className: "custom-tabler-icon",
  html: ReactDOMServer.renderToString(
    <IconMapPinFilled size={24} color="#1655FB" />
  ),
  iconSize: [24, 24],
  iconAnchor: [12, 24],
});

const MLSListIDXSearch = ({
  exportedListings,
  formData,
  handleChange,
  currency,
  onFilter,
  setFormData,
}) => {
  const profile = useSelector((state) => state.auth.profile);
  const [listingsWithCoords, setListingsWithCoords] = useState([]);
  const [mapCenter, setMapCenter] = useState([37.0902, -95.7129]);
  const [bedValue, setBedValue] = useState(
    formData?.buildingInfo?.bedroom || 0
  );
  const [bathValue, setBathValue] = useState(
    formData?.buildingInfo?.bathroom || 0
  );
  const [yearError, setYearError] = useState("");
  const [filteredListingIds, setFilteredListingIds] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  useEffect(() => {
    if (exportedListings && filteredListingIds.length > 0) {
      const filtered = exportedListings.filter((listing) =>
        filteredListingIds.includes(listing.ListingKey)
      );
      setFilteredListings(filtered);
    }
  }, [exportedListings, filteredListingIds]);

  console.log(filteredListings);
  console.log(filteredListingIds);

  console.log(exportedListings);
  useEffect(() => {
    if (onFilter) {
      onFilter(filteredListingIds);
    }
  }, [filteredListingIds, onFilter]);
  // price
  const countries = getCountryDataList();
  const [selectedCurrency, setSelectedCurrency] = useState(
    formData?.currency || ""
  );
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  const currentYear = new Date().getFullYear();

  const incrementBed = () => {
    setBedValue((prevValue) => prevValue + 1);
  };

  const decrementBed = () => {
    setBedValue((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  const incrementBath = () => {
    setBathValue((prevValue) => prevValue + 1);
  };

  const decrementBath = () => {
    setBathValue((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  console.log(filteredListingIds);

  const memoizedHandleChange = useCallback(
    (event) => {
      handleChange(event);
    },
    [handleChange]
  );

  const validateYear = (value) => {
    const year = parseInt(value, 10);
    if (year <= currentYear) {
      setYearError("");
      handleChange({ target: { name: "year", value } });
    } else {
      setYearError(`Year must not be greater than ${currentYear}.`);
      handleChange({ target: { name: "year", value } });
    }
  };

  const geocodeAddress = async (listing) => {
    // Construct full address based on the new structure
    const fullAddress = `${
      listing.UnitNumber ? `#${listing.UnitNumber} - ` : ""
    }${listing.StreetNumber || ""} ${listing.StreetName || ""} ${
      listing.StreetSuffix || ""
    }, ${listing.City || ""}, ${listing.StateOrProvince || ""}, ${
      listing.Country || ""
    }, ${listing.PostalCode || ""}`;

    console.log("Geocoding address:", fullAddress);

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          fullAddress
        )}&key=${LOCATION_URL}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.results && data.results.length > 0) {
        return {
          latitude: data.results[0].geometry.location.lat,
          longitude: data.results[0].geometry.location.lng,
        };
      } else {
        console.warn("No geocoding results for:", fullAddress);
        return null;
      }
    } catch (error) {
      console.error("Geocoding error:", error.message);
      return null;
    }
  };

  // Update listings with coordinates
  useEffect(() => {
    const updateListingsWithCoordinates = async () => {
      const updatedListings = await Promise.all(
        filteredListings.map(async (listing) => {
          let { latitude, longitude } = listing;

          if (!latitude || !longitude) {
            const coords = await geocodeAddress(listing); // Pass the whole listing object
            if (coords) {
              latitude = coords.latitude;
              longitude = coords.longitude;
            } else {
              console.warn(
                `Skipping listing due to geocoding failure: ${listing.id}`
              );
              return null; // Skip this listing if geocoding fails
            }
          }

          return {
            ...listing,
            address: {
              ...listing.address,
              latitude,
              longitude,
            },
          };
        })
      );

      const validListings = updatedListings.filter(Boolean);
      setListingsWithCoords(validListings);
      // setFilteredListingIds(updatedListings.map((listing) => listing.ListingKey))
    };
    if (filteredListings.length > 0) {
      updateListingsWithCoordinates();
    } else {
      setListingsWithCoords([]);
    }
  }, [filteredListings]);

  // Update map center based on listings with valid coordinates
  useEffect(() => {
    const validListings = listingsWithCoords.filter(
      (listing) => listing.address.latitude && listing.address.longitude
    );

    if (validListings.length > 0) {
      const avgLat =
        validListings.reduce(
          (sum, listing) => sum + listing.address.latitude,
          0
        ) / validListings.length;
      const avgLng =
        validListings.reduce(
          (sum, listing) => sum + listing.address.longitude,
          0
        ) / validListings.length;
      setMapCenter([avgLat, avgLng]);
    } else {
      setMapCenter([51.505, -0.09]); // Fallback to a default location
    }
  }, [listingsWithCoords]);

  const getSingleCurrency = (country) => {
    return Array.isArray(country.currency)
      ? country.currency[0]
      : country.currency;
  };

  // Handle country change to update both currency and selected country code
  const handleCountryChange = (countryCode) => {
    const selectedCountry = countries[countryCode];
    if (!countryCode) {
      setSelectedCurrency("");
      setSelectedCountryCode("");
      setFormData((prevData) => ({
        ...prevData,
        currency: "", // Clear currency to show all listings
      }));
    } else if (selectedCountry) {
      const singleCurrency = getSingleCurrency(selectedCountry);
      setSelectedCurrency(singleCurrency);
      setSelectedCountryCode(countryCode);
      setFormData((prevData) => ({
        ...prevData,
        currency: singleCurrency,
      }));
    }
  };

  // Set country based on initial formData.currency
  useEffect(() => {
    const countryCode = Object.keys(countries).find(
      (code) => getSingleCurrency(countries[code]) === formData.currency
    );
    if (countryCode) {
      setSelectedCountryCode(countryCode);
    }
  }, [formData.currency]);

  const filterListings = useCallback(() => {
    const minPrice = Number(formData.minPrice);
    const maxPrice = Number(formData.maxPrice);
    const year = Number(formData.year);

    const filtered = exportedListings.filter((listing) => {
      const listingPrice = listing.ListPrice;
      console.log("listingPrice", listingPrice);

      return (
        // Check if listing price is within the specified range
        listing.UnparsedAddress.toLowerCase().includes(
          formData.location.toLowerCase()
        ) &&
        (!formData.minPrice || listingPrice >= minPrice) &&
        (!formData.maxPrice || listingPrice <= maxPrice) &&
        listing.BedroomsTotal >= bedValue &&
        listing.BathroomsTotalInteger >= bathValue &&
        (listing.TaxYear <= year || !formData.year) &&
        (listing.PropertyType === formData.propertyType ||
          !formData.propertyType) &&
        (listing.TransactionType === formData.propertyCategory ||
          !formData.propertyCategory) &&
        (listing.MlsStatus === formData.status || !formData.status)
      );
    });

    console.log("exportedListings", exportedListings);

    setFilteredListingIds(filtered.map((listing) => listing.ListingKey));
    console.log("filtered", filtered);
  }, [
    formData.location,
    exportedListings,
    formData.minPrice,
    formData.maxPrice,
    formData.year,
    formData.propertyType,
    formData.propertyCategory,
    formData.status,
    bedValue,
    bathValue,
  ]);

  console.log(bathValue);
  console.log(bedValue);

  useEffect(() => {
    filterListings();
  }, [
    exportedListings,
    formData.minPrice,
    formData.maxPrice,
    formData.year,
    formData.propertyType,
    formData.propertyCategory,
    formData.status,
    bedValue,
    bathValue,
    filterListings, // Now safe to include since it's memoized
  ]);

  const openListingPreviewModal = (listingId) => {
    const baseUrl = window.location.origin;
    const preview = `${baseUrl}/listing/${listingId}?userId=${profile.id}`;
    window.open(preview, "_blank"); // Opens the link in a new tab
  };

  console.log("cords", listingsWithCoords);
  // useEffect(() => {
  //   console.log(listingsWithCoords); // Log updated listings with coordinates
  // }, [listingsWithCoords]);

  return (
    <div>
      {/* Map */}
      <div className="main-image mb-4">
        <MapContainer
          center={mapCenter} // Use the dynamic map center
          zoom={3} // Set appropriate zoom level
          minZoom={1}
          style={{
            height: "250px",
            width: "100%",
            marginBottom: "3px",
          }}
          className="mb-4 rounded-3"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {listingsWithCoords.map((listing) => {
            const { latitude, longitude } = listing.address;
            if (latitude && longitude) {
              return (
                <Marker
                  key={listing.ListingKey}
                  position={[parseFloat(latitude), parseFloat(longitude)]}
                  icon={customIcon}
                >
                  <Popup>{listing.UnparsedAddress}</Popup>
                </Marker>
              );
            }
            return null;
          })}
        </MapContainer>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-3">
        {/* <div className="mb-3 w-100">
      

          <p className="col-md-4">Radius: 2.5Km</p>
          <div className="ml-auto d-flex gap-3">
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
            <p>Larger Radius</p>
          </div>
        </div> */}
        <div className="mb-3 w-100">
          <label>Pre Selected Location</label>
          <input
            type="text"
            name="location"
            placeholder="Enter Location"
            className="form-control form-control-rounded"
            value={formData.location}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3 w-100">
          <label>Price</label>
          <div className="d-flex align-items-center">
            <input
              type="number"
              name="minPrice"
              placeholder="Min Price"
              className="form-control round-left"
              value={formData.minPrice}
              onChange={handleChange}
            />
            <input
              type="number"
              name="maxPrice"
              placeholder="Max Price"
              className="form-control  round-right"
              value={formData.maxPrice}
              onChange={handleChange}
            />
            {/* {selectedCurrency && (
              <div className="form-control border-end mt-2 w-25 bg-gray-500">
                {selectedCurrency}
              </div>
            )}
            <select
              className="form-select text-secondary"
              name="country"
              value={selectedCountryCode}
              onChange={(e) => handleCountryChange(e.target.value)}
              style={styles.select}
            >
              <option value="">Currency</option>{" "}
             
              {Object.keys(countries).map((code) => (
                <option key={code} value={code}>
                  {countries[code].name}
                </option>
              ))}
            </select> */}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-3">
        <div className="mb-3 w-100">
          <label>Property Type</label>
          <select
            className="form-select rounded-5"
            name="propertyType"
            // defaultValue={formData.propertyType}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              All
            </option>
            <option value="Commercial" className="form-select">
              Commercial
            </option>
            <option value="Residential Condo & Other" className="form-select">
              Condo
            </option>
            <option value="Residential Freehold" className="form-select">
              Freehold
            </option>
          </select>
        </div>
        <div className="mb-3 w-100">
          <label>Property Category</label>
          <select
            className="form-select rounded-5"
            name="propertyCategory"
            // defaultValue={formData.propertyCategory}
            onChange={handleChange}
          >
            <option value="" className="form-select">
                  All
                </option>
                <option value="For Sale" className="form-select">
                  Sale
                </option>
                <option value="For Lease" className="form-select">
                  Lease
                </option>
          </select>
        </div>
        <div className="mb-3 w-100">
          <label>Status</label>
          <select
            className="form-select rounded-5"
            name="status"
            // defaultValue={formData.status}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              All
            </option>
            <option value="active" className="form-select">
              Active
            </option>
            <option value="New" className="form-select">
              New
            </option>
            <option value="Price Change" className="form-select">
              Price Change
            </option>
            <option value="expired" className="form-select">
              Expired
            </option>
            <option value="on hold" className="form-select">
              On Hold
            </option>
            <option value="pending" className="form-select">
              Pending
            </option>
            <option value="sold" className="form-select">
              Sold
            </option>
            <option value="terminated" className="form-select">
              Terminated
            </option>
            <option value="unknown" className="form-select">
              Unknown
            </option>
            <option value="wait approval" className="form-select">
              Wait Approval
            </option>
          </select>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-3">
        <div className="mb-3 w-100">
          <label>Year Built</label>
          <input
            type="number"
            name="year"
            placeholder="Year Built"
            className="form-control form-control-rounded"
            // defaultValue={formData.buildingInfo.year}
            onChange={(e) => validateYear(e.target.value)}
          />
          {yearError && <small className="text-danger">{yearError}</small>}
        </div>
        {/* <div className="mb-3 w-100">
          <label>Approx. Area</label>
          <div className="d-flex">
            <input
              type="number"
              name="approxArea"
              placeholder="Approx. Area"
              className="form-control round-left"
              // defaultValue={formData.buildingInfo.livingArea}
              onChange={handleChange}
              style={styles.inputArea}
            />
            <select
              className="form-select"
              name="areaType"
              // defaultValue={formData.buildingInfo.areaType}
              onChange={handleChange}
              style={styles.select}
            >
              <option value="SQ FT" selected>
                SQ FT
              </option>
              <option value="Acres">Acres</option>
              <option value="Hectares">Hectares</option>
              <option value="Square Meters">Square Meters</option>
            </select>
          </div>
        </div> */}
        <div className="mb-3 w-100">
          <div style={styles.container}>
            <label className="mb-2"> Bedrooms</label>
            <div style={styles.inputContainer}>
              <button
                style={styles.button}
                onClick={decrementBed}
                disabled={bedValue === 0}
              >
                -
              </button>
              <input
                type="text"
                value={bedValue}
                name="bedroom"
                onChange={(e) =>
                  setBedValue(Math.max(0, parseInt(e.target.value, 10) || 0))
                }
                style={styles.input}
              />

              <button style={styles.button} onClick={incrementBed}>
                +
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 w-100">
          <div style={styles.container}>
            <label className="mb-2"> Bathrooms</label>
            <div style={styles.inputContainer}>
              <button
                style={styles.button}
                onClick={decrementBath}
                disabled={bathValue === 0}
              >
                -
              </button>
              <input
                type="text"
                value={bathValue}
                name="bathroom"
                onChange={(e) => {
                  const newValue = Math.max(0, parseInt(e.target.value, 10));
                  bathValue(isNaN(newValue) ? 0 : newValue);
                }}
                style={styles.input}
              />
              <button style={styles.button} onClick={incrementBath}>
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "20px",
    overflow: "hidden",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#f0f0f0",
    border: "none",
    borderRadius: "30px",
    padding: "6px 8px 6px 8px",
    cursor: "pointer",
    fontSize: "16px",
    width: "40px",
    textAlign: "center",
    margin: "3px",
  },
  input: {
    width: "40px",
    textAlign: "center",
    border: "none",
    outline: "none",
    fontSize: "13px",
    margin: "0 3px",
    backgroundColor: "white",
  },
  select: {
    width: "90px",
    border: "none",
    outline: "none",
    padding: "10px",
    borderRadius: "0 20px 20px 0",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
};

export default MLSListIDXSearch;
