import {
  IconChevronDown,
  IconCirclePlus,
  IconMail,
  IconPhone,
  IconTrash,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import AddLead from "../../../components/Leads/AddLead";
import ViewLead from "../../../components/Leads/ViewLeads";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLeadApi,
  fetchAllLeads,
  fetchAllListings,
  fetchIdxProperties,
  fetchLeadById,
  fetchLeads,
} from "../actions";
import { Bounce, toast } from "react-toastify";
import LeadsAnalytics from "../../../components/Leads/LeadsAnalytics";
import { useLocation } from "react-router-dom";
import Pagination from "../../../components/Pagination";

const Leads = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const tabs = ["All", "Leads", "Potential Leads"];
  const [leadData, setLeadData] = useState({});
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [leadIdToDelete, setLeadIdToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const profile = useSelector((state) => state.auth.profile);
  const { allListings } = useSelector((state) => state.dashboard);
  const [mlsLoading, setMLSLoading] = useState(false);
  const [MLSCards, setMLSCards] = useState([]);

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "active":
        return "bg-success"; // Green for active
      case "sold":
        return "bg-danger"; // Red for sold
      case "on hold":
        return "bg-yellow"; // Yellow for on hold
      case "pending":
        return "bg-yellow"; // Blue for pending
      case "deleted":
        return "bg-danger"; // Dark for deleted
      case "terminated":
        return "bg-danger"; // Gray for terminated
      case "expired":
        return "bg-danger"; // Muted color for expired
      case "cancelled":
        return "bg-danger"; // Light color for cancelled
      case "unknown":
        return "unknown"; // Gray for unknown
      case "wait approval":
        return "bg-yellow"; // Blue for waiting approval
      default:
        return "bg-secondary"; // Default color if status is undefined
    }
  };

  const handleDeleteClick = (id) => {
    setLeadIdToDelete(id);
    setIsConfirmModalOpen(true); // Open confirmation modal
  };

  const truncate = (input, maxLength) => {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + "...";
    }
    return input;
  };

  const handleDeleteConfirm = async () => {
    const response = await dispatch(deleteLeadApi(leadIdToDelete));
    if (response) {
      toast.success("Lead is deleted", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
    setIsConfirmModalOpen(false);
    setOpenDropdownId(null);
  };

  const handleDeleteCancel = () => {
    setIsConfirmModalOpen(false);
    setLeadIdToDelete(null);
  };

  const {
   
    allLeads,
    lead,
    leadsTotalPages,
    loading,
  } = useSelector((state) => state.dashboard);

  console.log("leads", allLeads);

  useEffect(() => {
    dispatch(fetchLeads());
    dispatch(fetchAllLeads());
    dispatch(fetchAllListings(profile.id));
    if (location.state?.activeTab === "Analytics") {
      setActiveTab("Analytics"); // Set the active tab to Analytics
    }
  }, [dispatch, lead, location.state, isConfirmModalOpen]);

  useEffect(() => {
    const fetchProperties = async () => {
      setMLSLoading(true); // Set loading to true before the API call
      try {
        let response;
        if (profile.userType === "brokerage") {
          response = await dispatch(
            fetchIdxProperties("ListOfficeKey", profile.mlsNumber)
          );
        } else {
          response = await dispatch(
            fetchIdxProperties("ListAgentKey", profile.mlsNumber)
          );
        }
        console.log("mls", response);
        setMLSCards(response.value || response); // Use response.value if it exists, else use response directly
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      } finally {
        setMLSLoading(false); // Set loading to false after the API call completes
      }
    };

    fetchProperties();
  }, [dispatch, profile.userType, profile.mlsNumber]);

  const openLeadsModal = () => {
    setIsModalOpen(true);
  };

  const closeLeadsModal = () => {
    setIsModalOpen(false);
  };

  const openVIewLeadsModal = async (id) => {
    setIsViewModalOpen(true);
    const data = await dispatch(fetchLeadById(id));
    setLeadData(data);
  };

  const closeViewLeadsModal = () => {
    setIsViewModalOpen(false);
  };

  useEffect(() => {
    // setIsConfirmModalOpen(isOpen);
    if (isConfirmModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isConfirmModalOpen]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= leadsTotalPages) {
      dispatch(fetchLeads(newPage)); // Fetch listings for the new page based on status
    }
  };

  const sortedLeads = [...allLeads].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const filteredLeads = sortedLeads.filter((lead) => {
    const fullName = `${lead.firstName} ${lead.lastName}`.toLowerCase();
    const email = lead.email.toLowerCase();
    const phone = lead.phone.toLowerCase();
    const searchValue = searchTerm.toLowerCase();

    // Apply search term filter
    const matchesSearch =
      fullName.includes(searchValue) ||
      email.includes(searchValue) ||
      phone.includes(searchValue);

    // Apply activeTab filter
    if (activeTab === "Potential Leads") {
      return matchesSearch && lead.isPotential === true; // Show only potential leads
    } else if (activeTab === "Leads") {
      return matchesSearch && lead.isPotential === false; // Show regular leads
    } else {
      return matchesSearch; // Show all leads
    }
  });

  const totalLeads = filteredLeads?.length || 0;
  const leadsPerPage = 10;
  const totalPages = Math.ceil(totalLeads / leadsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedLeads = filteredLeads?.slice(
    (currentPage - 1) * leadsPerPage,
    currentPage * leadsPerPage
  );

  const handlePageChangeLeads = (page) => {
    setCurrentPage(page);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab); // Set the active tab
  };

  const handlePreview = (listingId) => {
    const baseUrl = window.location.origin;
    // Check if the listing exists in allListings
    const isInAllListings = allListings.some(
      (listing) => listing.id === listingId
    );

    // Check if the listing exists in MLSCards
    const isInMLSCards = MLSCards.some(
      (listing) => listing.ListingKey === listingId
    );

    // Construct the preview URL based on the source
    let preview = "";
    if (isInAllListings) {
      preview = `${baseUrl}/listing/${listingId}?userId=${profile.id}`;
    } else if (isInMLSCards) {
      preview = `${baseUrl}/listingMLS/${listingId}?userId=${profile.id}&mls=${profile.mlsNumber}&type=${profile?.userType}`;
    } else {
      console.error("Listing ID not found in either source.");
      return;
    }

    // Open the preview link in a new tab
    window.open(preview, "_blank");
  };

  return (
    <div>
      <AddLead isOpen={isModalOpen} closeModel={closeLeadsModal} />
      <ViewLead
        isOpen={isViewModalOpen}
        closeModel={closeViewLeadsModal}
        lead={leadData}
        listings={allListings}
        mls={MLSCards}
      />
      {isConfirmModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Lead</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleDeleteCancel}
                ></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this lead?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="page-wrapper">
        <div className="page-header m-3">
          <div className="row align-items-center gap-2">
            <div className="col-auto bg-white rounded-4 border">
              <div className="border-0">
                {tabs.map((tab) => (
                  <button
                    key={tab}
                    className={`my-1 text-capitalize ${
                      activeTab === tab
                        ? "btn btn-outline-primary btn-pill bg-tabs"
                        : "bg-white border-0 mx-2"
                    }`}
                    onClick={() => handleTabChange(tab)}
                  >
                    {tab}
                  </button>
                ))}
                <button
                  className={`my-1 text-capitalize ${
                    activeTab === "Analytics"
                      ? "btn btn-outline-primary btn-pill bg-blue-lt"
                      : "bg-white border-0 mx-1 border-start "
                  }`}
                  onClick={() => setActiveTab("Analytics")}
                >
                  Analytics
                </button>
              </div>
            </div>
            <div className="col-auto ms-auto">
              <div className="btn-list">
                <button
                  className="btn btn-pill btn-primary"
                  onClick={openLeadsModal}
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="ms-2">Create a New Lead</div>
                    <IconCirclePlus size={18} />
                  </div>
                </button>
                {/* <button className="btn btn-outline-primary btn-pill">
                  <div className="d-flex align-items-center gap-2">
                    <div className="ms-2">Import</div>
                    <IconChevronDown size={18} />
                  </div>
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="page-header m-3">
          <div className="row align-items-center gap-2">
            <div className="col-auto rounded-4">
              {/* <div className="btn-list">
                <button className="btn btn-outline-primary btn-pill">
                  <div className="d-flex align-items-center gap-2">
                    <div className="">Select</div>
                  </div>
                </button>
                <button className="btn btn-outline-primary btn-pill">
                  <div className="d-flex align-items-center gap-2">
                    <div className="ms-2">Sort</div>
                    <IconChevronDown size={18} />
                  </div>
                </button>
              </div> */}
            </div>
            <div className="col-auto ms-auto">
              <div className="input-icon mb-3">
                <span className="input-icon-addon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control form-control-rounded border-0 bg-gray-600"
                  placeholder="Search"
                  value={searchTerm} // Controlled input
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                />
              </div>
            </div>
          </div>
        </div>
        {activeTab === "Analytics" ? (
          <LeadsAnalytics />
        ) : (
          <div className="table-responsive page-header m-3 bg-white border rounded-4">
            {loading ? (
              // Loading Spinner
              <div className="d-flex justify-content-center align-items-center p-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : paginatedLeads.length === 0 ? (
              // Empty Array Message
              <div className="text-center p-4">
                <p className="">No leads available</p>
              </div>
            ) : (
              <>
                <table
                  className="pb-3 table-vcenter table-nowrap overflow-auto"
                  style={{ borderRadius: "12px", overflow: "auto" }}
                >
                  <thead className="mb-2 p-2">
                    <tr>
                      <th className="p-3">Name</th>
                      {/* <th className="p-3">Lead Id</th> */}
                      <th className="p-3">Listing</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th className="p-3">Action</th>
                    </tr>
                  </thead>
                  <tbody
                    className="table-content-body bg-white"
                    style={{
                      borderRadius: "0 0 12px 12px",
                      overflow: "hidden",
                    }}
                  >
                    {paginatedLeads.map((lead, index) => (
                      <tr key={index} className="mb-2">
                        {/* <td className="p-2 cursor-pointer" onClick={() => openVIewLeadsModal(lead.id)}>
                      <div className="d-flex align-items-center">
                        <IconMail size={18} className="me-2" />
                        {lead.id}
                      </div>
                    </td> */}
                        <td
                          className="p-3 cursor-pointer"
                          onClick={() => openVIewLeadsModal(lead.id)}
                        >
                          <div className="d-flex align-items-center">
                            <div className="avatar bg-gray-300 rounded-4 me-3">
                              <span className="fs-4 fw-bold text-dark">
                                {lead.firstName.charAt(0).toUpperCase()}
                              </span>
                            </div>
                            <div>
                              <div className="fw-semibold">
                                {truncate(
                                  `${lead.firstName} ${lead.lastName}`,
                                  20
                                )}
                              </div>
                              <div className="badge bg-primary text-white">
                                {lead.isPotential ? "Potential" : "Lead"}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          className="p-2 cursor-pointer"
                          onClick={() =>
                            lead.listingId && handlePreview(lead.listingId)
                          }
                        >
                          <div className="d-flex align-items-center">
                            {allListings.map((listing) => {
                              if (listing.id === lead.listingId) {
                                return (
                                  <div className="d-felx flex-column">
                                    <div
                                      className="d-flex align-items-center"
                                      key={listing.id}
                                    >
                                      <div>
                                        <div className="fw-semibold">
                                          {listing.title
                                            ? truncate(listing.title, 20)
                                            : "No Title Available"}
                                        </div>
                                        <div className="d-flex gap-2">
                                          <div
                                            className={`badge text-white text-capitalize ${getStatusBadgeClass(
                                              listing.status
                                            )}`}
                                          >
                                            {listing.status
                                              ? listing.status
                                              : "No status Available"}
                                          </div>
                                          {listing.propertyType && (
                                            <div className=" text-black text-capitalize">
                                              {listing.propertyType}{" "}
                                              <strong>|</strong>
                                            </div>
                                          )}
                                          {listing.propertyCategory && (
                                            <div className=" text-black text-capitalize">
                                              {listing.propertyCategory}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <p className="text-muted">Manual</p>
                                  </div>
                                );
                              } else return null; // Ensure no empty elements are rendered when condition isn't met
                            })}
                            {MLSCards.map((listing) => {
                              if (listing.ListingKey === lead.listingId) {
                                return (
                                  <div className="d-felx flex-column">
                                    <div
                                      className="d-flex align-items-center"
                                      key={listing.ListingKey}
                                    >
                                      <div>
                                        <div className="fw-semibold">
                                          {listing.UnparsedAddress
                                            ? truncate(
                                                listing.UnparsedAddress,
                                                20
                                              )
                                            : "No Title Available"}
                                        </div>
                                        <div className="d-flex gap-2">
                                          <div
                                            className={`badge text-white text-capitalize ${getStatusBadgeClass(
                                              listing.MlsStatus
                                            )}`}
                                          >
                                            {listing.MlsStatus
                                              ? listing.MlsStatus
                                              : "No status Available"}
                                          </div>
                                          {listing.PropertyType && (
                                            <div className=" text-black text-capitalize">
                                              {listing.PropertyType}{" "}
                                              <strong>|</strong>
                                            </div>
                                          )}
                                          {listing.propertyCategory && (
                                            <div className=" text-black text-capitalize">
                                              {listing.PropertySubType}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <p className="text-muted">MLS</p>
                                  </div>
                                );
                              } else return null; // Ensure no empty elements are rendered when condition isn't met
                            })}
                            {!lead.listingId && (
                              <p className="">
                                No Listing Available
                              </p>
                            )}
                          </div>
                        </td>

                        <td
                          className="p-2 cursor-pointer"
                          onClick={() => openVIewLeadsModal(lead.id)}
                        >
                          <div className="d-flex align-items-center">
                            <IconPhone size={18} className="me-2" />
                            {lead.phone}
                          </div>
                        </td>
                        <td className="p-2 cursor-pointer">
                          <div className="d-flex align-items-center">
                            <IconMail size={18} className="me-2" />
                            {lead.email}
                          </div>
                        </td>
                        <td className="cursor-pointer position-relative">
                          <button
                            className="btn btn-link text-danger d-flex align-items-center gap-2"
                            onClick={() => handleDeleteClick(lead.id)}
                          >
                            <IconTrash size={18} />
                            <span className="text-danger">Delete</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
           
                <Pagination currentPage={currentPage} onPageChange={handlePageChangeLeads} totalPages={totalPages}/>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Leads;
