import {
  IconCheck,
  IconEdit,
  IconExclamationCircle,
  IconEye,
  IconEyeOff,
  IconX,
} from "@tabler/icons-react";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getProfile, updatePassword, updateProfile } from "../../auth/actions";
import { Bounce, toast } from "react-toastify";
import { downloadImageApi, uploadImageApi } from "../actions";
import { getDownloadUrl } from "../../../config/config";

const Profile = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);
  const [editMode, setEditMode] = useState(false);
  const [showPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [error, setError] = useState("");
  const [imageError, setImageError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    id: profile.id,
    phone: profile.phone || "N/A",
    businessPhone: profile.businessPhone,
    displayName: profile.displayName,
    name: profile.name || "N/A",
    businessAddress: profile.businessAddress || "N/A",
    email: profile.email || "N/A",
    isSkipped: profile.isSkipped || false,
    isVerified: profile.isVerified || false,
    profileUrl: profile.profileUrl || "N/A",
  });

  const fileInputRef = useRef(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Upload the image and get the image URL
        const uploadResponse = await dispatch(uploadImageApi(file));
        const filePath = new URL(uploadResponse).pathname.substring(1);
        console.log(uploadResponse);
        const fileName = uploadResponse.substring(
          uploadResponse.lastIndexOf("/") + 1
        );
        const downloadUrl = getDownloadUrl(fileName);
        if (filePath) {
          const response = await dispatch(downloadImageApi(filePath)); // Pass file path to API
          if(response.filePath) {
            setImageError("");
          }
          // const data = await response.json();
          // Set the downloaded image URL
          console.log(response.filePath);
        }
        const imageUrl = downloadUrl; // Assuming the API returns the URL in the payload
        setFormData({ ...formData, profileUrl: imageUrl });
      } catch (uploadError) {
        setImageError("Image size should be less than 2MB");
      }
    }
  };

  const handleAvatarClick = () => {
    if (editMode && fileInputRef.current) {
      fileInputRef.current.click(); // Trigger file input click
    }
  };

  console.log("Profile Data: ", formData);

  const [formPassword, setFormPassword] = useState({
    password: "",
    newPassword: "",
    retypePassword: "",
  });

  console.log("Password", formPassword);

  // Toggle Password Visibility Handlers
  const togglePasswordVisibility = () => {
    setShowCurrentPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleRetypePasswordVisibility = () => {
    setShowRetypePassword(!showRetypePassword);
  };

  // Handle Edit Mode Toggle
  const handleEditToggle = () => {
    setEditMode(true); // Toggle between edit and view mode
  };
  const handleCloseToggle = () => {
    // Toggle between edit and view mode

    setEditMode(false);
    dispatch(getProfile());
  };

  // Handle form input change
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handlePasswordChange = (e) => {
    setError("");
    setFormPassword({
      ...formPassword,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };
  const handleBusinessPhoneChange = (value) => {
    setFormData({
      ...formData,
      businessPhone: value,
    });
  };

  // Handle Profile Update (API Call)
  const handleProfileUpdate = async () => {
    const isProfileChanged = Object.keys(formData).some(
      (key) => formData[key] !== profile[key]
    );

    // If profile hasn't changed, don't proceed with the update
    if (!isProfileChanged) {
      toast.info("No changes detected in profile", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    setIsSubmitting(true);
    try {
      // Call API here with the updated formData
      const response = await dispatch(updateProfile(profile.id, formData));
      console.log("Profile Update Response: ", response);

      if (response.id) {
        toast.success("Profile updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }

      console.log("Profile Update Response: ", response);
      console.log("Profile Updated with Data: ", formData);

      // After successful update, you can switch back to view mode if needed
      setEditMode(false);
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error updating profile: ", error);
      // toast.error("Failed to update profile", {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   transition: Bounce,
      // });
    }
    setIsSubmitting(false);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordUpdate = async () => {
    if (formPassword.newPassword !== formPassword.retypePassword) {
      setError("Passwords do not match");
      return;
    }

    if (!validatePassword(formPassword.newPassword)) {
      setError(
        "Password must be at least 6 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character."
      );
      return;
    }

    // Create payload with only password and newPassword
    const passwordData = {
      password: formPassword.password,
      newPassword: formPassword.newPassword,
    };
    setIsSubmitting(true);
    try {
      // Await the result of the API call
      const response = await dispatch(updatePassword(passwordData));

      console.log("Password Update Response: ", response);

      if (response.name === "Success") {
        toast.success("Password updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        // Reset the password form after a successful update
        setFormPassword({
          password: "",
          newPassword: "",
          retypePassword: "",
        });
      }

      if (response.name === "Error") {
        toast.error(response.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
      setError("");
    } catch (error) {
      // Handle error response
      console.error("Error updating password:", error);
      alert(error.message || "An error occurred");
    }
    setEditMode(false);
    setIsSubmitting(false);
  };

  return (
    <div className="page mt-2 mb-3">
      <div className="container-xxl">
        <div className="row row-cards">
          <div className="col-12">
            {/* Account Information Section */}
            <div className="card rounded-4">
              <div className="card-header border-0 d-flex justify-content-between">
                <div>
                  <h2 className="text-primary">Account Information</h2>
                  <p>Click on the edit button to update your profile.</p>
                </div>
                {/* <span
                  className="cursor-pointer"
                  onClick={editMode ? handleProfileUpdate : handleEditToggle}
                >
                  {editMode ? <IconX size={20} /> : <IconEdit size={20} />}
                </span> */}
              </div>
              <div className="card-body">
                <div className="d-flex gap-6">
                  <div className="">
                    <div className="d-flex flex-column align-items-center">
                      <label className="form-label">Profile Image</label>
                      
                      <span
                        className="avatar avatar-xl rounded-circle"
                        style={{
                          backgroundImage: `url(${
                            formData.profileUrl ||
                            "https://via.placeholder.com/150"
                          })`,
                          position: "relative",
                          width: "120px",
                          height: "120px",
                          backgroundSize: "cover",
                          borderRadius: "50%",
                          cursor: editMode ? "pointer" : "default",
                        }}
                        onClick={(e) => {
                          // Check if the click target is not IconEdit or IconX
                          if (
                            editMode &&
                            e.target.closest(".icon-edit") === null &&
                            e.target.closest(".icon-close") === null
                          ) {
                            handleAvatarClick();
                          }
                        }}
                      >
                        {editMode && (
                          <>
                            <input
                              type="file"
                              accept="image/*"
                              className="d-none"
                              ref={fileInputRef}
                              onChange={handleImageChange}
                            />
                            {/* Close Icon */}
                            <span
                              className="badge bg-white text-primary border-primary cursor-pointer icon-close" // Add unique class here
                              style={{
                                position: "absolute",
                                right: "5px",
                                bottom: "80px",
                                borderRadius: "100%",
                                padding: "3px",
                              }}
                              onClick={handleCloseToggle}
                            >
                              <IconX size={16} />
                            </span>
                          </>
                        )}
                        {/* Edit Icon */}
                        <span
                          className={`badge bg-white cursor-pointer ${
                            editMode
                              ? "text-primary border-primary"
                              : "text-muted border-muted"
                          } icon-edit`} // Unique class for IconEdit
                          style={{
                            position: "absolute",
                            bottom: "1px",
                            right: "5px",
                            borderRadius: "100%",
                            padding: "3px",
                          }}
                          onClick={
                            editMode ? handleProfileUpdate : handleEditToggle
                          }
                          disabled={isSubmitting}
                        >
                          <IconEdit
                            size={16}
                            className={editMode ? "text-primary" : "text-muted"}
                          />
                        </span>
                      </span>
                      {imageError && (
                        <div className="text-danger mt-3 text-center">
                          {imageError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column flex-md-row gap-2">
                      <div className="form-group pl-4 mb-3 w-100">
                        <label>
                          Account ID{" "}
                          <IconExclamationCircle
                            className="text-muted"
                            size={15}
                          />
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-rounded"
                          defaultValue={profile.id || "N/A"}
                          readOnly
                          disabled
                        />
                      </div>
                      {profile.userType === "brokerage" ? (
                        <div className="form-group mb-3 w-100">
                          <label>
                            Your Name{" "}
                            <IconExclamationCircle
                              className="text-muted"
                              size={15}
                            />
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-rounded"
                            name="name"
                            defaultValue={formData.name}
                            readOnly={!editMode}
                            onChange={handleInputChange}
                          />
                        </div>
                      ) : (
                        <div className="form-group mb-3 w-100">
                          <label>
                            Your Name{" "}
                            <IconExclamationCircle
                              className="text-muted"
                              size={15}
                            />
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-rounded"
                            name="name"
                            defaultValue={formData.name}
                            readOnly={!editMode}
                            onChange={handleInputChange}
                          />
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-column flex-md-row gap-2">
                      <div className="form-group mb-3 w-100">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control form-control-rounded"
                          name="email"
                          defaultValue={formData.email}
                          readOnly={!editMode}
                          onChange={handleInputChange}
                        />
                      </div>
                      {profile.userType === "brokerage" ? (
                        <div
                          className={`form-group mb-3 w-100 phone-input ${
                            !editMode ? "read-only" : ""
                          }`}
                        >
                          <label>Phone Number</label>
                          <PhoneInput
                            international
                            defaultCountry="US"
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            readOnly={!editMode} // Conditional readOnly state
                          />
                        </div>
                      ) : (
                        <div
                          className={`form-group mb-3 w-100 phone-input ${
                            !editMode ? "read-only" : ""
                          }`}
                        >
                          <label>Phone Number</label>
                          <PhoneInput
                            international
                            defaultCountry="US"
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            readOnly={!editMode} // Conditional readOnly state
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Change Password Section */}
            <div className="card mt-3 rounded-4">
              <div className="card-header border-0">
                <h2 className="text-primary">Change Password</h2>
              </div>
              {error && (
                <div className="text-danger mt-3 text-center">{error}</div>
              )}
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <label className="form-label">Current Password</label>

                    <div className="position-relative mb-3">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control form-control-rounded"
                        placeholder="Current Password"
                        readOnly={!editMode}
                        name="password"
                        value={formPassword.password}
                        onChange={handlePasswordChange}
                      />
                      <div
                        className="position-absolute end-0 top-0 mt-2 me-2 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <IconEyeOff color="gray" />
                        ) : (
                          <IconEye color="gray" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">New Password</label>
                    <div className="position-relative mb-3">
                      <input
                        type={showNewPassword ? "text" : "password"}
                        className="form-control form-control-rounded"
                        placeholder="New Password"
                        readOnly={!editMode}
                        name="newPassword"
                        value={formPassword.newPassword}
                        onChange={handlePasswordChange}
                      />
                      <div
                        className="position-absolute end-0 top-0 mt-2 me-2 cursor-pointer"
                        onClick={toggleNewPasswordVisibility}
                      >
                        {showNewPassword ? (
                          <IconEyeOff color="gray" />
                        ) : (
                          <IconEye color="gray" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Retype Password</label>
                    <div className="position-relative">
                      <input
                        type={showRetypePassword ? "text" : "password"}
                        className="form-control form-control-rounded"
                        placeholder="Retype Password"
                        readOnly={!editMode}
                        name="retypePassword"
                        value={formPassword.retypePassword}
                        onChange={handlePasswordChange}
                      />
                      <span
                        className="position-absolute end-0 top-0 mt-2 me-2 cursor-pointer"
                        onClick={toggleRetypePasswordVisibility}
                      >
                        {showRetypePassword ? (
                          <IconEyeOff color="gray" />
                        ) : (
                          <IconEye color="gray" />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <button
                    className="btn btn-primary btn-pill justify-content-end"
                    disabled={!editMode || isSubmitting}
                    onClick={handlePasswordUpdate}
                  >
                    {isSubmitting ? (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Change Password"
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="card mt-3 rounded-4">
              <div className="card-header border-0 mb-0">
                <h2 className="text-primary">Subscription</h2>
              </div>
              <div className="card-body border border-primary rounded-4 m-3">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="text-secondary">Only</p>
                  <IconCheck />
                </div>
                <h1 className="text-primary">
                  <strong>
                    <sup>$</sup>59/month
                  </strong>
                </h1>
                <h5 className="">+ taxes and board fees for certain boards*</h5>

                <p>
                  Whether you need a single agent website, listings for
                  WordPress or a broker website, myRealpage has a solution to
                  help your business
                </p>

                <div className="text-primary">
                  <p>
                    <IconCheck stroke={2} /> No Contracts
                  </p>
                  <p>
                    <IconCheck stroke={2} /> Cancel Anytime
                  </p>
                  <p>
                    <IconCheck stroke={2} /> 16 hours / 7 days Support
                  </p>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  {/* <button
                    className="btn btn-primary btn-pill justify-content-end"
                    onClick={handlePasswordUpdate}
                  >
                    {isSubmitting ? (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Change Password"
                    )}
                  </button> */}
                  {profile.role !== "superAdmin" && (
                    <a
                      href={`mailto:noreply@realtorr.io`}
                      className="btn btn-primary btn-pill d-flex align-items-center"
                    >
                      <div className="d-flex align-items-center gap-2">
                        Cancel Subscription{" "}
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
