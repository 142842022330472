import {
  IconBuilding,
  IconCirclePlus,
  IconDotsVertical,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteShowcaseApi,
  fetchAllListings,
  fetchAllShowcase,
  fetchIdxProperties,
  fetchShowcase,
  fetchVowProperties,
} from "../actions";
import SelectedListingPreview from "../../../components/Listinngs/SelectedListingPreview";
import ShowCase from "../../../components/Listinngs/ShowCase/showCaseModal";
import UpdateShowCase from "../../../components/Listinngs/ShowCase/viewShowcase";
import { Bounce, toast } from "react-toastify";
import Pagination from "../../../components/Pagination";

const Marketing = () => {
  const dispatch = useDispatch();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isShowCaseOpen, setShowCaseOpen] = useState(false);
  const [updateShowCase, setUpdateShowCase] = useState(false);
  const [exportedListings, setExportedListings] = useState([]);
  const profile = useSelector((state) => state.auth.profile);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedShowcase, setSelectedShowcase] = useState({});
  const [mlsLoading, setMLSLoading] = useState(false);
  const [MLSCards, setMLSCards] = useState([]);

  console.log("selectedShowcase", selectedShowcase);

  const toggleDropdown = (id) => {
    // Toggle dropdown open/close based on the agent ID
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const handleIconClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleDeleteClick = (id) => {
    // setLeadIdToDelete(id);
    setIsConfirmModalOpen(true); // Open confirmation modal
    setDropdownVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsConfirmModalOpen(false);

    // setLeadIdToDelete(null);
  };

  const {
    allListings,
    allShowcase,
    showcases,
    showcaseCurrentPage,
    showcaseTotalPages,
    showcaseTotal,
    loading,
  } = useSelector((state) => state.dashboard);
  useEffect(() => {
    dispatch(fetchAllShowcase());
    dispatch(fetchAllListings(profile.id));
    // dispatch(fetchShowcase(1));
  }, [
    dispatch,
    isShowCaseOpen,
    updateShowCase,
    profile.id,
    isConfirmModalOpen,
  ]);

  console.log("show:", allShowcase);

  useEffect(() => {
    const fetchProperties = async () => {
      setMLSLoading(true); // Set loading to true before the API call
      try {
        let response;
        if (profile.userType === "brokerage") {
          response = await dispatch(
            fetchIdxProperties("ListOfficeKey", profile.mlsNumber)
          );
        } else if (profile.userType === "solo") {
          response = await dispatch(
            fetchVowProperties("ListAgentKey", profile.mlsNumber)
          );
        } else if (profile.mlsNumber) {
          response = await dispatch(
            fetchVowProperties("ListAgentKey", profile.mlsNumber)
          );
          console.log("Response: ", response);
          if (response.length === 0) {
            response = await dispatch(
              fetchIdxProperties("ListOfficeKey", profile.mlsNumber)
            );
          }
        }
        console.log("mls", response);
        setMLSCards(response.value || response); // Use response.value if it exists, else use response directly
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      } finally {
        setMLSLoading(false); // Set loading to false after the API call completes
      }
    };

    fetchProperties();
  }, [dispatch, profile.userType, profile.mlsNumber]);

  const sortedShowcase = [...allShowcase].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const totalShowcase = sortedShowcase?.length || 0;
  const showcasePerPage = 10;
  const totalPages = Math.ceil(totalShowcase / showcasePerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedShowcases = sortedShowcase?.slice(
    (currentPage - 1) * showcasePerPage,
    currentPage * showcasePerPage
  );

  const handlePageChangeShowcase = (page) => {
    setCurrentPage(page);
  };

  const openListingPreviewModal = async (listing) => {
    setExportedListings(allListings);
    setIsPreviewModalOpen(true);
  };
  const openShowCasePreviewModal = async (listing) => {
    // const data = await dispatch(fetchAllListings());
    setExportedListings(allListings);
    // setSelectedListingId(listing);
    setShowCaseOpen(true);
  };
  const openUpdateShowCasePreviewModal = (listing) => {
    // const data = await dispatch(fetchAllListings());
    // setExportedListings(allListings);
    // setSelectedListingId(listing);
    setSelectedShowcase(listing);
    setUpdateShowCase(true);
  };

  const closeListingPreviewModal = () => {
    setIsPreviewModalOpen(false);
  };
  const closeShowCasePreviewModal = () => {
    setShowCaseOpen(false);
  };
  const closeUpdateShowCasePreviewModal = () => {
    setUpdateShowCase(false);
  };

  const showcaseData = [
    { id: "123456", title: "Showcase 01", type: "On the map" },
    { id: "789101", title: "Showcase 02", type: "Virtual Tour" },
    { id: "112131", title: "Showcase 03", type: "Highlight Video" },
  ];

  const handleDeleteConfirm = async () => {
    const response = await dispatch(deleteShowcaseApi(openDropdownId));
    if (response) {
      toast.success("Showcase is deleted", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
    setIsConfirmModalOpen(false);
    setOpenDropdownId(null);
  };

  useEffect(() => {
    // setIsConfirmModalOpen(isOpen);
    if (isConfirmModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isConfirmModalOpen]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= showcaseTotalPages) {
      dispatch(fetchShowcase(newPage)); // Fetch listings for the new page based on status
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (showcaseTotalPages <= 5) {
      // If there are 5 or fewer pages, show all page numbers
      for (let i = 1; i <= showcaseTotalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`pagination-number ${
              showcaseCurrentPage === i ? "active" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show first, last, and up to 2 pages around the current page
      if (showcaseCurrentPage > 2) {
        pageNumbers.push(
          <button
            key={1}
            className={`pagination-number`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        );
      }

      if (showcaseCurrentPage > 3) {
        pageNumbers.push(<span key="dots1">...</span>);
      }

      const startPage = Math.max(2, showcaseCurrentPage - 1);
      const endPage = Math.min(showcaseTotalPages - 1, showcaseCurrentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`pagination-number ${
              showcaseCurrentPage === i ? "active" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }

      if (showcaseCurrentPage < showcaseTotalPages - 2) {
        pageNumbers.push(<span key="dots2">...</span>);
      }

      if (showcaseCurrentPage < showcaseTotalPages - 1) {
        pageNumbers.push(
          <button
            key={showcaseTotalPages}
            className={`pagination-number`}
            onClick={() => handlePageChange(showcaseTotalPages)}
          >
            {showcaseTotalPages}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  // useEffect(() => {
  //   console.log("Updated selectedShowcase:", selectedShowcase);
  // }, [selectedShowcase]);
  return (
    <>
      <SelectedListingPreview
        isOpen={isPreviewModalOpen}
        closeModal={closeListingPreviewModal}
        exportedListings={allListings}
        mls={MLSCards}
      />
      <ShowCase
        isOpen={isShowCaseOpen}
        closeModal={closeShowCasePreviewModal}
        exportedListings={allListings}
        mls={MLSCards}
      />
      <UpdateShowCase
        isOpen={updateShowCase}
        closeModal={closeUpdateShowCasePreviewModal}
        exportedListings={allListings}
        selectedShowcase={selectedShowcase}
        mls={MLSCards}
      />
      {isConfirmModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Showcase</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleDeleteCancel}
                ></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this showcase?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container py-3">
        <div className="card shadow-sm p-4 agent-card rounded-4 mb-3">
          <div className="d-flex justify-content-between align-items-center gap-5">
            <div className="mt-3">
              <h2 className="text-primary">WordPress/ Embedded Link</h2>
            </div>
            <button
              className="btn btn-pill btn-primary d-flex align-items-center gap-2"
              onClick={openListingPreviewModal}
            >
              Create <IconCirclePlus size={20} />
            </button>
          </div>
        </div>
        <div className="card shadow-sm p-4 agent-card rounded-4">
          <div className="d-flex justify-content-between align-items-center gap-5">
            <div className="mt-3">
              <h2 className="text-primary">Showcases</h2>
            </div>
            <button
              className="btn btn-pill btn-primary d-flex align-items-center gap-2"
              onClick={openShowCasePreviewModal}
            >
              Add New Showcase <IconCirclePlus size={20} />
            </button>
          </div>
          {mlsLoading ? (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : paginatedShowcases.length === 0 ? (
            <div className="text-center text-muted mt-4">
              <p>No showcases found. Click "Add New Showcase" to create one.</p>
            </div>
          ) : (
            paginatedShowcases.map((showcase) => (
              <div className="card p-3 rounded-4 mt-3" key={showcase.id}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <div
                      className="avatar rounded-4 cursor-pointer bg-light d-flex align-items-center justify-content-center"
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#F3F4F6",
                      }}
                      onClick={() => openUpdateShowCasePreviewModal(showcase)}
                    >
                      <IconBuilding size={24} />
                    </div>
                    <div
                      onClick={() => openUpdateShowCasePreviewModal(showcase)}
                      className="cursor-pointer "
                    >
                      <h3 className="mb-1">{showcase.title}</h3>
                      <small className="text-muted">
                        Type: {showcase.type} &nbsp; | &nbsp; ID: {showcase.id}
                      </small>
                    </div>
                  </div>
                  <button className="btn btn-link text-muted p-0 position-relative">
                    <IconDotsVertical
                      size={20}
                      onClick={() => toggleDropdown(showcase.id)}
                    />
                  </button>
                  {openDropdownId === showcase.id && (
                    <div
                      className="position-absolute bg-white shadow-sm rounded-3 border"
                      style={{
                        top: "20px",
                        right: "40px",
                      }}
                    >
                      <span
                        className="p-2 d-block text-danger cursor-pointer"
                        onClick={handleDeleteClick}
                      >
                        Delete
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
          {paginatedShowcases.length > 0 && (
            <Pagination
              currentPage={currentPage}
              onPageChange={handlePageChangeShowcase}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Marketing;
