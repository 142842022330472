import {
  IconBath,
  IconBed,
  IconCheck,
  IconClipboard,
  IconFileDescription,
  IconLink,
  IconMapPin,
  IconMapPinFilled,
  IconPhotoScan,
  IconPrinter,
  IconRuler,
  IconShare,
  IconVocabulary,
  IconX,
} from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import preImage1 from "../../../assets/images/pre1.png";
import preImage2 from "../../../assets/images/pre2.png";
import preImage3 from "../../../assets/images/pre3.png";
import preImage4 from "../../../assets/images/pre4.png";
import preImage5 from "../../../assets/images/pre5.png";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Schedule from "./Schedule";
import L from "leaflet";
// import Mortgage from "./Mortgage";
import { Link } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchUserById } from "../../../modules/dashboard/actions";
import ProfileCard from "./ProfileCard";
import { LOCATION_URL } from "../../../config/config";

const customIcon = new L.DivIcon({
  className: "custom-tabler-icon",
  html: ReactDOMServer.renderToString(
    <IconMapPinFilled size={24} color="#1655FB" />
  ),
  iconSize: [24, 24],
  iconAnchor: [12, 24],
});

// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const BasicSection = ({ selectedListing, listings, user }) => {
  const [listingLink, setListingLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [userId, setUserId] = useState(user || "");
  const [userProfile, setUserProfile] = useState({});
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]); // Default center
  const [displayEmail, setDisplayEmail] = useState(false);
  const [displayPhone, setDisplayPhone] = useState(false);

  const handleEmailToggle = () => setDisplayEmail(!displayEmail);
  const handlePhoneToggle = () => setDisplayPhone(!displayPhone);
  const googleApiKey = LOCATION_URL;
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    // Get the current URL from the browser
    if (!user) {
      const urlParams = new URLSearchParams(window.location.search);
      const userIdParam = urlParams.get("userId");
      const emailParam = urlParams.get("email");
      const phoneParam = urlParams.get("phone");
      setUserId(userIdParam);
      setDisplayEmail(emailParam === "true");
      setDisplayPhone(phoneParam === "true");
      const fetchUser = async () => {
        try {
          const response = await dispatch(fetchUserById(userIdParam));

          if (response) {
            setUserProfile(response);
          }
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      };
      fetchUser();
    }
  }, [user, dispatch]);

  useEffect(() => {
    const selected = listings.find((listing) => listing.id === selectedListing);
    if (selected) {
      geocodeAddress(selected.address);
    }
  }, [selectedListing, listings]);

  useEffect(() => {
    // Update the map center if the map instance and mapCenter are available
    if (mapRef.current) {
      const map = mapRef.current;
      map.setView(mapCenter, 13); // Set zoom level here if needed
    }
  }, [mapCenter]);

  const geocodeAddress = async (address) => {
    const fullAddress = `${address.number || ""} ${
      address.streetNumber || ""
    } ${address.streetName || ""} ${address.streetType || ""} ${
      address.streetDirection || ""
    }, ${address.city || ""}, ${address.state || ""}, ${
      address.country || ""
    }, ${address.postalCode || ""}`;

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address: fullAddress,
            key: googleApiKey,
          },
        }
      );
      if (response.data.results[0]) {
        const location = response.data.results[0].geometry.location;
        setMapCenter([location.lat, location.lng]);
        console.log(mapCenter);
      } else {
        console.error(
          "Geocode was not successful for the following reason:",
          response.data.status
        );
      }
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };

  const generateListingLink = (listingId) => {
    return `${window.location.origin}/listing/${listingId}?userId=${userId}&email=${displayEmail}&phone=${displayPhone}`;
  };

  const handleShareListing = () => {
    const link = generateListingLink(selectedListing);
    setListingLink(link);
    setIsModalOpen(true);
  };

  const handleCopyLinkFallback = () => {
    const textArea = document.createElement("textarea");
    textArea.value = listingLink;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      setCopied(true);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textArea);
  };

  const handleCopyLink = () => {
    console.log("Attempting to copy the link..."); // Debugging log
    if (navigator.clipboard) {
      navigator.clipboard.writeText(listingLink).then(
        () => {
          console.log("Link copied successfully"); // Success log
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 3000);
        },
        (err) => {
          console.error("Failed to copy using Clipboard API: ", err);
          handleCopyLinkFallback(); // Fallback for unsupported cases
        }
      );
    } else {
      handleCopyLinkFallback(); // Fallback for older browsers
    }
  };

  const formatListedDate = (dateString) => {
    const listedDate = new Date(dateString);
    const currentDate = new Date();

    // Set both dates to midnight to ignore time differences
    listedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    // Calculate the days difference, ensuring no negative values
    const timeDifference = currentDate - listedDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert ms to days

    // Ensure no negative daysAgo by returning 0 if timeDifference is negative
    const daysAgoFinal = timeDifference < 0 ? 0 : daysAgo;

    return daysAgoFinal === 0
      ? "Listed today"
      : daysAgoFinal === 1
      ? "Listed 1 day ago"
      : `Listed ${daysAgoFinal} days ago`;
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      {listings.map((listing) => (
        <div className="" key={listing.id}>
          {listing.id === selectedListing && (
            <div className="row">
              {/* Left Section */}
              <div className="col-lg-12">
                {/* Property Images */}
                <div className="image-grid">
                  {listing.images.slice(0, 5).map((image, index) => (
                    <div
                      key={index}
                      className={`image-item ${
                        index === 0 ? "main-image" : "thumbnail"
                      }`}
                    >
                      <img
                        src={image.imageUrl || preImage1}
                        alt={`Image ${index + 1}`}
                        className="image"
                      />
                      {index === 4 && listing.images.length > 5 && (
                        <div className="overlay">
                          <span>+{listing.images.length - 5}</span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-8">
                {/* Property Details */}
                <div className="mb-3">
                  <div className="card-body">
                    <h1 className="card-title h1">
                      <strong>{listing.title && listing.title}</strong>
                    </h1>
                    <p className="h3 text-capitalize">
                      {listing.propertyType && listing.propertyType}
                    </p>
                    <p className="h3 text-capitalize">
                      MLS - {listing.mlsNumber && listing.mlsNumber}
                    </p>
                    <p>
                      For {listing.propertyCategory && listing.propertyCategory}{" "}
                      <div className="ms-2 border-start p-2">
                        {listing.listedDate &&
                          formatListedDate(listing.listedDate)}
                      </div>
                    </p>
                    <p>
                      <IconMapPin className="me-2" />
                      {listing.address.fullAddress &&
                        listing.address.fullAddress}
                    </p>
                    <h3>
                      {listing.currency && listing.currency}{" "}
                      {listing.price && Number(listing.price).toLocaleString()}{" "}
                      / Per Sq Ft
                    </h3>
                    <p>
                      <IconPhotoScan />
                      <Link
                        to={
                          listing.buildingInfo.externalUrl &&
                          listing.buildingInfo.externalUrl
                        }
                        className="ms-2 text-black"
                        target="_blank"
                      >
                        Virtual Tour
                      </Link>
                    </p>
                    <p>{listing.description && listing.description}</p>

                    {/* Property Info */}
                    <div className="d-flex flex-column flex-md-row  my-4 border pt-3 pb-2 rounded-3 me-2">
                      <div className="col ms-2 ps-2">
                        <p>Year Built</p>
                        <h5>
                          {listing.buildingInfo.year &&
                            listing.buildingInfo.year}
                        </h5>
                      </div>
                      <div className="col border-md-start ms-2 ps-2">
                        <p>Living Area</p>
                        <h5>
                          <IconRuler className="me-2" />
                          {listing.buildingInfo.livingArea &&
                            listing.buildingInfo.livingArea}
                        </h5>
                      </div>
                      <div className="col border-md-start ms-2 ps-2">
                        <p>Lot Area</p>
                        <h5>
                          <IconRuler className="me-2" />
                          {listing.buildingInfo.lotArea &&
                            listing.buildingInfo.lotArea}
                        </h5>
                      </div>
                      <div className="col border-md-start ms-2 ps-2">
                        <p>Bedrooms</p>
                        <h5>
                          <IconBed className="me-2" />
                          {listing.buildingInfo.bedroom &&
                            listing.buildingInfo.bedroom}
                        </h5>
                      </div>
                      <div className="col border-md-start ms-2 ps-2">
                        <p>Bathrooms</p>
                        <h5>
                          <IconBath className="me-2" />
                          {listing.buildingInfo.bathroom &&
                            listing.buildingInfo.bathroom}
                        </h5>
                      </div>
                    </div>

                    {/* Special Features */}
                    <div className="mb-4 me-2">
                      <h4>Special Features</h4>
                      <div className="row border pt-3 px-2 rounded-3">
                        {listing.features &&
                          listing.features.map((feature) => (
                            <div className="col-12 d-flex gap-2">
                              <strong>{feature.name && feature.name}</strong>
                              <p>
                                {feature.description && feature.description}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* Room Details */}
                    <div className="table-responsive mb-4">
                      <h4>Room</h4>
                      <table className="table border">
                        <thead>
                          <tr>
                            <th className="text-capitalize">Floor</th>
                            <th className="text-capitalize">Type</th>
                            <th className="text-capitalize">Size</th>
                            <th className="text-capitalize">Other</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listing.rooms &&
                            listing.rooms.map((room) => (
                              <tr>
                                <td>{room.level && room.level}</td>
                                <td className="text-secondary">
                                  {room.name && room.name}
                                </td>
                                <td className="text-secondary">
                                  {room.width && room.width} x{" "}
                                  {room.length && room.length}
                                </td>
                                <td className="text-secondary">
                                  {room.info && room.info}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Bathroom Details */}
                    <div className="mb-4">
                      <h4>Bathroom</h4>
                      <table className="table border rounded-4">
                        <thead className="">
                          <tr className="">
                            <th className="text-capitalize">Floor</th>
                            <th className="text-capitalize">Ensuite</th>
                            <th className="text-capitalize">Pieces</th>
                            <th className="text-capitalize">Other</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listing.bathrooms &&
                            listing.bathrooms.map((bathroom) => (
                              <tr>
                                <td>{bathroom.level && bathroom.level}</td>
                                <td className="text-secondary">
                                  {bathroom.ensuit && bathroom.ensuit
                                    ? "Yes"
                                    : "-"}
                                </td>
                                <td className="text-secondary">
                                  {bathroom.pieces && bathroom.pieces}
                                </td>
                                <td className="text-secondary">
                                  {bathroom.info && bathroom.info}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Document and Floor Plans */}
                    <div className="row mb-4">
                      <div className="col-lg-6 mb-4">
                        <h4>Document</h4>
                        <div className="list-unstyled border p-1 rounded-3">
                          {listing.documents &&
                            listing.documents.map((document) => (
                              <li className="d-flex justify-content-between p-2">
                                <div>
                                  <IconFileDescription size={16} />{" "}
                                  {document.name && document.name}
                                </div>
                                <div className="d-flex gap-4">
                                  <div className="me-5">PDF</div>
                                  <Link
                                    to={
                                      document.documentUrl &&
                                      document.documentUrl
                                    }
                                    className="text-black"
                                    target="_blank"
                                  >
                                    View
                                  </Link>
                                </div>
                              </li>
                            ))}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h4>Floor Plans</h4>
                        <div className="list-unstyled border p-1 rounded-3">
                          {listing.floorPlans.map((floorPlan) => (
                            <li className="d-flex justify-content-between p-2">
                              <div>
                                <IconVocabulary size={16} />{" "}
                                {floorPlan.name && floorPlan.name}
                              </div>
                              <div className="d-flex gap-4">
                                <div className="me-5">PDF</div>
                                <Link
                                  to={
                                    floorPlan.documentUrl &&
                                    floorPlan.documentUrl
                                  }
                                  className="text-black"
                                  target="_blank"
                                >
                                  View
                                </Link>
                              </div>
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* External Links */}
                    <div className="mb-4">
                      <h4>External Links</h4>
                      <div className="list-unstyled border p-1 rounded-3">
                        <li className="d-flex justify-content-between p-2">
                          <div>
                            <IconLink size={16} />{" "}
                            {listing.externalLinks.name &&
                              listing.externalLinks.name}
                          </div>
                          <Link
                            to={
                              listing.externalLinks.link &&
                              listing.externalLinks.link
                            }
                            className="text-black"
                            target="_blank"
                          >
                            View
                          </Link>
                        </li>
                        {/* <li className="d-flex justify-content-between p-2 border-top">
                          <div>
                            <IconLink size={16} /> External link 02
                          </div>
                          <a href="#" className="text-black">
                            View
                          </a>
                        </li> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Section */}
              <div className="col-lg-4">
                {/* Schedule a Viewing */}
                <div className="d-flex align-items-center gap-2 mb-3">
                  <IconPrinter size={65} />
                  <button
                    className="btn btn-black-outline w-100"
                    onClick={handleShareListing}
                  >
                    Share Listing <IconShare size={20} className="ms-1" />
                  </button>
                  <button className="btn btn-black w-100">Send Listing</button>
                </div>

                {user && <><div className="d-flex align-items-center mt-2 mb-3 justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <div className="d-flex align-items-center">
                      Display Email
                    </div>
                  </div>
                  <div className="ml-auto">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={displayEmail}
                        onChange={handleEmailToggle}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2 mb-3 justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <div className="d-flex align-items-center">
                      Display Phone
                    </div>
                  </div>
                  <div className="ml-auto">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={displayPhone}
                        onChange={handlePhoneToggle}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div></>}

                {!user && <ProfileCard profile={userProfile} email={displayEmail} phone={displayPhone}/>}

                <MapContainer
                  center={mapCenter}
                  ref={mapRef}
                  zoom={13}
                  style={{
                    height: "150px",
                    width: "100%",
                    marginBottom: "3px",
                  }}
                  className="mb-4 rounded-3"
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={mapCenter} icon={customIcon}>
                    <Popup>{listing.address.fullAddress}</Popup>
                  </Marker>
                </MapContainer>

                {/* schedule */}
                <Schedule listingID={listing.id} userId={userId} />

                {/* Calculate Mortgage */}
                {/* <Mortgage /> */}
              </div>
            </div>
          )}
        </div>
      ))}

      {isModalOpen && (
        <div className="custom-modal">
          <div>
            <div className="custom-modal-content">
              <div className="d-flex justify-content-between">
                <h3 className="">Share Listing Link</h3>
                <IconX
                  onClick={closeModal}
                  color="gray"
                  className="cursor-pointer"
                />
              </div>
              <div className="custom-modal-body">
                <div className="position-relative mb-3 mt-3">
                  <input
                    type="text"
                    className="form-control form-control-rounded pe-5"
                    value={listingLink}
                    readOnly
                  />
                  <div
                    className="position-absolute end-0 top-0 mt-2 me-2"
                    onClick={handleCopyLink}
                    style={{ cursor: "pointer", marginTop: "0.5rem" }}
                  >
                    {copied ? <IconCheck color="green" /> : <IconClipboard />}
                  </div>
                  {copied && (
                    <div
                      className="tooltip-text"
                      style={{
                        position: "absolute",
                        top: "-30px",
                        right: "0",
                        backgroundColor: "black",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                    >
                      Copied!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicSection;
