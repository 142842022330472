import React, { useState } from "react";
import AuthLayout from "../../../components/AuthLayout";
import { IconChevronLeft, IconEye, IconEyeOff } from "@tabler/icons-react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { restPassword } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const enteredEmail = location.state;
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    email: enteredEmail,
    password: "",
    retypePassword: "",
  });
  const token = useSelector((state) => state.auth.token);
  console.log("token", token);

  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setRetypeShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRetypePasswordVisibility = () => {
    setRetypeShowPassword(!showRetypePassword);
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.password || !formData.retypePassword) {
      return setError("Please fill out all fields.");
    }
    if (formData.password !== formData.retypePassword) {
      return setError("Passwords do not match");
    }

    if (!validatePassword(formData.password)) {
      return setError(
        "Password must be at least 6 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character."
      );
    }

    try {
      await dispatch(
        restPassword({
          password: formData.retypePassword,
          token,
        })
      );
      navigate("/");
      toast.success('Passwords is changed.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred");
    }
  };

  console.log(formData);

  return (
    <AuthLayout>
      <Link to="../email-verification" className="back-button m-3 h5 mb-4">
        <div className="d-flex align-items-center">
          <IconChevronLeft />
          Back
        </div>
      </Link>
      <div className="container container-tight my-5 px-5">
        <div className="text-center mb-4 mt-4">
          <h2 className="h1 text-center mb-3">
            <strong>Reset Password</strong>
          </h2>
        </div>
        <p className="text-center mb-3">Please enter your new password</p>
        <form
          action="./"
          method="get"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
           {error && <div className="text-danger mt-3 text-center">{error}</div>}
          <div className="mb-2">
            <label className="form-label">Password</label>
            <div className="position-relative">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control form-control-rounded"
                placeholder="New Password"
                autoComplete="off"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <div
                className="position-absolute end-0 top-0 mt-2 me-2"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <IconEye color="gray" />
                ) : (
                  <IconEyeOff color="gray" />
                )}
              </div>
            </div>
          </div>
          <div className="mb-6">
            <label className="form-label">Retype Password</label>
            <div className="input-icon">
              <input
                type={showRetypePassword ? "text" : "password"}
                className="form-control form-control-rounded"
                placeholder="Retype Password"
                autoComplete="off"
                name="retypePassword"
                value={formData.retypePassword}
                onChange={handleChange}
              />
              <div
                className="position-absolute end-0 top-0 mt-2 me-2"
                onClick={toggleRetypePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showRetypePassword ? (
                  <IconEye color="gray" />
                ) : (
                  <IconEyeOff color="gray" />
                )}
              </div>
            </div>
          </div>

          <div className="form-footer mt-8">
            <button type="submit" className="btn btn-primary btn-pill w-100">
              Change Password
            </button>
          </div>
        </form>
       
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
