import React, { useEffect, useRef, useState } from "react";
import listingImage from "../../../assets/images/login-cover.png";
import preImage1 from "../../../assets/images/pre1.png";
import Mortgage from "../Preview/Mortgage";
import ProfileCard from "../Preview/ProfileCard";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Schedule from "./../Preview/Schedule";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMlsMedia,
  fetchUserById,
} from "../../../modules/dashboard/actions";
import {
  IconCheck,
  IconClipboard,
  IconMapPin,
  IconMapPinFilled,
  IconPhotoScan,
  IconPrinter,
  IconShare,
  IconX,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import Loader from "../../Loader";
import { LOCATION_URL } from "../../../config/config";

const customIcon = new L.DivIcon({
  className: "custom-tabler-icon",
  html: ReactDOMServer.renderToString(
    <IconMapPinFilled size={24} color="#1655FB" />
  ),
  iconSize: [24, 24],
  iconAnchor: [12, 24],
});

const MlsImages = ({ selectedListing, listings, user }) => {
  const profile = useSelector((state) => state.auth.profile);
  const [listingLink, setListingLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [userId, setUserId] = useState(user || "");
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]); // Default center
  const [displayEmail, setDisplayEmail] = useState(false);
  const [displayPhone, setDisplayPhone] = useState(false);
  const [time, setTime] = useState("");

  const handleEmailToggle = () => setDisplayEmail(!displayEmail);
  const handlePhoneToggle = () => setDisplayPhone(!displayPhone);
  const googleApiKey = LOCATION_URL;
  const mapRef = useRef(null);
  const [userProfile, setUserProfile] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const now = new Date().toISOString();
    setTime(now.split(".")[0] + "Z"); // Remove milliseconds
  }, []);

  useEffect(() => {
    // Get the current URL from the browser
    if (!user) {
      const urlParams = new URLSearchParams(window.location.search);
      const userIdParam = urlParams.get("userId");
      const emailParam = urlParams.get("email");
      const phoneParam = urlParams.get("phone");
      console.log("User ID:", userIdParam);
      console.log("Email:", emailParam);
      console.log("Phone:", phoneParam);
      setUserId(userIdParam);
      setDisplayEmail(emailParam === "true");
      setDisplayPhone(phoneParam === "true");
      console.log("Display Email:", displayEmail);
      console.log("Display Phone:", displayPhone);
      const fetchUser = async () => {
        try {
          const response = await dispatch(fetchUserById(userIdParam));

          if (response) {
            setUserProfile(response);
          }
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      };
      fetchUser();
    }
  }, [user, dispatch]);

  const [mls, setMls] = useState("");
  const [userType, setUserType] = useState("");
  const [listing, setListing] = useState({});
  const [imageUrl, setImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currency, setCurrency] = useState("$");

  const getCurrencyFromAddress = (address) => {
    if (address.includes("ON") || address.includes("Canada")) {
      return "$"; // Canadian Dollar
    } else if (address.includes("St. Catharines")) {
      return "$"; // Specific city in Canada
    } else if (address.includes("US") || address.includes("United States")) {
      return "$"; // US Dollar
    }
    // Add more rules as needed
    return "$"; // Default to USD
  };

  useEffect(() => {
    // Get the current URL from the browser
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get("userId");
    const mlsParam = urlParams.get("mls");
    const userTypeParam = urlParams.get("type");
    setUserId(userIdParam);
    setMls(mlsParam);
    setUserType(userTypeParam);
  }, []);

  useEffect(() => {
    const selected = listings.find(
      (listing) => listing.ListingKey === selectedListing
    );
    if (selected) {
      geocodeAddress(selected);
      setListing(selected);
    }
  }, [selectedListing, listings]);

  console.log(selectedListing);
  console.log(listings);

  useEffect(() => {
    // Update the map center if the map instance and mapCenter are available
    if (mapRef.current) {
      const map = mapRef.current;
      map.setView(mapCenter, 13); // Set zoom level here if needed
    }
  }, [mapCenter]);

  useEffect(() => {
    const fetchImage = async () => {
      if (listing) {
        setLoading(true);
        try {
          const response = await dispatch(
            fetchMlsMedia(time, listing.ListingKey)
          );
          console.log("Fetched images:", response);

          if (response && Array.isArray(response) && response.length > 0) {
            // Filter for valid media items
            const activeMediaUrls = response
              .filter(
                (item) =>
                  item?.MediaURL &&
                  item?.MediaStatus === "Active" &&
                  item?.MediaType === "image/jpeg"
              )
              .map((item) => item.MediaURL);

            if (activeMediaUrls.length > 0) {
              setImageUrl(activeMediaUrls); // Store all MediaURLs
            } else {
              setImageUrl([]); // Default fallback
            }
          } else {
            setImageUrl([]); // Default fallback
          }
        } catch (error) {
          console.error("Failed to fetch images:", error);
          setImageUrl([]); // Default fallback
        } finally {
          setLoading(false);
        }
      } else {
        setImageUrl([listingImage]); // Default fallback
      }
    };

    fetchImage();

    if (listing?.UnparsedAddress) {
      const detectedCurrency = getCurrencyFromAddress(listing.UnparsedAddress);
      setCurrency(detectedCurrency);
    }
  }, [dispatch, listing, time]);

  const geocodeAddress = async (listing) => {
    const fullAddress = `${
      listing.UnitNumber ? `#${listing.UnitNumber} - ` : ""
    }${listing.StreetNumber || ""} ${listing.StreetName || ""} ${
      listing.StreetSuffix || ""
    }, ${listing.City || ""}, ${listing.StateOrProvince || ""}, ${
      listing.Country || ""
    }, ${listing.PostalCode || ""}`;
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address: fullAddress,
            key: googleApiKey,
          },
        }
      );
      if (response.data.results[0]) {
        const location = response.data.results[0].geometry.location;
        setMapCenter([location.lat, location.lng]);
        console.log(mapCenter);
      } else {
        console.error(
          "Geocode was not successful for the following reason:",
          response.data.status
        );
      }
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };

  const generateListingLink = (listingId) => {
    const mlsParam = profile?.mlsNumber || mls || ""; // Ensure it uses mls if profile.mlsNumber is not available
    const user = profile?.id || userId || "";
    return `${
      window.location.origin
    }/listingMLS/${listingId}?userId=${user}&mls=${mlsParam}&type=${
      profile?.userType || userType
    }&email=${displayEmail}&phone=${displayPhone}`;
  };

  const handleShareListing = () => {
    const link = generateListingLink(selectedListing);
    setListingLink(link);
    setIsModalOpen(true);
  };

  const handleCopyLinkFallback = () => {
    const textArea = document.createElement("textarea");
    textArea.value = listingLink;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      setCopied(true);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textArea);
  };

  const handleCopyLink = () => {
    console.log("Attempting to copy the link..."); // Debugging log
    if (navigator.clipboard) {
      navigator.clipboard.writeText(listingLink).then(
        () => {
          console.log("Link copied successfully"); // Success log
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 3000);
        },
        (err) => {
          console.error("Failed to copy using Clipboard API: ", err);
          handleCopyLinkFallback(); // Fallback for unsupported cases
        }
      );
    } else {
      handleCopyLinkFallback(); // Fallback for older browsers
    }
  };

  const formatListedDate = (dateString) => {
    const listedDate = new Date(dateString);
    const currentDate = new Date();

    // Set both dates to midnight to ignore time differences
    listedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    // Calculate the days difference, ensuring no negative values
    const timeDifference = currentDate - listedDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert ms to days

    // Ensure no negative daysAgo by returning 0 if timeDifference is negative
    const daysAgoFinal = timeDifference < 0 ? 0 : daysAgo;

    return daysAgoFinal === 0
      ? "Listed today"
      : daysAgoFinal === 1
      ? "Listed 1 day ago"
      : `Listed ${daysAgoFinal} days ago`;
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const cleanAddress = (address) => {
    return address
      .replace(/#/g, "") // Remove #
      .replace(/-\s*/g, "") // Remove - and any trailing spaces
      .replace(/,\s*ON.*/g, "") // Remove ", ON" and everything after it
      .trim(); // Trim extra spaces
  };
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  if (loading) {
    return (
      <Loader/>
    );
  }

  return (
    <div>
      {listings.map((listing) => (
        <div key={listing.ListingKey}>
          {listing.ListingKey === selectedListing && (
            <div className="d-flex flex-column flex-lg-row gap-4" >
              {/* Image section */}
              <div style={{
      flex: 1,
      maxHeight: "100vh", // Adjust as needed
      overflowY: "auto",
      paddingRight: "10px", // Optional for scrollbar space
    }}>
                {/* Main Image */}
                {
                  <>
                    {imageUrl && imageUrl > 0 && (
                      <img
                        src={imageUrl[0]}
                        alt="Main Property"
                        className="main-image mb-3"
                      />
                    )}

                    {/* Thumbnails */}
                    <div className="image-gallery d-flex flex-wrap gap-3">
                      {imageUrl &&
                        imageUrl
                          .slice(0)
                          .map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Thumbnail ${index + 1}`}
                              className="gallery-image rounded-3"
                            />
                          ))}
                    </div>
                  </>
                }
              </div>
                {/* property info */}
              <div className="property-info" style={{
      
      overflow: "hidden",
      position: "sticky",
       // Keeps it at the top of the viewport
    }}>
                <h1 className="card-title h1">
                  <strong>
                    {listing.UnparsedAddress &&
                      cleanAddress(listing.UnparsedAddress)}
                  </strong>
                </h1>
                <h3 className="text-capitalize mb-2">
                  {listing.PropertyType || "Property"} |{" "}
                  {listing.MlsStatus || "Status"} |{" "}
                  {listing.PropertySubType || "N/A"} |{" "}
                  {listing.ArchitecturalStyle?.[0] &&
                    listing.ArchitecturalStyle?.[0]}
                </h3>
                <h4 className="mb-3">MLS - {listing.ListingKey || "N/A"}</h4>
                <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                  {/* <span
                            className={`status ${listing.StandardStatus?.toLowerCase()} text-capitalize text-white`}
                          >
                            {listing.StandardStatus || "For Sale"}
                          </span>
                          | */}
                  <span className="text-black">
                    {listing.TransactionType || "Transaction Type"}
                  </span>
                  |
                  <span className="text-black">
                    {listing.OriginalEntryTimestamp &&
                      formatListedDate(listing.OriginalEntryTimestamp)}
                  </span>
                </div>
                <p>
                  <IconMapPin className="me-2" />
                  {listing.UnparsedAddress && listing.UnparsedAddress}
                </p>
                <h2 className="">
                  {currency}
                  {listing.ListPrice &&
                    Number(listing.ListPrice).toLocaleString()}{" "}
                  <strong className="">/Sq Ft</strong>
                </h2>

                <div className="d-flex align-items-center gap-2 mb-3">
                  <button
                    className="btn btn-black-outline w-100"
                    onClick={handleShareListing}
                  >
                    Share Listing <IconShare size={20} className="ms-1" />
                  </button>
                  <button className="btn btn-black w-100">Send Lsting</button>
                  <IconPrinter size={65} />
                </div>

                {!user && (
                  <ProfileCard
                    profile={userProfile}
                    email={displayEmail}
                    phone={displayPhone}
                  />
                )}

                <div className="map-view">
                  <MapContainer
                    center={mapCenter}
                    ref={mapRef}
                    zoom={13}
                    style={{
                      height: "150px",
                      width: "100%",
                      marginBottom: "3px",
                    }}
                    className="mb-4 rounded-3"
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={mapCenter} icon={customIcon}>
                      <Popup>{listing.UnparsedAddress}</Popup>
                    </Marker>
                  </MapContainer>
                </div>

                {/* schedule */}
                <Schedule listingID={selectedListing} userId={userId} />

                {/* Calculate Mortgage */}
                {/* <Mortgage /> */}
              </div>
            </div>
          )}
        </div>
      ))}
      {isModalOpen && (
        <div className="custom-modal">
          <div>
            <div className="custom-modal-content">
              <div className="d-flex justify-content-between">
                <h3 className="">Share Listing Link</h3>
                <IconX
                  onClick={closeModal}
                  color="gray"
                  className="cursor-pointer"
                />
              </div>
              <div className="custom-modal-body">
                <div className="position-relative mb-3 mt-3">
                  <input
                    type="text"
                    className="form-control  form-control-rounded pe-5"
                    value={listingLink}
                    readOnly
                  />
                  <div
                    className="position-absolute end-0 top-0 mt-2 me-2"
                    onClick={handleCopyLink}
                    style={{ cursor: "pointer", marginTop: "0.5rem" }}
                  >
                    {copied ? <IconCheck color="green" /> : <IconClipboard />}
                  </div>
                  {copied && (
                    <div
                      className="tooltip-text"
                      style={{
                        position: "absolute",
                        top: "-30px",
                        right: "0",
                        backgroundColor: "black",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                    >
                      Copied!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MlsImages;
