import {
  IconCirclePlus,
  IconGripVertical,
  IconTrash,
} from "@tabler/icons-react";
import React, { useRef, useState } from "react";
import {
  uploadImageApi,
  downloadImageApi,
} from "../../../modules/dashboard/actions";
import { useDispatch } from "react-redux";
import { getDownloadUrl } from "../../../config/config"; // Import the utility function
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { set } from "date-fns";

const Images = ({ formData, setFormData }) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState(formData.images || []); // Initialize with form data images if available
  const imageInputRef = useRef(null);
  const [error, setError] = useState("");

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    const uploadedImages = [];
  
    for (const file of files) {
      try {
        // Attempt to upload the image
        const uploadResponse = await dispatch(uploadImageApi(file));
        if (!uploadResponse) throw new Error("Upload failed");
  
        // Extract file path and name from the upload response
        const filePath = new URL(uploadResponse).pathname.substring(1);
        const fileName = uploadResponse.substring(
          uploadResponse.lastIndexOf("/") + 1
        );
  
        // Construct the download URL
        const downloadUrl = getDownloadUrl(fileName);
  
        // Verify the download URL is valid
        if (!downloadUrl) throw new Error("Invalid download URL");
  
        // Call the download API to verify the file path
        const response = await dispatch(downloadImageApi(filePath));
        if (!response?.filePath) throw new Error("Error downloading image");
  
        // Add the successfully uploaded image to the list
        uploadedImages.push({
          imageId: file.name, // Image ID can be the file name or a unique identifier
          imageUrl: downloadUrl, // Use the constructed URL
        });
  
        setError(""); // Clear any previous error
      } catch (error) {
        console.error(`Error uploading image: ${file.name}`, error.message);
        setError(`Image size should be less than 2MB`);
      }
    }
  
    if (uploadedImages.length > 0) {
      // Update formData with the successfully uploaded images
      setFormData({
        ...formData,
        images: [...(formData.images || []), ...uploadedImages],
      });
  
      // Update state to display images in real-time
      setImages((prevImages) => [...prevImages, ...uploadedImages]);
    }
  
    // Reset the input field to allow re-uploading
    event.target.value = null;
  };
  

  const handleImageButtonClick = () => {
    imageInputRef.current.click();
  };

  const handleRemoveImage = (imageId) => {
    const updatedImages = images.filter((image) => image.imageId !== imageId);
    setImages(updatedImages);
    setFormData({
      ...formData,
      images: updatedImages,
    });
  };

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = images.findIndex((image) => image.imageId === active.id);
      const newIndex = images.findIndex((image) => image.imageId === over.id);
      const reorderedImages = arrayMove(images, oldIndex, newIndex);

      setImages(reorderedImages);
      setFormData({ ...formData, images: reorderedImages });
    }
  };

  const SortableImage = ({ image, index }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({
        id: image.imageId,
      });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style} className="position-relative">
        <img
          src={image.imageUrl}
          alt={image.imageId}
          className="me-3 rounded-4 object-fit-cover"
          style={{ width: "180px", height: "150px" }}
        />
        <div className="position-absolute bottom-0 end-0 me-4 mb-2">
          <button
            className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
            onClick={() => handleRemoveImage(image.imageId)}
          >
            <IconTrash size={17} />
          </button>
        </div>
        <div className="position-absolute top-0 end-0 mt-2 me-4 mb-2">
          <div className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center">
            <strong>{index + 1}</strong>
          </div>
        </div>
        {/* Make IconGripVertical the only draggable handle */}
        <div
          {...attributes}
          {...listeners}
          className="position-absolute top-50 start-0 translate-middle-y ms-2"
          style={{ zIndex: 10, cursor: "grab" }} // Add cursor style for better UX
        >
          <div className="bg-white px-0 rounded-3 border-0 d-flex align-items-center justify-content-center">
            <IconGripVertical size={30} />
          </div>
        </div>
      </div>
    );
  };

  console.log("Current Images:", images);

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="text-primary">Images</h2>
        </div>
        {error && <div className="text-danger mt-3 text-center">{error}</div>}

        <div className="mb-2">
          <button
            type="button"
            className="btn btn-primary btn-pill"
            onClick={handleImageButtonClick}
          >
            Upload
            <IconCirclePlus className="ms-1" />
          </button>
          <input
            type="file"
            accept="image/*"
            multiple
            className="form-control d-none"
            ref={imageInputRef}
            onChange={handleImageUpload}
          />
        </div>
      </div>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={images.map((image) => image.imageId)}
          strategy={horizontalListSortingStrategy}
        >
          <div className="d-flex flex-wrap gap-2 mt-1">
            {images.map((image, index) => (
              <SortableImage key={image.imageId} image={image} index={index} />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </>
  );
};

export default Images;
