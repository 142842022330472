import { IconCirclePlus, IconEdit, IconPlus } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import Basic from "./Basic";
import Other from "./Other";
import Share from "./Share";
import UpdateLead from "./UpdateLead";

const ViewLead = ({ isOpen, closeModel, lead, listings, mls }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Basic Info");
  const [isEditing, setIsEditing] = useState(false);
  const tabs = ["Basic Info", "Other Info"];

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen]);

  console.log("lead", lead);

  const renderContent = () => {
    if (isEditing) {
      // Render the form if isEditing is true
      return (
        <UpdateLead
          lead={lead}
          setIsEditing={setIsEditing}
          closeModel={closeModel}
        />
      );
    }
    switch (activeTab) {
      case "Basic Info":
        return <Basic lead={lead} />;
      case "Other Info":
        return <Other lead={lead} listings={listings} mls={mls}/>;
      case "Share":
        return <Share />;
      default:
        return <Basic />;
    }
  };
  return (
    <div>
      {isModalOpen && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="">
                <button
                  type="button"
                  className="btn-close mt-2 me-4"
                  onClick={closeModel}
                  aria-label="Close"
                ></button>
              </div>
              {/* {error && <div className="text-danger mt-3 text-center">{error}</div>} */}
              <div className="modal-body text-black">
                <div className="profile-card">
                  {!isEditing && (
                    <>
                      <div className="card-header mb-4 mt-4">
                        <div className="d-flex align-items-center">
                          <div className="avatar avatar-lg bg-gray-500 rounded-4 me-3">
                            <span className="fs-3 fw-bold text-dark">
                              {lead.firstName && lead.firstName.charAt(0)}
                            </span>
                          </div>
                          <div className="d-flex flex-column justify-content-start gap-1">
                            <strong>
                              {lead.firstName && lead.firstName}{" "}
                              {lead.lastName && lead.lastName}
                            </strong>
                            <button className="btn btn-primary btn-pill btn-sm">
                              <div className="d-flex align-items-center gap-1">
                                {/* <IconPlus size={16} /> */}
                                <div className="ms-1">{lead.label && lead.label}</div>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="card-actions ">
                          <button
                            className="btn btn-primary btn-pill"
                            onClick={() => setIsEditing(true)}
                          >
                            <div className="d-flex align-items-center gap-1">
                              <div className="ms-1">Edit</div>
                              <IconEdit size={16} />
                            </div>
                          </button>
                          {/* <button className="btn btn-outline-primary btn-pill ms-2">
                            <div className="d-flex align-items-center gap-1">
                              <div className="ms-1">Add</div>
                              <IconCirclePlus size={16} />
                            </div>
                          </button> */}
                        </div>
                      </div>

                      <div className="bg-white rounded-4 border">
                        <div className="border-0 d-flex justify-content-between">
                          {tabs.map((tab) => (
                            <button
                              key={tab}
                              className={`my-1 px-5 text-capitalize ${
                                activeTab === tab
                                  ? "btn btn-outline-primary btn-pill bg-blue-lt"
                                  : "bg-white border-0 mx-2"
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveTab(tab);
                              }}
                            >
                              {tab}
                            </button>
                          ))}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="mt-3">{renderContent()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewLead;
