import React, { useEffect, useState } from "react";
import ListingSet from "../ListingCodeGenerator/ListingSet";
import { useDispatch } from "react-redux";
import { createShowcaseApi } from "../../../modules/dashboard/actions";
import { Bounce, toast } from "react-toastify";
import ShowcaseListingSet from "./showcaseListingSet";

const UpdateShowcaseForm = ({
  formData,
  handleChange,
  cards,
  sort,
  filter,
  selectedSort,
  selectedFilter,
  removeCard,
  closeModel,
  selectedFilterOption, 
  setfilterOptions,
  removeAllCards
}) => {
  // const [formData, setFormData] = useState({
  //   name: "",
  //   title: "",
  //   type: "",
  //   listingIds: cards
  // });
  const [displayCards, setDisplayCards] = useState(formData.listingIds);

  useEffect(() => {
    let updatedCards = [...formData.listingIds];

    // Apply status filter
    if (selectedFilter) {
      updatedCards = updatedCards.filter((card) =>
        card.status &&
        card.status.toLowerCase() === selectedFilter.toLowerCase()
      );
    }

    // Apply sorting
    if (selectedSort === "Price (Low to High)") {
      updatedCards.sort((a, b) => a.price - b.price);
    } else if (selectedSort === "Price (High to Low)") {
      updatedCards.sort((a, b) => b.price - a.price);
    } else if (selectedSort === "Listed Date (Recent First)") {
      updatedCards.sort((a, b) => new Date(b.listedDate) - new Date(a.listedDate));
    } else if (selectedSort === "Listed Date (Oldest First)") {
      updatedCards.sort((a, b) => new Date(a.listedDate) - new Date(b.listedDate));
    }

    setDisplayCards(updatedCards);
  }, [cards, selectedFilter, selectedSort]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [listingIds, setListingIds] = useState(formData.listingIds || []);

  // Function to add a listing to listingIds
  const addListing = (listing) => {
    setListingIds([...listingIds, listing]);
  };

  // Function to remove a listing by ID from listingIds
  const removeListing = (id) => {
    setListingIds(listingIds.filter((listing) => listing.id !== id));
  };

  console.log("formData", formData);

  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const showcaseData = {
      ...formData,
      listingIds, // Include updated listingIds in the formData
    };
    setIsSubmitting(true);
    const response = await dispatch(createShowcaseApi(showcaseData));
    console.log(response.data);
    if (response.data) {
      toast.success("Showcase is created", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      closeModel();
      setError("");
      // setFormData({
      //   name: "",
      //   title: "",
      //   type: "",
      //   listingIds: cards
      // });
    } else {
      setError(error);
    }
    setIsSubmitting(false);
  };
  return (
    <div>
      <div>
        <h2 className="text-primary">Showcase</h2>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
          {/* <div className="mb-3 w-100">
            <label>
              <strong>Showcase name</strong>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Showcase Name"
              className="form-control form-control-rounded"
              defaultValue={formData.name}
              onChange={handleChange}
            />
          </div> */}
          <div className="mb-3 w-100">
            <label>
              <strong>Showcase Title</strong>
            </label>
            <input
              type="text"
              name="title"
              placeholder="Showcase Title"
              className="form-control form-control-rounded"
              defaultValue={formData.title}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3 w-100">
            <label>
              <strong>Showcase Type</strong>
            </label>
            <select
              className="form-select rounded-5"
              name="type"
              defaultValue={formData.type}
              onChange={handleChange}
            >
              <option value="" className="form-select">
                Select Type
              </option>
              <option value="Photo Banner" className="form-select">
                Photo Banner"
              </option>
            </select>
          </div>
        </div>
      </div>
     
      
      <div className="listing-group-controls">
        <div className="control-row">
          {/* <div className="drag-icon"><</div> */}
          <select
            className="form-select rounded-5 me-2"
            value={selectedFilter}
            onChange={filter}
            // disabled={!isDropdownEnabled}
          >
            <option value="">Status: All</option>
            <option value="active">Status: Active</option>
            <option value="sold">Status: Sold</option>
            <option value="on hold">Status: On hold</option>
            <option value="pending">Status: Pending</option>
            <option value="deleted">Deleted Listings</option>
            <option value="terminated">Terminated Listings</option>
            <option value="expired">Expired Listings</option>
            <option value="cancelled">Cancelled Listings</option>
            <option value="unknown">Unknown Listings</option>
            <option value="wait approval">Wait approval Listings</option>
          </select>
          <select
            className="form-select rounded-5 me-2"
            value={selectedSort}
            onChange={sort}
          >
            <option value="">Sort: All Listings</option>
            <option value="Price (Low to High)">Sort: Price (Low to High)</option>
            <option value="Price (High to Low)">Sort: Price (High to Low)</option>
            <option value="Listed Date (Recent First)">Sort: Listed Date (Recent First)</option>
            <option value="Listed Date (Oldest First)">Sort: Listed Date (Oldest First)</option>
          </select>
        </div>
        <select
            className="form-select rounded-5 w-50"
            value={selectedFilterOption}
            onChange={setfilterOptions}
          >
            <option value="Default">Filter Setting: Default</option>
            <option value="Top">Filter Setting: Top</option>
          </select>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-outline-primary btn-pill m-2"
          onClick={removeAllCards}
        >
          Clear
        </button>
      </div>
      <div>
        <ShowcaseListingSet
          cards={formData.listingIds || displayCards}
          removeCard={removeCard}
        />
      </div>
    </div>
  );
};

export default UpdateShowcaseForm;
