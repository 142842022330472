import React, { useEffect, useState } from "react";
import ListingSet from "../ListingCodeGenerator/ListingSet";
import { useDispatch } from "react-redux";
import { createShowcaseApi } from "../../../modules/dashboard/actions";
import { Bounce, toast } from "react-toastify";
import ShowcaseListingSet from "./showcaseListingSet";
import MLSShowcaseListingSet from "./mlsShowcaseListingSet";

const MLSAddShowcase = ({
  formData,
  handleChange,
  cards,
  sort,
  filter,
  selectedSort,
  selectedFilter,
  removeCard,
  closeModel,
  selectedFilterOption,
  setfilterOptions,
  removeAllCards,
}) => {
  // const [formData, setFormData] = useState({
  //   name: "",
  //   title: "",
  //   type: "",
  //   listingIds: cards
  // });
  const [displayCards, setDisplayCards] = useState(cards);

  useEffect(() => {
    let updatedCards = [...cards];

    // Apply status filter
    if (selectedFilter) {
      updatedCards = updatedCards.filter(
        (card) =>
          card.MlsStatus &&
          card.MlsStatus.toLowerCase() === selectedFilter.toLowerCase()
      );
    }

    // Apply sorting
    if (selectedSort === "Price (Low to High)") {
      updatedCards.sort((a, b) => a.ListPrice - b.ListPrice);
    } else if (selectedSort === "Price (High to Low)") {
      updatedCards.sort((a, b) => b.ListPrice - a.ListPrice);
    } else if (selectedSort === "Listed Date (Recent First)") {
      updatedCards.sort(
        (a, b) =>
          new Date(b.MediaChangeTimestamp) - new Date(a.MediaChangeTimestamp)
      );
    } else if (selectedSort === "Listed Date (Oldest First)") {
      updatedCards.sort(
        (a, b) =>
          new Date(a.MediaChangeTimestamp) - new Date(b.MediaChangeTimestamp)
      );
    }

    setDisplayCards(updatedCards);
  }, [cards, selectedFilter, selectedSort]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [listingIds, setListingIds] = useState(formData.listingIds || []);

  // Function to add a listing to listingIds
  const addListing = (listing) => {
    setListingIds([...listingIds, listing]);
  };

  // Function to remove a listing by ID from listingIds
  const removeListing = (id) => {
    setListingIds(listingIds.filter((listing) => listing.id !== id));
  };

  console.log("formData", formData);

  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const showcaseData = {
      ...formData,
      listingIds: displayCards.map((card) => card.ListingKey), // Include updated listingIds in the formData
    };
    setIsSubmitting(true);
    const response = await dispatch(createShowcaseApi(showcaseData));
    console.log(response.data);
    if (response.data) {
      toast.success("Showcase is created", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      closeModel();
      setError("");
      // setFormData({
      //   name: "",
      //   title: "",
      //   type: "",
      //   listingIds: cards
      // });
    } else {
      setError(error);
    }
    setIsSubmitting(false);
  };
  return (
    <div>
      <div>
        <h2 className="text-primary">Showcase</h2>
        <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
          {/* <div className="mb-3 w-100">
            <label>
              <strong>Showcase name</strong>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Showcase Name"
              className="form-control form-control-rounded"
              defaultValue={formData.name}
              onChange={handleChange}
            />
          </div> */}
          <div className="mb-3 w-100">
            <label>
              <strong>Showcase Title</strong>
            </label>
            <input
              type="text"
              name="title"
              placeholder="Showcase Title"
              className="form-control form-control-rounded"
              defaultValue={formData.title}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3 w-100">
            <label>
              <strong>Showcase Type</strong>
            </label>
            <select
              className="form-select rounded-5"
              name="type"
              defaultValue={formData.type}
              onChange={handleChange}
            >
              <option value="" className="form-select">
                Select Type
              </option>
              <option value="Photo Banner" className="form-select">
                Photo Banner"
              </option>
            </select>
          </div>
        </div>
      </div>
      {/* <div className="alert alert-primary bg-blue-lt  mx-3 mt-3 no-margin-bottom mb-3 rounded-4">
        <div className="d-flex align-items-center">
          <>
            {" "}
            <div className="alert-icon text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-alert-circle"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="12" r="9" />
                <line x1="12" y1="8" x2="12" y2="12" />
                <line x1="12" y1="16" x2="12.01" y2="16" />
              </svg>
            </div>
            <div className="ms-3">
         
              <div className="text-primary">
                For the Photo Banner showcase, the maximum number of listings
                that will be shown is 20
              </div>
            </div>
          </>
        </div>
      </div> */}
      <div className="listing-group-controls">
        <div className="control-row">
          {/* <div className="drag-icon"><</div> */}
          <select
            className="form-select rounded-5 me-2"
            value={selectedFilter}
            onChange={filter}
            // disabled={!isDropdownEnabled}
          >
            <option value="">Status: All</option>
            <option value="New">Status: New</option>
            <option value="Price Change">Status: Price Change</option>
            {/* <option value="deleted">Deleted Listings</option>
            <option value="terminated">Terminated Listings</option>
            <option value="expired">Ex Listings</option> */}
          </select>
          <select
            className="form-select rounded-5 me-2"
            value={selectedSort}
            onChange={sort}
          >
            <option value="My Listings">Sort: All Listings</option>
            <option value="Price (Low to High)">
              Sort: Price (Low to High)
            </option>
            <option value="Price (High to Low)">
              Sort: Price (High to Low)
            </option>
            <option value="Listed Date (Recent First)">
              Sort: Listed Date (Recent First)
            </option>
            <option value="Listed Date (Oldest First)">
              Sort: Listed Date (Oldest First)
            </option>
          </select>

          {/* <select className="select-input">
            <option>All Listings</option>
            <option>Featured Listings</option>
          </select> */}
          {/* <div className="toggle-container">
            <label className="switch">
              <input
                type="checkbox"
                checked={isDropdownEnabled}
                onChange={() => setIsDropdownEnabled(!isDropdownEnabled)}
              />
              <span className="slider round"></span>
            </label>
          </div> */}
        </div>
        <select
          className="form-select rounded-5 w-50"
          value={selectedFilterOption}
          onChange={setfilterOptions}
        >
          <option value="Default">Filter Setting: Default</option>
          <option value="Top">Filter Setting: Top</option>
        </select>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-outline-primary btn-pill m-2"
          onClick={removeAllCards}
        >
          Clear
        </button>
      </div>
      <div>
        <MLSShowcaseListingSet
          cards={displayCards}
          sort={sort}
          filter={filter}
          selectedSort={selectedSort}
          selectedFilter={selectedFilter}
          removeCard={removeCard}
          formData={formData.listingIds}
          selectedFilterOption={selectedFilterOption}
          setfilterOptions={setfilterOptions}
          removeAllCards={removeAllCards}
        />
      </div>
    </div>
  );
};

export default MLSAddShowcase;
