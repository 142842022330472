import React, { useState } from "react";
import listingImage from "../../../assets/images/login-cover.png";
import "../../../assets/styles/listingSet.scss";
import { IconX } from "@tabler/icons-react";

const ListingSet = ({
  cards,
  sort,
  filter,
  selectedSort,
  selectedFilter,
  removeCard,
  selectedFilterOption,
  setfilterOptions,
  removeAllCards,
}) => {
  const truncate = (input, maxLength) => {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + "...";
    }
    return input;
  };
  return (
    <div className="listing-page-container">
      {/* Listing Group Controls */}
      <div className="listing-group-controls">
        <h2 className="text-primary">Added Listings</h2>
        <div className="control-row">
          {/* <div className="drag-icon"><</div> */}
          <select
            className="form-select rounded-5 me-2"
            value={selectedFilter}
            onChange={filter}
            // disabled={!isDropdownEnabled}
          >
            <option value="">Status: All</option>
            <option value="active">Status: Active</option>
            <option value="sold">Status: Sold</option>
            <option value="on hold">Status: On hold</option>
            <option value="pending">Status: Pending</option>
            <option value="deleted">Deleted Listings</option>
            <option value="terminated">Terminated Listings</option>
            <option value="expired">Expired Listings</option>
            <option value="cancelled">Cancelled Listings</option>
            <option value="unknown">Unknown Listings</option>
            <option value="wait approval">Wait approval Listings</option>
          </select>
          <select
            className="form-select rounded-5 me-2"
            value={selectedSort}
            onChange={sort}
          >
            <option value="My Listings">Sort: All Listings</option>
            <option value="Price (Low to High)">
              Sort: Price (Low to High)
            </option>
            <option value="Price (High to Low)">
              Sort: Price (High to Low)
            </option>
            <option value="Listed Date (Recent First)">
              Sort: Listed Date (Recent First)
            </option>
            <option value="Listed Date (Oldest First)">
              Sort: Listed Date (Oldest First)
            </option>
          </select>

          {/* <select className="select-input">
            <option>All Listings</option>
            <option>Featured Listings</option>
          </select> */}
          {/* <div className="toggle-container">
            <label className="switch">
              <input
                type="checkbox"
                checked={isDropdownEnabled}
                onChange={() => setIsDropdownEnabled(!isDropdownEnabled)}
              />
              <span className="slider round"></span>
            </label>
          </div> */}
        </div>
        <select
          className="form-select rounded-5 w-50"
          value={selectedFilterOption}
          onChange={setfilterOptions}
        >
          <option value="Default">Filter Setting: Default</option>
          <option value="Top">Filter Setting: Top</option>
        </select>
      </div>
      <div className="d-flex justify-content-end">
      <button className="btn btn-outline-primary btn-pill m-2" onClick={removeAllCards}>Clear</button>
      </div>

      {/* Property Listing */}
      <div className="property-listing">
        {cards.length === 0 ? (
          <div className="no-listing-message">
            Select listing by clicking the plus button
          </div>
        ) : (
          cards.map((card) => (
            <div key={card.id} className="property-list-item">
              <div className="d-flex gap-2 align-items-center w-100">
                <img
                  src={
                    card.images && card.images[0] && card.images[0].imageUrl
                      ? card.images[0].imageUrl
                      : listingImage
                  }
                  alt={card.description}
                  className="property-thumb"
                />
                <div className="property-info">
                  <div className="price h4">
                    {card.currency} {card.price}
                  </div>
                  <div className="description">{truncate(`${card.title}`, 30)}</div>
                </div>
              </div>
              <div
                className="property-actions cursor-pointer rounded-5 bg-gray-900 p-1"
                onClick={() => removeCard(card.id)}
              >
                <IconX />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ListingSet;
